import { EuiButton, EuiCallOut, EuiFieldText, EuiForm, EuiFormRow, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiOverlayMask, EuiSpacer, EuiTextArea, EuiTitle } from "@elastic/eui"
import React from "react"
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CohortValdiationEnum } from "../../../models/customers/cohorts/cohort-validation";
import { updateCohortName, updateCohortDescription, toggleCreateMenu, toggleDeleteMenu, toggleUpdateMenu, updateCohort } from "../../../redux/customers/cohorts/cohorts.actions";
import { CohortsStateProps } from "../../../redux/customers/cohorts/cohorts.state";
import { SmartbillsRootState } from "../../../redux/smartbills.reducer";
import { UpdateModalDispatchProps } from "./update-modal-props";

class UpdateCohortModal extends React.Component<WithTranslation & { cohorts: CohortsStateProps } & UpdateModalDispatchProps> {
    public render() {
        const { cohorts } = this.props;
        const { activeCohort, cohortForm } = cohorts;
        return (
            <EuiOverlayMask>
                <EuiModal onClose={() => { this.props.toggleUpdateMenu(false) }}>
                    <EuiModalHeader>
                        <EuiTitle>
                            <h1>
                                {this.props.t("update-modal:title")}
                            </h1>
                        </EuiTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        <p dangerouslySetInnerHTML={{ __html: this.props.t("update-modal:description", { name: activeCohort?.name }) }}></p>
                        <EuiSpacer></EuiSpacer>
                        <EuiForm isInvalid={this.props.cohorts.updateError != undefined} error={this.props.cohorts.updateError?.message}>
                            <EuiFormRow label={this.props.t("update-modal:cohort-name")} isInvalid={cohorts.nameValidation != CohortValdiationEnum.VALID} >
                                <EuiFieldText value={cohortForm?.name} onChange={(event) => this.props.updateCohortName(event.target.value)} required fullWidth placeholder={this.props.t("update-modal:cohort-name-placeholder")}></EuiFieldText>
                            </EuiFormRow>
                            <EuiFormRow label={this.props.t("update-modal:cohort-description")} isInvalid={cohorts.descriptionValidation != CohortValdiationEnum.VALID}>
                                <EuiTextArea value={cohortForm?.description} onChange={(event) => this.props.updateCohortDescription(event.target.value)} resize="none" fullWidth placeholder={this.props.t("update-modal:cohort-description-placeholder")}></EuiTextArea>
                            </EuiFormRow>
                        </EuiForm>
                    </EuiModalBody>
                    <EuiModalFooter>
                        <EuiButton color="danger" onClick={() => this.props.toggleUpdateMenu(false)} fullWidth iconType="cross">{this.props.t("update-modal:cancel")}</EuiButton>
                        <EuiButton isDisabled={!cohorts.isFormValid} isLoading={cohorts.updateLoading} color="secondary" onClick={() => this.props.updateCohort()} fullWidth iconType="save">{this.props.t("update-modal:save")}</EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            </EuiOverlayMask>)
    }
}

const mapStateToProps = (state: SmartbillsRootState): { cohorts: CohortsStateProps } => {
    const { cohorts } = state.customers;
    return {
        cohorts: cohorts
    };
};
const mapDispatchToProps = (dispatch: any): UpdateModalDispatchProps => {
    return {
        updateCohort: () => {
            dispatch(updateCohort());
        },
        updateCohortName: (name) => {
            dispatch(updateCohortName(name));
        },
        updateCohortDescription: (description) => {
            dispatch(updateCohortDescription(description));
        },
        toggleUpdateMenu: (isOpen) => {
            dispatch(toggleUpdateMenu(isOpen))
        }
    }
}

const UpdateCohortModalTranslated = withTranslation("cohorts")(connect(mapStateToProps, mapDispatchToProps)(UpdateCohortModal));
export { UpdateCohortModalTranslated as UpdateCohortModal }