import { EuiButton, EuiFieldText, EuiForm, EuiFormRow, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiOverlayMask, EuiTextArea, EuiTitle } from "@elastic/eui"
import React from "react"
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CohortValdiationEnum } from "../../../models/customers/cohorts/cohort-validation";
import { createCohort, updateCohortName, updateCohortDescription, toggleCreateMenu, toggleDeleteMenu } from "../../../redux/customers/cohorts/cohorts.actions";
import { CohortsStateProps } from "../../../redux/customers/cohorts/cohorts.state";
import { SmartbillsRootState } from "../../../redux/smartbills.reducer";
import { CreateModalDispatchProps } from "./create-modal-props";

class CreateCohortModal extends React.Component<WithTranslation & { cohorts: CohortsStateProps } & CreateModalDispatchProps> {
    public render() {
        const { cohorts } = this.props;
        const { cohortForm } = cohorts;
        return (
            <EuiOverlayMask>
                <EuiModal onClose={() => { this.props.toggleCreateMenu(false) }}>
                    <EuiModalHeader>
                        <EuiTitle>
                            <h1>
                                {this.props.t("create:title")}
                            </h1>
                        </EuiTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        <EuiForm isInvalid={this.props.cohorts.createError != undefined} error={this.props.cohorts.createError?.message}>
                            <EuiFormRow label={this.props.t("create:name-field:title")} isInvalid={cohorts.nameValidation != CohortValdiationEnum.VALID} >
                                <EuiFieldText value={cohortForm?.name} onChange={(event) => this.props.updateCohortName(event.target.value)} required fullWidth placeholder={this.props.t("create:name-field:placeholder")}></EuiFieldText>
                            </EuiFormRow>
                            <EuiFormRow label={this.props.t("create:description-field:title")} isInvalid={cohorts.descriptionValidation != CohortValdiationEnum.VALID}>
                                <EuiTextArea value={cohortForm?.description} onChange={(event) => this.props.updateCohortDescription(event.target.value)} resize="none" fullWidth placeholder={this.props.t("create:description-field:placeholder")}></EuiTextArea>
                            </EuiFormRow>
                        </EuiForm>
                    </EuiModalBody>
                    <EuiModalFooter>
                        <EuiButton color="danger" onClick={() => this.props.toggleCreateMenu(false)} fullWidth iconType="cross">{this.props.t("create:cancel")}</EuiButton>
                        <EuiButton isDisabled={!cohorts.isFormValid} isLoading={cohorts.createLoading} color="secondary" onClick={() => this.props.createCohort()} fullWidth iconType="save">{this.props.t("create:save")}</EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            </EuiOverlayMask>)
    }
}

const mapStateToProps = (state: SmartbillsRootState): { cohorts: CohortsStateProps } => {
    const { cohorts } = state.customers;
    return {
        cohorts: cohorts
    };
};
const mapDispatchToProps = (dispatch: any): CreateModalDispatchProps => {
    return {

        createCohort: () => {
            dispatch(createCohort());
        },
        updateCohortName: (name) => {
            dispatch(updateCohortName(name));
        },
        updateCohortDescription: (description) => {
            dispatch(updateCohortDescription(description));
        },
        toggleCreateMenu: (isOpen) => {
            dispatch(toggleCreateMenu(isOpen))
        }
    }
}

const CreateCohortModalTranslated = withTranslation("cohorts")(connect(mapStateToProps, mapDispatchToProps)(CreateCohortModal));
export { CreateCohortModalTranslated as CreateCohortModal }