import { Card, Typography } from "antd"
import React, { ReactNode } from "react"

import { SettingsCategoryProps } from "./settings-category.props"
import styles from "./settings-category.module.scss";

export class SettingsCategory extends React.Component<SettingsCategoryProps> {
    public render(): ReactNode {
        return (
            <Card className={styles.card} title={<Title level={4}>{this.props.title}</Title>} >
                {this.props.children}
            </Card>
        )
    }
}
const { Title } = Typography;
