import { User, UserManager, UserManagerSettings } from "oidc-client";

import { AccountClaims } from "../../view-models/account/account-claims";
import { AccountService } from "../account/account.service";
import { RetailAPI } from "../retail-api";
import { createUserManager } from 'redux-oidc';

export class OAuth2Service {
    private static client: UserManager;
    private static config: UserManagerSettings = {
        authority: process.env.REACT_APP_IDENTITY_AUTHORITY,
        redirect_uri: process.env.REACT_APP_IDENTITY_REDIRECT_URL,
        client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
        scope: "openid email profile smartbills-retailer-api offline_access",
        response_type: "code",
        stopCheckSessionOnError: true,
        post_logout_redirect_uri: process.env.REACT_APP_IDENTITY_POST_LOGOUT_REDIRECT_URI,
        revokeAccessTokenOnSignout: true,
        silent_redirect_uri: process.env.REACT_APP_IDENTITY_SILENT_RENEW_CALLBACK_URI,
        automaticSilentRenew: true,
    };
    public static isLoggedIn(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            OAuth2Service.instance.getUser().then((user: User | null) => {
                if (user && !user.expired) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((e: Error) => reject(e));
        });
    }

    public static get instance(): UserManager {
        if (!OAuth2Service.client) {
            OAuth2Service.client = createUserManager(this.config);
            OAuth2Service.setupEventListeners();
        };
        return OAuth2Service.client
    }

    private static setupEventListeners() {
        OAuth2Service.client.events.addUserLoaded((user) => {
            RetailAPI.setToken(user?.access_token);
            const claims: AccountClaims = {
                id: user.profile.sub,
                email: user.profile.email!,
                fullname: user.profile.fullname,
                username: user.profile.username,
            }
        })
    }
}