import React, { ReactElement, ReactNode } from "react";

import { LanguageSettingsProps } from "./language-settings.props";
import { LanguageUtil } from "../../../utils/language.util";
import { Select } from "antd";
import { Setting } from "../setting/setting";
import { SettingsCategory } from "../settings-category/settings-category";
import { withTranslation } from "react-i18next";

class LanguageSettings extends React.Component<LanguageSettingsProps> {
    public render(): ReactNode {
        return (
            <SettingsCategory title={this.props.t("settings:language")}>
                {this.getSettings()}
            </SettingsCategory>
        );
    }

    public getAvailableLanguage(): ReactNode[] {
        const languages = LanguageUtil.getAvailableLanguages();
        return languages.map((l, index) => {
            return <Select.Option value={l} key={index}>{l}</Select.Option>
        })
    }

    public onLanguageChange(locale: string): void {
        this.props.i18n.changeLanguage(locale);
    }
    private getSettings() {
        let settings: ReactElement[] = [];

        settings.push(
            <Setting name={this.props.t("settings:selectLanguage")} option={
                <Select key="lang" defaultValue={this.props.i18n.language}
                    onChange={(value: string) => this.onLanguageChange(value)}>
                    {this.getAvailableLanguage()}
                </Select>}>
            </Setting>
        );
        return settings;
    }
}
const LanguageSettingsTranslated = withTranslation()(LanguageSettings);
export { LanguageSettingsTranslated as LanguageSettings }