import { ManageCompaniesStateProps } from "../manage-company/manage-company.state";
import { ManageCompaniesAction } from "./manage-company.actions";
import { ManageCompaniesActionTypes } from "./manage-company.types";


const initialState: ManageCompaniesStateProps = {};
export function ManageCompaniesReducer(
    state: ManageCompaniesStateProps = initialState,
    action: ManageCompaniesAction
): ManageCompaniesStateProps {
    switch (action.type) {
        case ManageCompaniesActionTypes.GET_DEFAULT_COMPANY_STARTED:
            return {
                ...state,
                company: action.payload?.company,
                companyLoading: action.payload?.companyLoading,
                companyError: action.payload?.companyError
            };
        case ManageCompaniesActionTypes.GET_DEFAULT_COMPANY_COMPLETED:
            return {
                ...state,
                company: action.payload?.company,
                companyLoading: action.payload?.companyLoading,
                companyError: action.payload?.companyError
            };
        case ManageCompaniesActionTypes.GET_DEFAULT_COMPANY_FAILED:
            return {
                ...state,
                company: action.payload?.company,
                companyLoading: action.payload?.companyLoading,
                companyError: action.payload?.companyError
            };

        case ManageCompaniesActionTypes.GET_COMPANIES_STARTED:
            return {
                ...state,
                companies: action.payload?.companies,
                companiesLoading: action.payload?.companiesLoading,
                companiesError: action.payload?.companiesError
            };
        case ManageCompaniesActionTypes.GET_COMPANIES_COMPLETED:
            return {
                ...state,
                companies: action.payload?.companies,
                companiesLoading: action.payload?.companiesLoading,
                companiesError: action.payload?.companiesError
            };
        case ManageCompaniesActionTypes.GET_COMPANIES_FAILED:
            return {
                ...state,
                companies: action.payload?.companies,
                companiesLoading: action.payload?.companiesLoading,
                companiesError: action.payload?.companiesError
            };

        case ManageCompaniesActionTypes.UPDATE_DEFAULT_COMPANY_STARTED:
            return {
                ...state,
                updateDefaultCompanyLoading: action.payload?.updateDefaultCompanyLoading,
                updateDefaultError: action.payload?.updateDefaultError,
            };
        case ManageCompaniesActionTypes.UPDATE_DEFAULT_COMPANY_COMPLETED:
            return {
                ...state,
                updateDefaultCompanyLoading: action.payload?.updateDefaultCompanyLoading,
                updateDefaultError: action.payload?.updateDefaultError,
            };
        case ManageCompaniesActionTypes.UPDATE_DEFAULT_COMPANY_FAILED:
            return {
                ...state,
                updateDefaultCompanyLoading: action.payload?.updateDefaultCompanyLoading,
                updateDefaultError: action.payload?.updateDefaultError,
            };
        case ManageCompaniesActionTypes.UPDATE_DEFAULT_COMPANY:
            return {
                ...state,
                company: action.payload?.company,
            };
        default:
            return state;
    }
}