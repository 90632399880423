import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { ManageCompaniesActionTypes } from "./manage-company.types";
import { SBCompany } from "@smartbills/smartbills-js";
import { CompanyOwned } from "../../models/company/company-owned";
import { CompanyService } from "../../services/company/company.service";
import { ManageCompaniesPayload } from "./manage-company.state";

export interface ManageCompaniesAction {
    type: ManageCompaniesActionTypes;
    payload?: ManageCompaniesPayload;
}

export const getDefaultCompany = () => {
    return (dispatch: Dispatch) => {
        dispatch(getDefaultCompanyStarted());
        CompanyService.getDefaultCompany().then((company) => {
            dispatch(getDefaultCompanyCompleted(company))
        }).catch((error: AxiosError) => {
            dispatch(getDefaultCompanyFailed(error))
        })
    };
}

export const getDefaultCompanyStarted = (): ManageCompaniesAction => {
    return {
        type: ManageCompaniesActionTypes.GET_DEFAULT_COMPANY_STARTED,
        payload: {
            companyLoading: true,
            companyError: undefined
        }
    };
};
export function getDefaultCompanyCompleted(company: CompanyOwned): ManageCompaniesAction {
    return {
        type: ManageCompaniesActionTypes.GET_DEFAULT_COMPANY_COMPLETED,
        payload: {
            company: company,
            companyLoading: false,
            companyError: undefined
        }
    };
}

export const getDefaultCompanyFailed = (error: AxiosError): ManageCompaniesAction => {
    return {
        type: ManageCompaniesActionTypes.GET_DEFAULT_COMPANY_FAILED,
        payload: {
            companyLoading: false,
            companyError: error,
        }
    };
};

export const getCompanies = () => {
    return (dispatch: Dispatch) => {
        dispatch(getCompaniesStarted());
        CompanyService.getCompanies().then((companies) => {
            dispatch(getCompaniesCompleted(companies))
        }).catch((error: AxiosError) => {
            dispatch(getCompaniesFailed(error))
        })
    };
}

export const getCompaniesStarted = (): ManageCompaniesAction => {
    return {
        type: ManageCompaniesActionTypes.GET_COMPANIES_STARTED,
        payload: {
            companiesLoading: true,
            companiesError: undefined
        }
    };
};
export function getCompaniesCompleted(companies: SBCompany[]): ManageCompaniesAction {
    return {
        type: ManageCompaniesActionTypes.GET_COMPANIES_COMPLETED,
        payload: {
            companies: companies,
            companiesLoading: false,
            companiesError: undefined
        }
    };
}

export const getCompaniesFailed = (error: AxiosError): ManageCompaniesAction => {
    return {
        type: ManageCompaniesActionTypes.GET_COMPANIES_FAILED,
        payload: {
            companiesLoading: false,
            companiesError: error.response,
        }
    };
};

export const setDefaultCompany = (companyId: string) => {
    return (dispatch: Dispatch) => {
        dispatch(setDefaultCompanyStarted());
        CompanyService.setDefaultCompany(companyId).then((companies) => {
            dispatch(setDefaultCompanyCompleted(companyId))
            window.location.href = "/";
        }).catch((error: AxiosError) => {
            dispatch(setDefaultCompanyFailed(error))
        })
    };
}

export const updateDefaultCompany = (company: CompanyOwned) => {
    return {
        type: ManageCompaniesActionTypes.UPDATE_DEFAULT_COMPANY,
        payload: {
            company: company,
        }
    };
}

export const setDefaultCompanyStarted = (): ManageCompaniesAction => {
    return {
        type: ManageCompaniesActionTypes.UPDATE_DEFAULT_COMPANY_STARTED,
        payload: {
            updateDefaultCompanyLoading: true,
        }
    };
};
export function setDefaultCompanyCompleted(companyId: string): ManageCompaniesAction {
    return {
        type: ManageCompaniesActionTypes.UPDATE_DEFAULT_COMPANY_COMPLETED,
        payload: {
            updateDefaultCompanyLoading: false,
        }
    };
}

export const setDefaultCompanyFailed = (error: AxiosError): ManageCompaniesAction => {
    return {
        type: ManageCompaniesActionTypes.UPDATE_DEFAULT_COMPANY_FAILED,
        payload: {
            updateDefaultCompanyLoading: false,
            updateDefaultError: error.response,
        }
    };
};