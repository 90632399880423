import React, { ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { LoadingPage } from "../loading/loading-page";
import { OAuth2Service } from "../../../services/authentication/oauth2-service";
import { SignoutCallbackComponent } from "redux-oidc";

class SilentRenewCallbackScreen extends React.Component<RouteComponentProps<{}>> {
    public constructor(props: RouteComponentProps<{}>) {
        super(props);

    };
    public componentDidMount() {
        OAuth2Service.instance.signinSilentCallback();
    }

    public render(): ReactNode {
        return (
            null
        );
    }
}

const CallbackScreenWithRouter = withRouter(SilentRenewCallbackScreen);
export { CallbackScreenWithRouter as SilentRenewCallbackScreen }