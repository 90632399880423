import { Button, Col, Row, Steps, Typography } from "antd";
import { Card, Text } from "@zeit-ui/react";
import React, { Fragment, ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import styles from "./setup.module.scss";

const { Step } = Steps;
const { Title } = Typography;
class SetupScreen extends React.Component<WithTranslation, { current: number }> {
    public constructor(props: any) {
        super(props)
        this.state = {
            current: 0,
        }
    }

    onChange = (current: number) => {
        this.setState({ current });
    };

    public render(): ReactNode {
        return (
            <Row className={styles.row}>
                <Card hoverable shadow className={styles.setupCard}>
                    <Row justify="center" align="middle" style={{ width: "100%", flexDirection: "column" }}>
                        <Col style={{ width: 150 }}>
                            <img alt="logo" height="100%" src="/logo.png">
                            </img>
                        </Col>
                        <Col style={{ marginTop: 30 }}>
                            <Title level={3}>Setup Instructions</Title>
                            <Text>Let's get started with this guide</Text>

                        </Col>
                    </Row>
                    <Row align="middle" justify="center" style={{ marginTop: 50 }}>
                        <Col >
                            <Steps
                                current={this.state.current}
                                onChange={this.onChange}
                                className="site-navigation-steps"
                                direction="vertical">
                                <Step title="Step 1 - Register" description={
                                    <Text>Click <a href={`${process.env.REACT_APP_REGISTER_URL}?returnUrl=${process.env.REACT_APP_RETAILER_URL}`} target="__blank">here</a> to create an account</Text>
                                } />
                                <Step title="Step 2 - Confirmation" description={
                                    <Text>After registration, you should receive a email confirmation. Please confirm your identity by clicking on the provided link. You won't be able to login until you have confirmed your email.</Text>
                                } />
                                <Step title="Step 3 - Ready to go!" description={
                                    <Fragment>
                                        <Text>You are now ready to go! The final step is to head hover to the application and to start using it.</Text>
                                        <Link to="/"><Button danger shape="round" disabled={this.state.current !== 2}>Bring me to the application</Button></Link>
                                    </Fragment>
                                } />
                            </Steps>
                        </Col>
                    </Row>
                </Card>
            </Row>
        );
    }
}
const SetupScreenTrannslated = withTranslation()(SetupScreen);
export { SetupScreenTrannslated as SetupScreen }