import { SearchEngineActionTypes } from "./search-engine.types";
import { AxiosError } from "axios";

import { setDefaultCompany, updateDefaultCompany } from "../../manage-company/manage-company.actions";
import { Dispatch } from "redux";
import { SearchEngineService } from "../../../services/search-engine/search-engine";
import { SearchEnginePayload } from "../../search-engine/search-engine.state";

export interface SearchEngineAction {
    type: SearchEngineActionTypes;
    payload?: SearchEnginePayload;
}
export const updateEnabled = (enabled: boolean) => {
    return (dispatch: Dispatch) => {
        dispatch(updateEnabledStarted(enabled));
        SearchEngineService.enableSearchEngine(enabled).then((company) => {
            dispatch(updateDefaultCompany(company));
            dispatch(updateEnabledCompleted(enabled));
        }).catch((error: AxiosError) => {
            dispatch(updateEnabledFailed(enabled, error))
        })
    };
}

export const updateEnabledStarted = (enabled: boolean): SearchEngineAction => {
    return {
        type: SearchEngineActionTypes.UPDATE_ENABLED_STARTED,
        payload: {
            enabled: enabled,
            loading: true,
            error: undefined
        }
    };
};
export function updateEnabledCompleted(enabled: boolean): SearchEngineAction {
    return {
        type: SearchEngineActionTypes.UPDATE_ENABLED_COMPLETED,
        payload: {
            enabled: enabled,
            loading: false,
            error: undefined
        }
    };
}

export const updateEnabledFailed = (enabled: boolean, error: AxiosError): SearchEngineAction => {
    return {
        type: SearchEngineActionTypes.UPDATE_ENABLED_FAILED,
        payload: {
            enabled: !enabled,
            loading: false,
            error: error
        }
    };
};