import React, { ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";

import { CallbackComponent } from "redux-oidc";
import { LoadingPage } from "../loading/loading-page";
import { OAuth2Service } from "../../../services/authentication/oauth2-service";
import mixpanel from "mixpanel-browser";

class CallbackScreen extends React.Component<WithTranslation & RouteComponentProps<{}>> {
    public constructor(props: WithTranslation & RouteComponentProps<{}>) {
        super(props);
    };

    public render(): ReactNode {
        return (<CallbackComponent userManager={OAuth2Service.instance} successCallback={(user) => {
            mixpanel.identify(user.profile.username)
            mixpanel.people.set({
                "$email": user.profile.email,
                "$name": user.profile.fullname,
            })
            mixpanel.track("Login");
            this.props.history.push("/")
        }} errorCallback={(error) => {
            this.props.history.push("/error")
        }}>
            <LoadingPage description={this.props.t("loading:appRedirect")} />
        </CallbackComponent>);
    }

    public onSuccessCallback() {
        this.props.history.push("/");
    }
}

const CallbackScreenWithRouter = withRouter(withTranslation()(CallbackScreen));
export { CallbackScreenWithRouter as CallbackScreen } 