import React, { ReactNode, Fragment } from "react";
import { LineChart, XAxis, YAxis, CartesianGrid, Line, ResponsiveContainer, Legend, Tooltip } from "recharts";
import { Row, Col, Typography, DatePicker, Space } from "antd";
import { Card, Text } from "@zeit-ui/react";
import { RetailAPI } from "../../../services/retail-api";
import moment from "moment";
const { RangePicker } = DatePicker;
export class OrderInsightsScreen extends React.Component<{}, { data: [] }> {
    public constructor(props: {}) {
        super(props);
        this.state = { data: [] }
    }
    public componentDidMount() {
        RetailAPI.getInstance().get("/insights/orders").then(x => this.setState({ data: x.data }))
    }
    public render(): ReactNode {
        return (
            <Fragment>
                <Title level={3}>Do</Title>
                <Row>
                    <RangePicker></RangePicker>
                </Row>
                <Space direction="vertical"></Space>
                {this.state.data.length > 0 &&

                    <ResponsiveContainer width="100%" height={300}>

                        <LineChart data={this.state.data}
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <XAxis dataKey="date" tickFormatter={timeStr => moment(timeStr).format('LL')} angle={-45} textAnchor="end" />
                            <YAxis />
                            <Line type="monotone" dataKey="web" stroke="#ff5757" dot={false} />
                        </LineChart>
                    </ResponsiveContainer>
                }
                <Row>
                    <Col span={12}>
                        <Card shadow>
                            <Card.Content>
                                <Text b>Total order in store vs Web</Text>
                            </Card.Content>
                            <Card.Content>

                            </Card.Content>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card>
                            <Card.Content>
                                <Text b>Total order </Text>
                            </Card.Content>
                            <Card.Content>

                            </Card.Content>
                        </Card>
                    </Col>
                </Row >
            </Fragment>
        )
    }
}

const { Title } = Typography;