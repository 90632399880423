export enum CohortActionTypes {
    SET_ACTIVE_COHORT = "SET_ACTIVE_COHORT",
    GET_COHORT_STARTED = "GET_COHORT_STARTED",
    GET_COHORT_COMPLETED = "GET_COHORT_COMPLETED",
    GET_COHORT_FAILED = "GET_COHORT_FAILED",
    UPDATE_SELECTED_FITLERS = "UPDATE_SELECTED_FITLERS",
    SET_ACTIVE_TAB = "SET_ACTIVE_TAB",
    GET_COHORT_OVERVIEW_STARTED = "GET_COHORT_OVERVIEW_STARTED",
    GET_COHORT_OVERVIEW_COMPLETED = "GET_COHORT_OVERVIEW_COMPLETED",
    GET_COHORT_OVERVIEW_FAILED = "GET_COHORT_OVERVIEW_FAILED",
    UPDATE_COHORT_STARTED = "UPDATE_COHORT_STARTED",
    UPDATE_COHORT_COMPLETED = "UPDATE_COHORT_COMPLETED",
    UPDATE_COHORT_FAILED = "UPDATE_COHORT_FAILED",
    GET_REVENUES_OVER_TIME_STARTED = "GET_REVENUES_OVER_TIME_STARTED",
    GET_REVENUES_OVER_TIME_COMPLETED = "GET_REVENUES_OVER_TIME_COMPLETED",
    GET_REVENUES_OVER_TIME_FAILED = "GET_REVENUES_OVER_TIME_FAILED",
    TOGGLE_CONFIGURATION_MENU = "TOGGLE_CONFIGURATION_MENU",
    TOGGLE_COHORT_DELETE_MENU = "TOGGLE_COHORT_DELETE_MENU",
    CONFIGURATION_DELETE_COHORT_STARTED = "CONFIGURATION_DELETE_COHORT_STARTED",
    CONFIGURATION_DELETE_COHORT_COMPLETED = "CONFIGURATION_DELETE_COHORT_COMPLETED",
    CONFIGURATION_DELETE_COHORT_FAILED = "CONFIGURATION_DELETE_COHORT_FAILED",
    CHANGE_CURRENT_PAGE = "CHANGE_CURRENT_PAGE",
    CHANGE_ROWS_PER_PAGE = "CHANGE_ROWS_PER_PAGE",
    RESET_COHORT_STATE = "RESET_COHORT_STATE"
}