import { AxiosError, AxiosResponse } from "axios";

import { AmountFilters } from "../../../view-models/insights/map/amount-filters";
import { LocalizedCustomer } from "../../../view-models/insights/map/localized-customer";
import { MapFilter } from "../../../view-models/insights/map/map-filter";
import { ProductFilter } from "../../../view-models/insights/map/product-filter";
import { RetailAPI } from "../../retail-api";
import { SmartbillsRootState } from "../../../redux/smartbills.reducer";
import querystring from "query-string";
import { store } from "../../../redux/store";
import { BasicMapResult } from "../../../view-models/insights/map/basic-map-result";
import { BasicMapFilters } from "../../../view-models/insights/map/basic-map-filters";

export class MapService {
    public static getProductsFilter(): Promise<ProductFilter[]> {
        var manageCompanies: SmartbillsRootState = store.getState();
        return new Promise<ProductFilter[]>((resolve, reject) => {
            RetailAPI.getInstance().get(`insights/map/${manageCompanies.manageCompanies.company}/product-filters`).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }

    public static getAmountFilters(): Promise<AmountFilters> {
        var manageCompanies: SmartbillsRootState = store.getState();
        return new Promise<AmountFilters>((resolve, reject) => {
            RetailAPI.getInstance().get(`insights/map/${manageCompanies.manageCompanies.company}/amount-filters`).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }

    public static getMapResults(mapFilter: MapFilter): Promise<LocalizedCustomer[]> {
        var manageCompanies: SmartbillsRootState = store.getState();
        return new Promise<LocalizedCustomer[]>((resolve, reject) => {
            RetailAPI.getInstance().post(`insights/map/${manageCompanies.manageCompanies.company}`, mapFilter, {

            }).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }

    public static getBasicMap(mapFilter: BasicMapFilters): Promise<BasicMapResult> {
        return new Promise<BasicMapResult>((resolve, reject) => {
            RetailAPI.getInstance().post(`insights/map`, mapFilter, {
            }).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }
}