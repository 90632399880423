import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { ProductService } from "../../services/products/product-service";
import { SBProductFilter } from "../../view-models/products/product-filter";
import { SmartbillsRootState } from "../smartbills.reducer";
import { ProductsPayload } from "./products.state";
import { ProductsActionTypes } from "./products.types";

export interface ProductsAction {
    type: ProductsActionTypes;
    payload: ProductsPayload;
}

export const getProductsFilter = () => {
    return (dispatch: Dispatch, getState: () => SmartbillsRootState) => {
        dispatch(getProductsFiltersStarted());
        ProductService.getProductsFilter().then((productsFilter) => {
            dispatch(getProductsFiltersCompleted(productsFilter))
        }).catch((error: AxiosError) => {
            dispatch(getProductsFiltersFailed(error))
        })
    };
}

export const getProductsFiltersStarted = (): ProductsAction => {
    return {
        type: ProductsActionTypes.GET_PRODUCTS_FILTER_STARTED,
        payload: {
            productsFilterLoading: true,
            productsFilterError: undefined
        }
    }
};

export const getProductsFiltersCompleted = (productsFilter: SBProductFilter[]): ProductsAction => {
    return {
        type: ProductsActionTypes.GET_PRODUCTS_FILTER_COMPLETED,
        payload: {
            productsFilter: productsFilter,
            productsFilterLoading: false,
            productsFilterError: undefined
        }
    }
};
export const getProductsFiltersFailed = (error: AxiosError): ProductsAction => {
    return {
        type: ProductsActionTypes.GET_PRODUCTS_FILTER_FAILED,
        payload: {
            productsFilter: undefined,
            productsFilterLoading: false,
            productsFilterError: error
        }
    }
};