import "./manage-company.module.scss";

import React, { ReactNode } from "react";

import { CompanyLinked } from "../../../components/manage-company/company-linked/company-linked";
import { CompanyNotLinked } from "../../../components/manage-company/company-not-linked/company-not-linked";
import { ManageCompanyProps } from "./manage-company.props";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { ManageCompaniesStateProps } from "../../../redux/manage-company/manage-company.state";
import { SmartbillsRootState } from "../../../redux/smartbills.reducer";
import { DashboardPage } from "../../../components/dashboard-page/dashboard-page";
import { EuiAvatar, EuiFieldText, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiHorizontalRule, EuiLink, EuiPanel, EuiTitle } from "@elastic/eui";

class ManageCompany extends React.Component<ManageCompanyProps, ManageCompaniesStateProps> {
    public constructor(props: ManageCompanyProps) {
        super(props);
    }
    public render(): ReactNode {
        var company = this.props.companies?.find(x => x.id === this.props.company?.companyId)
        return (
            <DashboardPage title={this.props.t("manageCompany:title")}>
                <EuiFlexGrid>
                    <EuiFlexItem>
                        <EuiAvatar size="xl" type="space" name={company?.name ? company.name : ""} />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiTitle>
                            <h1 style={{ margin: 0 }}>{company?.name}</h1>
                        </EuiTitle>
                        <EuiLink href={"https://" + company?.domain} target="_blank" external={true}>{company?.domain}</EuiLink>
                    </EuiFlexItem>
                </EuiFlexGrid>
                <EuiHorizontalRule></EuiHorizontalRule>
                <EuiForm>
                    <EuiFormRow label={this.props.t("manageCompany:owner")}>
                        <EuiFieldText disabled value={company?.owner} />
                    </EuiFormRow>
                    <EuiFormRow label={this.props.t("manageCompany:owner-email")}>
                        <EuiFieldText disabled value={company?.email} />
                    </EuiFormRow>
                    <EuiFormRow label={this.props.t("manageCompany:customer-email")}>
                        <EuiFieldText disabled value={company?.customerEmail} />
                    </EuiFormRow>
                    <EuiFormRow label={this.props.t("manageCompany:smartbills-page")}>
                        <EuiFieldText disabled value={`https://smartbills.ca/explore/${company?.slug}`} />
                    </EuiFormRow>
                </EuiForm>
            </DashboardPage>
        )
    }
}

const mapStateToProps = (state: SmartbillsRootState): ManageCompaniesStateProps => {
    const { manageCompanies } = state;
    return {
        company: manageCompanies.company,
        companies: manageCompanies.companies,
        companyLoading: manageCompanies.companyLoading,
        companyError: manageCompanies.companyError,
    };
};

const ManageCompanyConnected = connect(mapStateToProps)(withTranslation()(ManageCompany));
export { ManageCompanyConnected as ManageCompany }