import { EuiTitle, EuiText } from "@elastic/eui"
import { ArgsProps } from "antd/lib/notification"
import i18next from "i18next"
import React from "react"
import { Trans, Translation, WithTranslation, withTranslation } from "react-i18next"
import { SBCohort } from "../../../models/customers/SBCohorts"

export class CohortNotification implements ArgsProps {
    cohort: SBCohort;
    message: React.ReactNode
    description?: React.ReactNode
    btn?: React.ReactNode
    key?: string | undefined
    onClose?: (() => void) | undefined
    duration?: number | null | undefined
    icon?: React.ReactNode
    placement?: "topLeft" | "topRight" | "bottomLeft" | "bottomRight" | undefined
    style?: React.CSSProperties | undefined
    prefixCls?: string | undefined
    className?: string | undefined
    type?: "success" | "info" | "error" | "warning" | undefined
    onClick?: (() => void) | undefined
    top?: number | undefined
    bottom?: number | undefined
    getContainer?: (() => HTMLElement) | undefined
    closeIcon?: React.ReactNode
    public constructor(cohort: SBCohort) {
        this.cohort = cohort;
        this.placement = "bottomRight";
    }
}

export class CohortSuccessNotification extends CohortNotification {
    public constructor(cohort: SBCohort) {
        super(cohort);
        this.type = "success"

    }
}

export class DeleteCohortSuccessNotification extends CohortSuccessNotification {
    public constructor(cohort: SBCohort) {
        super(cohort);
        this.message = <EuiTitle size="xs"><h2><Translation ns={['cohorts']}>{(t) => t('delete-notification:message')}</Translation></h2></EuiTitle>;
        this.description =
            <Translation ns={['cohorts']}>
                {(t) =>
                    <EuiText dangerouslySetInnerHTML={{ __html: t('delete-notification:description', { cohort: this.cohort }) }}></EuiText>
                }
            </Translation>
    }
}

export class UpdateCohortSuccessNotification extends CohortSuccessNotification {
    public constructor(cohort: SBCohort) {
        super(cohort);
        this.message = <EuiTitle size="xs"><h2><Translation ns={['cohorts']}>{(t) => t('update-notification:message')}</Translation></h2></EuiTitle>;
        this.description =
            <Translation ns={['cohorts']}>
                {(t) =>
                    <EuiText dangerouslySetInnerHTML={{ __html: t('update-notification:description', { cohort: this.cohort }) }}></EuiText>
                }
            </Translation>
    }
}

export class CreateCohortSuccessNotification extends CohortSuccessNotification {
    public constructor(cohort: SBCohort) {
        super(cohort);
        this.message = <EuiTitle size="xs"><h2><Translation ns={['cohorts']}>{(t) => t('create-notification:message')}</Translation></h2></EuiTitle>;
        this.description =
            <Translation ns={['cohorts']}>
                {(t) =>
                    <EuiText dangerouslySetInnerHTML={{ __html: t('create-notification:description', { cohort: this.cohort }) }}></EuiText>
                }
            </Translation>
    }
}