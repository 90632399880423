import { dateFormatAliases, EuiBasicTable, EuiButtonIcon, EuiContextMenuItem, EuiContextMenuPanel, EuiPanel, EuiPopover, EuiSpacer, EuiTitle, formatDate } from "@elastic/eui";
import { Axis, LineSeries, Chart, Position, Settings, timeFormatter, niceTimeFormatByDay } from '@elastic/charts';

import React, { ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CohortStateProps } from "../../../../../redux/customers/cohort/cohort.state";
import { OrdersStateProps } from "../../../../../redux/orders/orders.state";
import { ProductsStateProps } from "../../../../../redux/products/products.state";
import { SmartbillsRootState } from "../../../../../redux/smartbills.reducer";
import '@elastic/charts/dist/theme_only_light.css';
import { SBCohort } from "../../../../../models/customers/SBCohorts";
import { SBCohortUserViewModel } from "../../../../../view-models/customers/cohorts/cohort-user";
import { Fragment } from "react";
import { CohortUsersPanelProps } from "./cohort-user-props";
import { changeClientsCurrentPage, changeClientsRowsPerPage } from "../../../../../redux/customers/cohort/cohort.actions";
import { Pagination } from "@elastic/eui/src/components/basic_table/pagination_bar";
import { CriteriaWithPagination } from "@elastic/eui/src/components/basic_table/basic_table";

class CohortUsersPanel extends React.Component<WithTranslation & CohortUsersPanelProps & { cohort: CohortStateProps, products: ProductsStateProps, orders: OrdersStateProps }> {
    public render(): ReactNode {
        const { overview, overviewLoading } = this.props.cohort;
        return (
            <Fragment>
                <EuiSpacer></EuiSpacer>
                <EuiBasicTable onChange={(criteria: CriteriaWithPagination<SBCohortUserViewModel>) => {
                    this.props.changeClientsCurrentPage(criteria.page.index)
                    this.props.changeClientsRowsPerPage(criteria.page.size)
                }}
                    loading={overviewLoading}
                    pagination={{
                        pageIndex: this.props.cohort.clientsCurrentPage!,
                        pageSize: this.props.cohort.clientsRowsPerPage!,
                        totalItemCount: overview?.users.length!,
                        pageSizeOptions: [10, 25, 50, 100],
                        hidePerPageOptions: false,
                    }}
                    columns={[
                        {
                            field: 'firstName',
                            name: this.props.t("clients-panel:first-name")
                        },
                        {
                            field: 'lastName',
                            name: this.props.t("clients-panel:last-name")
                        },
                        {
                            field: 'email',
                            name: this.props.t("clients-panel:email")
                        },
                    ]}
                    items={this.getUsers()}

                >
                </EuiBasicTable>
            </Fragment>
        )
    }

    private getUsers() {
        if (this.props.cohort.overview?.users) {
            var page_number = this.props.cohort.clientsCurrentPage!+ 1;
            var page_size = this.props.cohort.clientsRowsPerPage!;
            return this.props.cohort.overview.users.slice((page_number - 1) * page_size, page_number * page_size);
        } else {
            return [];
        }

    }

}

const mapStateToProps = (state: SmartbillsRootState): { cohort: CohortStateProps, products: ProductsStateProps, orders: OrdersStateProps } => {
    const { products, orders } = state;
    const { cohort } = state.customers;
    return {
        cohort: cohort,
        products: products,
        orders: orders
    };
};

const mapDispatchToProps = (dispatch: any): CohortUsersPanelProps => {
    return {
        changeClientsCurrentPage: (page) => {
            dispatch(changeClientsCurrentPage(page))
        },
        changeClientsRowsPerPage: (rows) => {
            dispatch(changeClientsRowsPerPage(rows));
        }
    }
}

const CohortUsersPanelTranslated = withTranslation("cohort")(connect(mapStateToProps, mapDispatchToProps)(CohortUsersPanel));
export { CohortUsersPanelTranslated as CohortUsersPanel }