import { Col, Row } from "antd";

import React from "react";
import { ReactNode } from "react";
import { SettingProps } from "./settting.props";

export class Setting extends React.Component<SettingProps> {
    public render(): ReactNode {
        return (
            <Row justify="space-between" style={{ width: "100%" }}>
                <Col span={12} xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    {this.props.name}
                </Col>
                <Col >
                    {this.props.option}
                </Col>
            </Row>
        );
    }
}