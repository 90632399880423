
import { OrdersAction } from "./orders.actions";
import { OrdersStateProps } from "./orders.state";
import { OrdersActionTypes } from "./orders.types";

const initialState: OrdersStateProps = {
    filters: {
        locales: [],
        paymentStatus: [],
        discountCodes: [],
        gateways: [],
        creditCards: [],
        stores: [],
    }
}
export function OrdersReducer(
    state: OrdersStateProps = initialState,
    action: OrdersAction
): OrdersStateProps {
    switch (action.type) {
        case OrdersActionTypes.GET_ORDERS_STARTED:
            return {
                ...state,
                orders: action.payload?.orders,
                ordersLoading: action.payload?.ordersLoading,
                ordersError: action.payload?.ordersError
            };
        case OrdersActionTypes.GET_ORDERS_COMPLETED:
            return {
                ...state,
                orders: action.payload?.orders,
                ordersLoading: action.payload?.ordersLoading,
                ordersError: action.payload?.ordersError
            };
        case OrdersActionTypes.GET_ORDERS_FAILED:
            return {
                ...state,
                orders: action.payload?.orders,
                ordersLoading: action.payload?.ordersLoading,
                ordersError: action.payload?.ordersError
            };
        case OrdersActionTypes.GET_ORDER_STARTED:
            return {
                ...state,
                order: action.payload?.order,
                orderLoading: action.payload?.orderLoading,
                orderError: action.payload?.ordersError
            };
        case OrdersActionTypes.GET_ORDER_COMPLETED:
            return {
                ...state,
                order: action.payload?.order,
                orderLoading: action.payload?.orderLoading,
                orderError: action.payload?.orderError
            };
        case OrdersActionTypes.GET_ORDER_FAILED:
            return {
                ...state,
                order: action.payload?.order,
                orderLoading: action.payload?.orderLoading,
                orderError: action.payload?.orderError
            };
        case OrdersActionTypes.GET_ORDERS_FILTERS_STARTED:
            return {
                ...state,
                filters: action.payload?.filters,
                filtersLoading: action.payload?.filtersLoading,
                filtersError: action.payload?.filtersError
            };
        case OrdersActionTypes.GET_ORDERS_FILTERS_COMPLETED:
            return {
                ...state,
                filters: action.payload?.filters,
                filtersLoading: action.payload?.filtersLoading,
                filtersError: action.payload?.filtersError
            };
        case OrdersActionTypes.GET_ORDERS_FILTERS_FAILED:
            return {
                ...state,
                filters: action.payload?.filters,
                filtersLoading: action.payload?.filtersLoading,
                filtersError: action.payload?.filtersError
            };
        default:
            return state;
    }
}