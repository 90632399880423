import React, { ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { OAuth2Service } from "../../../services/authentication/oauth2-service";
import mixpanel from "mixpanel-browser";

class LogoutScreen extends React.Component<RouteComponentProps<{}>> {
    public constructor(props: RouteComponentProps<{}>) {
        super(props);
        mixpanel.track("Logout");
    };
    componentDidMount() {
        OAuth2Service.instance.signoutRedirect();
    }

    public render(): ReactNode {
        return (
            null
        )
    }
}

const CallbackScreenWithRouter = withRouter(LogoutScreen);
export { CallbackScreenWithRouter as LogoutScreen }