import { EuiIcon } from "@elastic/eui";
import React, { ReactNode } from "react";
export interface LodableComponentProps {
    isLoading?: boolean;
    loadingIcon: ReactNode;
}


export class LoadableComponent extends React.Component<LodableComponentProps> {

    public render(): ReactNode {
        return (
            this.props.isLoading ? this.props.loadingIcon : this.props.children
        );
    }
}