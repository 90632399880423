import React, { Fragment, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FiShoppingBag, FiUsers } from "react-icons/fi";
import { WithTranslation, withTranslation } from "react-i18next";
import { SideMenuState } from "./side-menu.state";
import { EuiCollapsibleNav, EuiCollapsibleNavGroup, EuiIcon, EuiListGroup } from "@elastic/eui";
import { SmartbillsRootState } from "../../redux/smartbills.reducer";
import { toggleSideMenu } from "../../redux/dashboard-layout/dashboard-layout.actions";
import { DashboardLayoutDispatchProps } from "../../redux/dashboard-layout/dashboard-layout.props";
import { DashboardLayoutStateProps } from "../../redux/dashboard-layout/dashboard-layout.state";
import { FiCreditCard } from "react-icons/fi";
import { connect } from "react-redux";
import "./side-menu.scss"
import { ManageCompaniesStateProps } from "../../redux/manage-company/manage-company.state";

class SideMenu extends React.Component<RouteComponentProps & { layout: DashboardLayoutStateProps, companies: ManageCompaniesStateProps } & DashboardLayoutDispatchProps & WithTranslation, SideMenuState> {
    public constructor(props: RouteComponentProps & DashboardLayoutDispatchProps & WithTranslation & { layout: DashboardLayoutStateProps, companies: ManageCompaniesStateProps }) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }
    public render(): ReactNode {
        return (
            <EuiCollapsibleNav
                showCloseButton
                onClose={() => {
                    this.props.toggleSideMenu(false)
                }} isOpen={this.props.layout.sideMenuOpen}>
                {
                    this.renderAppMenu()
                }
            </EuiCollapsibleNav>
        )
    }

    private renderAppMenu() {
        if (this.props.companies.companies?.length! > 0) {
            return (
                <Fragment>
                    <EuiCollapsibleNavGroup
                        className="no-children"
                        initialIsOpen={this.isOpen("/home")}
                        title={this.props.t("home")}
                        iconType="home"
                        onClick={() => this.navigate("/")}
                        isCollapsible={true} />
                    <EuiCollapsibleNavGroup
                        title={this.props.t("database")}
                        className="menu-item"
                        iconType="database"
                        arrowDisplay="right"
                        initialIsOpen={this.isOpen("/database")}
                        isCollapsible={true}>
                        <EuiListGroup
                            listItems={[
                                {
                                    label: this.props.t("orders"),
                                    onClick: () => this.navigate('/database/orders'),
                                    icon: <FiShoppingBag />,
                                    isActive: this.isActive("/database/orders")
                                },
                            ]}
                            maxWidth="none"
                            color="subdued"
                            gutterSize="none"
                            size="m"
                        />
                    </EuiCollapsibleNavGroup>
                    <EuiCollapsibleNavGroup
                        title={this.props.t("customers:title")}
                        className="menu-item"
                        iconType="user"
                        arrowDisplay="right"
                        initialIsOpen={this.isOpen("/customers")}
                        isCollapsible={true}>
                        <EuiListGroup
                            listItems={[
                                {
                                    label: this.props.t("customers:cohorts"),
                                    onClick: () => this.navigate('/customers/cohorts'),
                                    icon: <FiUsers />,
                                    isActive: this.isActive("/customers/cohorts"),
                                },
                            ]}
                            maxWidth="none"
                            color="subdued"
                            gutterSize="none"
                            size="m"
                        />
                    </EuiCollapsibleNavGroup>
                    <EuiCollapsibleNavGroup
                        className="menu-item"
                        title={this.props.t("insights:title")}
                        iconType="visualizeApp"
                        arrowDisplay="right"
                        key="/menu-item"
                        iconProps={{ color: "text" }}
                        initialIsOpen={this.isOpen("/insights")}
                        isCollapsible={true}>
                        <EuiListGroup
                            listItems={[
                                {
                                    label: this.props.t("insights:map"),
                                    onClick: () => this.navigate('/insights/map'),
                                    iconType: 'mapMarker',
                                    isActive: this.isActive("/insights/map")
                                },
                                {
                                    label: this.props.t("insights:customers"),
                                    onClick: () => this.navigate('/insights/customers'),
                                    iconType: 'user',
                                    isActive: this.isActive("/insights/customers")
                                }
                            ]}
                            color="subdued"
                            gutterSize="none"
                            size="m"
                        />
                    </EuiCollapsibleNavGroup>
                    <EuiCollapsibleNavGroup
                        className="menu-item"
                        title={this.props.t("manage:title")}
                        iconProps={{ color: "text" }}
                        iconType="reportingApp"
                        arrowDisplay="right"
                        initialIsOpen={this.isOpen("/manage")}
                        isCollapsible={true}>
                        <EuiListGroup
                            listItems={[
                                {
                                    label: this.props.t("manage:company"),
                                    onClick: () => this.navigate('/manage/company'),
                                    iconType: 'user',
                                    isActive: this.isActive("/manage/company")
                                },
                                {
                                    label: this.props.t("manage:search-engine"),
                                    onClick: () => this.navigate('/manage/search-engine'),
                                    iconType: 'search',
                                    isActive: this.isActive("/manage/search-engine")
                                },
                            ]}
                            color="subdued"
                            gutterSize="none"
                            size="m"
                        />
                    </EuiCollapsibleNavGroup>
                </Fragment>
            )
        } else {
            return (
                <EuiCollapsibleNavGroup
                    className="no-children"
                    initialIsOpen={this.isOpen("/home")}
                    title={this.props.t("home")}
                    iconType="home"
                    onClick={() => this.navigate("/")}
                    isCollapsible={true} />
            )
        }
    }

    public navigate(path: string) {
        this.props.history.push(path);
        this.props.toggleSideMenu(false);
    }

    private isActive(key: string) {
        const currentActive = this.props.location.pathname;
        return key == currentActive;
    }
    private isOpen(key: string) {
        const currentActive = this.props.location.pathname;
        return currentActive.includes(key);
    }
}

const mapStateToProps = (state: SmartbillsRootState): { layout: DashboardLayoutStateProps, companies: ManageCompaniesStateProps } => {
    const { layout, manageCompanies } = state;
    return {
        layout: layout,
        companies: manageCompanies
    };
};

const mapDispatchToProps = (dispatch: any): DashboardLayoutDispatchProps => {
    return {
        toggleSideMenu: (sideMenOpen: boolean) => {
            dispatch(toggleSideMenu(sideMenOpen));
        }
    }
}


const SubMenuWithRouter = connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation("menu")(SideMenu)));
export { SubMenuWithRouter as SideMenu }
