import React, { ReactNode } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { CompanyNotLinked } from "../../components/manage-company/company-not-linked/company-not-linked";
import { HomeScreen } from "../../screens/home/home.screen";
import { ManageCompany as ManageCompanyScreen } from "../../screens/manage/manage-company/manage-company";

import { SettingsScreen } from "../../screens/settings/settings.screen";
import { SupportScreen } from "../../screens/support/support.screen";
import { SearchEngineScreen } from "../../screens/manage/search-engine/search-engine";
import { Error404 } from "../../components/error/404";
import { OrdersScreen } from "../../screens/orders/orders.screen";
import { OrderInsightsScreen } from "../../screens/insights/orders/orders-insights";
import { BasicMapScreen } from "../../screens/insights/basic-map/basic-map.screen";
import { CustomerInsightsScreen } from "../../screens/insights/customers/customers.screen";
import { ReceiptPage } from "../../screens/orders/receipt.page";
import { CohortsPage } from "../../screens/customers/cohorts/cohorts.page";
import { CohortPage } from "../../screens/customers/cohort/cohort.page";
import { ManageCompaniesStateProps } from "../../redux/manage-company/manage-company.state";
import { SmartbillsRootState } from "../../redux/smartbills.reducer";
import { connect } from "react-redux";
class Router extends React.Component<{ companies: ManageCompaniesStateProps }> {
    public render(): ReactNode {
        return this.renderFromState()
    }

    public renderFromState() {
        if (this.props.companies.companies?.length === 0) {
            return (
                <Switch>
                    <Route exact path="/" component={CompanyNotLinked} />
                    <Route exact path="/settings" component={SettingsScreen} />
                    <Route exact path="/support" component={SupportScreen} />
                    <Route path="/404" component={Error404} />
                    <Redirect to="/404" />
                </Switch >
            )
        } else {
            return (
                <Switch>
                    <Route exact path="/" component={HomeScreen} />
                    <Route exact path="/customers/cohorts" component={CohortsPage} />
                    <Route exact path="/customers/cohorts/:cohortId" component={CohortPage} />
                    <Route exact path="/database/orders" component={OrdersScreen} />
                    {
                        //<Route exact path="/database/orders/:orderId" component={ReceiptPage} />
                    }
                    <Route exact path="/insights/map" component={BasicMapScreen} />
                    <Route exact path="/insights/orders" component={OrderInsightsScreen} />
                    <Route exact path="/insights/customers" component={CustomerInsightsScreen} />
                    <Route exact path="/manage/company" component={ManageCompanyScreen} />
                    <Route exact path="/manage/search-engine" component={SearchEngineScreen} />
                    <Route exact path="/support" component={SupportScreen} />
                    <Route exact path="/settings" component={SettingsScreen} />
                    <Route path="/404" component={Error404} />
                    <Redirect to="/404" />
                </Switch>
            )
        }
    }
}

const mapStateToProps = (state: SmartbillsRootState): { companies: ManageCompaniesStateProps } => {
    const { manageCompanies } = state;
    return {
        companies: manageCompanies
    };
};
const RouterConnected = connect(mapStateToProps)(Router);
export { RouterConnected as Router }