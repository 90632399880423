export enum MapInsightsActionTypes {
    GET_MAP_RESULT_STARTED = 'GET_MAP_RESULT_STARTED',
    GET_MAP_RESULT_COMPLETED = 'GET_MAP_RESULT_COMPLETED',
    GET_MAP_RESULT_FAILED = 'GET_MAP_RESULT_FAILED',

    GET_PRODUCT_FILTERS_STARTED = 'GET_PRODUCT_FILTERS_STARTED',
    GET_PRODUCT_FILTERS_COMPLETED = 'GET_PRODUCT_FILTERS_COMPLETED',
    GET_PRODUCT_FILTERS_FAILED = 'GET_PRODUCT_FILTERS_FAILED',

    GET_AMOUNT_FILTER_STARTED = 'GET_AMOUNT_FILTER_STARTED',
    GET_AMOUNT_FILTER_COMPLETED = 'GET_AMOUNT_FILTER_COMPLETED',
    GET_AMOUNT_FILTER_FAILED = 'GET_AMOUNT_FILTER_FAILED',

    UPDATE_MAP_DATE_FILTERS = "UPDATE_MAP_DATE_FILTERS",
    UPDATE_MAP_PRODUCTS_FILTER = "UPDATE_MAP_PRODUCTS_FILTER",
    UPDATE_MAP_EXCLUDE_FILTER = "UPDATE_MAP_EXCLUDE_FILTER",
    UPDATE_MAP_FILTER_AMOUNT = "UPDATE_MAP_FILTER_AMOUNT",
    UPDATE_MAP_ORDER_AMOUNT_FILTER = "UPDATE_MAP_ORDER_AMOUNT_FILTER",
    UPDATE_MAP_FIRST_TIME_CUSTOMER_FILTER = "UPDATE_MAP_FIRST_TIME_CUSTOMER_FILTER",
    UPDATE_MAP_RECURRENT_CUSTOMER_FILTER = "UPDATE_MAP_RECURRENT_CUSTOMER_FILTER",
    UPDATE_MAP_AMOUNT_FILTERS = "UPDATE_MAP_AMOUNT_FILTERS",
    UPDATE_MAP_BOUNDS_FILTERS = "UPDATE_MAP_BOUNDS_FILTERS",
    UPDATE_INSTORE_CHECKBOX = "UPDATE_INSTORE_CHECKBOX",
    UPDATE_ONLINE_CHECKBOX = "UPDATE_ONLINE_CHECKBOX",
     
}