import { RetailAPI } from "../retail-api";
import { AxiosResponse, AxiosError } from "axios";
import { CompanyOwned } from "../../models/company/company-owned";
export class SearchEngineService {
    public static enableSearchEngine(enable: boolean): Promise<CompanyOwned> {
        return new Promise<CompanyOwned>((resolve, reject) => {
            RetailAPI.getInstance().post(`search-engine`, { enabled: enable }).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }
}