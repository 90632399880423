import { Table, Row, Col } from "antd";
import React, { Fragment, ReactNode } from "react";
import _ from "lodash";
import {
    EuiStat,
    EuiFlexItem,
    EuiFlexGroup,
    EuiPanel,
    EuiTitle,
    EuiText,
    EuiSpacer,
} from '@elastic/eui';
import { CustomerService } from "../../../services/insight/customers/customer.service";
import { CustomerOrderCount } from "../../../models/customers/customer-order-count";
import { Pie, Bar } from "react-chartjs-2";
import { WithTranslation, withTranslation } from "react-i18next";
import { DashboardPage } from "../../../components/dashboard-page/dashboard-page";
const formatNumber = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'CAD' });
class CustomerInsightsScreen extends React.Component<WithTranslation, { customers: CustomerOrderCount[] }> {
    public constructor(props: WithTranslation) {
        super(props);
        this.state = { customers: [] }
    }
    public componentDidMount() {
        CustomerService.getKnownCustomer().then((x) => this.setState({ customers: x }))
    }
    public render(): ReactNode {
        var firstTime = this.state.customers.filter(x => x.count <= 1)
        var recurrent = this.state.customers.filter(x => x.count > 1)
        var firstTimeRevenues = firstTime.reduce((a, b) => a + b.revenues, 0).toFixed(2)
        var recurrentRevenues = recurrent.reduce((a, b) => a + b.revenues, 0).toFixed(2)

        var meanFirstTime = _.mean(firstTime.map(x => x.revenues)).toFixed(2)
        var meanrecurrent = _.mean(recurrent.map(x => x.revenues))

        var store = this.state.customers.filter(x => x.shoppedInStore && !x.shoppedOnline)
        var storeRevenues = store.reduce((a, b) => a + b.revenues, 0).toFixed(2)
        var online = this.state.customers.filter(x => x.shoppedOnline && !x.shoppedInStore)
        var onlineRevenues = online.reduce((a, b) => a + b.revenues, 0).toFixed(2)
        var bothStoreOnline = this.state.customers.filter(x => x.shoppedInStore && x.shoppedOnline)
        var bothStoreRevenues = bothStoreOnline.reduce((a, b) => a + b.revenues, 0).toFixed(2)

        var mean = _.mean(this.state.customers.map(x => x.revenues)).toFixed(2)
        var meanStore = _.mean(store.map(x => x.revenues))
        var meanOnline = _.mean(online.map(x => x.revenues))
        var meanBoth = _.mean(bothStoreOnline.map(x => x.revenues))

        var groupByrecurrent = _.countBy(recurrent, x => x.count);
        var recurrentColors = Object.keys(groupByrecurrent).map(x => '#36A2EB')
        var lifetimeRevenues = _.sum(this.state.customers.map(x => x.revenues))

        const email = _.chain(this.state.customers)
            // Group the elements of Array based on `color` property
            .groupBy(x => {
                if (x.email != null) {

                    return x.email.split("@")[1].toLowerCase()
                } return ""
            }
            )
            // `key` is group's name (color), `value` is the array of objects
            .map((value, key) => ({ emailProvider: key, users: value }))
            .value()
        const retentionCustomers = {
            labels: [
                this.props.t("retention:first-time"),
                this.props.t("retention:recurrent"),
            ],
            datasets: [{
                data: [firstTime.length, recurrent.length],
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                ],
                hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                ]
            }]
        };
        const customerPerChannel = {
            labels: [
                this.props.t("store:physical"),
                this.props.t("store:online"),
                this.props.t("store:both")
            ],
            datasets: [{
                data: [store.length, online.length, bothStoreOnline.length],
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56'
                ],
                hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56'
                ]
            }]
        };
        const revenuesPerChannel = {
            labels: [
                this.props.t("store:physical"),
                this.props.t("store:online"),
                this.props.t("store:both")
            ],
            datasets: [{
                data: [storeRevenues, onlineRevenues, bothStoreRevenues],
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56'
                ],
                hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56'
                ]
            }]
        };
        const retentionRevenues = {
            labels: [
                this.props.t("retention:first-time"),
                this.props.t("retention:recurrent"),
            ],
            datasets: [{
                data: [firstTimeRevenues, recurrentRevenues],
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                ],
                hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                ]
            }]
        };

        const recurrentTimes = {
            labels: Object.keys(groupByrecurrent),
            datasets: [{
                data: Object.values(groupByrecurrent),
                backgroundColor: recurrentColors,
                hoverBackgroundColor: recurrentColors,
            }],
        }
        return (
            <DashboardPage title={this.props.t("title")} description={this.props.t("description")}>
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <EuiPanel>
                            <EuiStat isLoading={this.state.customers.length == 0} title={this.state.customers.length} description={this.props.t("total-customers")} titleColor="danger" />
                        </EuiPanel>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiPanel>
                            <EuiStat isLoading={this.state.customers.length == 0} title={formatNumber.format(Number(lifetimeRevenues))} description={this.props.t("lifetime-revenues")} titleColor="primary" />
                        </EuiPanel>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiPanel>
                            <EuiStat isLoading={this.state.customers.length == 0} title={formatNumber.format(Number(mean))} description={this.props.t("average-revenues")} titleColor="#F5A700" />
                        </EuiPanel>
                    </EuiFlexItem>
                </EuiFlexGroup>
                <Row gutter={[24, 24]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <EuiPanel>
                            <EuiTitle size="s"><h3>{this.props.t("retention:customers")}</h3></EuiTitle>
                            <Row style={{ flexDirection: "column" }} gutter={[24, 24]}>
                                <Col span={24}>
                                    <EuiFlexGroup responsive={false}>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={firstTime.length} description={this.props.t("retention:first-time")} titleColor="danger" />
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={recurrent.length} description={this.props.t("retention:recurrent")} titleColor="primary" />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                    <Pie data={retentionCustomers} options={{
                                        responsive: true, maintainAspectRatio: true,
                                        tooltips: {
                                            callbacks: {
                                                label: (tooltipItem: any, data: any) => {
                                                    var dataset = data.datasets[tooltipItem.datasetIndex];
                                                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                    var total = meta.total;
                                                    var currentValue = dataset.data[tooltipItem.index];
                                                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                                    return currentValue + ' (' + percentage + '%)';
                                                },
                                                title: (tooltipItem: any, data: any) => {
                                                    return data.labels[tooltipItem[0].index];
                                                }
                                            }
                                        }
                                    }} >
                                    </Pie>
                                </Col>
                                <Col span={24}>
                                    <EuiTitle size="s"><h3>{this.props.t("retention:revenues")}</h3></EuiTitle>
                                    <EuiFlexGroup responsive={false}>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={formatNumber.format(Number(firstTimeRevenues))} description={this.props.t("retention:first-time")} titleColor="danger" />
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={formatNumber.format(Number(recurrentRevenues))} description={this.props.t("retention:recurrent")} titleColor="primary" />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                    <Pie data={retentionRevenues} options={{
                                        responsive: true, maintainAspectRatio: true,
                                        tooltips: {
                                            callbacks: {
                                                label: (tooltipItem: any, data: any) => {
                                                    var dataset = data.datasets[tooltipItem.datasetIndex];
                                                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                    var total = meta.total;
                                                    var currentValue = dataset.data[tooltipItem.index];
                                                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                                    return formatNumber.format(currentValue) + ' (' + percentage + '%)';
                                                },
                                                title: (tooltipItem: any, data: any) => {
                                                    return data.labels[tooltipItem[0].index];
                                                }
                                            }
                                        }
                                    }} >
                                    </Pie>
                                </Col>
                                <Col span={24}>
                                    <EuiTitle size="s"><h3>{this.props.t("retention:average-revenue")}</h3></EuiTitle>
                                    <EuiFlexGroup responsive={false}>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={formatNumber.format(Number(meanFirstTime))} description={this.props.t("retention:first-time")} titleColor="danger" />
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={formatNumber.format(Number(meanrecurrent))} description={this.props.t("retention:recurrent")} titleColor="primary" />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </Col>
                            </Row>
                        </EuiPanel>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <EuiPanel>
                            <EuiTitle size="s"><h3>{this.props.t("store:customers")}</h3></EuiTitle>
                            <Row style={{ flexDirection: "column" }} gutter={[24, 24]}>
                                <Col span={24}>
                                    <EuiFlexGroup responsive={false}>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={store.length} description={this.props.t("store:physical")} titleColor="danger" />
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={online.length} description={this.props.t("store:online")} titleColor="primary" />
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={bothStoreOnline.length} description={this.props.t("store:both")} titleColor="#F5A700" />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                    <Pie data={customerPerChannel} options={{
                                        responsive: true, maintainAspectRatio: true,
                                        tooltips: {
                                            callbacks: {
                                                label: (tooltipItem: any, data: any) => {
                                                    var dataset = data.datasets[tooltipItem.datasetIndex];
                                                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                    var total = meta.total;
                                                    var currentValue = dataset.data[tooltipItem.index];
                                                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                                    return currentValue + ' (' + percentage + '%)';
                                                },
                                                title: (tooltipItem: any, data: any) => {
                                                    return data.labels[tooltipItem[0].index];
                                                }
                                            }
                                        }
                                    }} >
                                    </Pie>
                                </Col>
                                <Col span={24}>
                                    <EuiTitle size="s"><h3>{this.props.t("store:revenues")}</h3></EuiTitle>
                                    <EuiFlexGroup responsive={false}>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={formatNumber.format(Number(storeRevenues))} description={this.props.t("store:physical")} titleColor="danger" />
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={formatNumber.format(Number(onlineRevenues))} description={this.props.t("store:online")} titleColor="primary" />
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={formatNumber.format(Number(bothStoreRevenues))} description={this.props.t("store:both")} titleColor="#F5A700" />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                    <Pie data={revenuesPerChannel} options={{
                                        responsive: true, maintainAspectRatio: true,
                                        tooltips: {
                                            callbacks: {
                                                label: (tooltipItem: any, data: any) => {
                                                    var dataset = data.datasets[tooltipItem.datasetIndex];
                                                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                                    var total = meta.total;
                                                    var currentValue = dataset.data[tooltipItem.index];
                                                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                                    return formatNumber.format(currentValue) + ' (' + percentage + '%)';
                                                },
                                                title: (tooltipItem: any, data: any) => {
                                                    return data.labels[tooltipItem[0].index];
                                                }
                                            }
                                        }
                                    }} >
                                    </Pie>
                                </Col>
                                <Col span={24}>
                                    <EuiTitle size="s"><h3>{this.props.t("store:average-revenue")}</h3></EuiTitle>
                                    <EuiFlexGroup responsive={false}>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={formatNumber.format(Number(meanStore))} description={this.props.t("store:physical")} titleColor="danger" />
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={formatNumber.format(Number(meanOnline))} description={this.props.t("store:online")} titleColor="primary" />
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <EuiStat titleSize="s" isLoading={this.state.customers.length == 0} title={formatNumber.format(Number(meanBoth))} description={this.props.t("store:both")} titleColor="#F5A700" />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </Col>
                            </Row>
                        </EuiPanel>
                    </Col>
                    <Col span={24}>
                        <EuiPanel>
                            <EuiTitle size="s"><h3>{this.props.t("recurrent-orders:title")}</h3></EuiTitle>
                            <Bar
                                height={100}
                                data={recurrentTimes}
                                options={{
                                    legend: {
                                        display: false
                                    }
                                }}
                            />
                        </EuiPanel>
                    </Col>
                </Row>
            </DashboardPage>
        );
    }
}
const CustomerInsightsScreenTranslated = withTranslation("customersInsights")(CustomerInsightsScreen)
export { CustomerInsightsScreenTranslated as CustomerInsightsScreen }