import { AccountDispatchProps } from "../../redux/account/account.props";
import { AccountStateProps } from "../../redux/account/account.state";
import { LoadingPage } from "../../screens/authentication/loading/loading-page";
import { ManageCompaniesDispatchProps } from "../../redux/manage-company/manage-company.props";
import { ManageCompaniesStateProps } from "../../redux/manage-company/manage-company.state";
import { NavigationHeader } from "../header/header";
import React from "react";
import { Redirect } from "react-router-dom";
import { RetailAPI } from "../../services/retail-api";
import { Router } from "../router/router";
import { SideMenu } from "../side-menu/side-menu";
import { SmartbillsRootState } from "../../redux/smartbills.reducer";
import { UserState } from 'redux-oidc';
import { connect } from "react-redux";
import { updateAccountClaims } from "../../redux/account/account.actions";
import { SocketService } from "../../services/socket/socket.service";
import { EuiPage } from "@elastic/eui";
import { WithTranslation, withTranslation } from "react-i18next";

class DashboardLayout extends React.Component<WithTranslation & { oidc: UserState, companies: ManageCompaniesStateProps, account: AccountStateProps } & ManageCompaniesDispatchProps & AccountDispatchProps> {
    public constructor(props: any) {
        super(props);
        SocketService.getInstance();
        RetailAPI.setToken(this.props.oidc.user?.access_token);
        this.props.updateAccountClaims!();
    }
    public render() {
        if (this.props.companies.companiesLoading || this.props.companies.companyLoading || this.props.account.updateClaimsLoading) {
            return <LoadingPage description={this.props.t("loading:loading")}></LoadingPage>
        } else {
            if (this.props.companies.companyError || this.props.account.updateClaimsError) {
                return (<Redirect to="/error"></Redirect>)
            }
            return (
                <EuiPage>
                    <NavigationHeader></NavigationHeader>
                    <SideMenu></SideMenu>
                    <Router></Router>
                </EuiPage>
            )
        }
    }
}

const mapStateToProps = (state: SmartbillsRootState): { oidc: UserState, companies: ManageCompaniesStateProps, account: AccountStateProps } => {
    const { oidc } = state;
    const { manageCompanies } = state;
    const { account } = state;

    return {
        oidc: oidc,
        companies: manageCompanies,
        account
    };
};
const mapDispatchToProps = (dispatch: any): ManageCompaniesDispatchProps & AccountDispatchProps => {
    return {
        updateAccountClaims: () => {
            dispatch(updateAccountClaims())
        }
    }
}


const DashboardLayoutConnected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardLayout));
export { DashboardLayoutConnected as DashboardLayout }