import { RetailAPI } from "../retail-api";
import { AxiosResponse, AxiosError } from "axios";
import { SBProductFilter } from "../../view-models/products/product-filter";
export class ProductService {
    public static getProductsFilter(): Promise<SBProductFilter[]> {
        return new Promise<SBProductFilter[]>((resolve, reject) => {
            RetailAPI.getInstance().get(`products/filters`).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }
}