import { EuiText, EuiTitle } from "@elastic/eui";
import { notification } from "antd";
import { ArgsProps } from "antd/lib/notification";
import { AxiosError } from "axios";
import React from "react";
import { Dispatch } from "redux";
import { SBCohort } from "../../../models/customers/SBCohorts";
import { CohortsService } from "../../../services/customers/cohorts/cohorts.service";
import { ProductService } from "../../../services/products/product-service";
import { SBCohortOverviewViewModel } from "../../../view-models/customers/cohorts/cohort-overview";
import { SBCohortStats } from "../../../view-models/customers/cohorts/stats/cohort-stats";
import { SBCohortOrderCountGraphPoint } from "../../../view-models/customers/cohorts/stats/order-count-point";
import { SmartbillsRootState } from "../../smartbills.reducer";
import { CohortPayload } from "./cohort.state";
import { CohortActionTypes } from "./cohort.types";
import { deleteCohortCompleted as deleteCohortFromCohorts, showNotification } from "../cohorts/cohorts.actions";
import { push } from "connected-react-router";
import { getOrdersFilters } from "../../orders/orders.actions";
import { DeleteCohortSuccessNotification } from "../../../components/notifications/cohort/cohort-notification";
export interface CohortAction {
    type: CohortActionTypes;
    payload: CohortPayload;
}
export const toggleConfigurationMenu = (isOpen: boolean): CohortAction => {
    return {
        type: CohortActionTypes.TOGGLE_CONFIGURATION_MENU,
        payload: {
            configurationMenuOpen: isOpen
        }
    }
};
export const toggleDeleteMenu = (isOpen: boolean): CohortAction => {
    return {
        type: CohortActionTypes.TOGGLE_COHORT_DELETE_MENU,
        payload: {
            deleteMenuOpen: isOpen
        }
    }
};
export const getCohort = () => {
    return (dispatch: Dispatch, getState: () => SmartbillsRootState) => {
        var { activeCohort } = getState().customers.cohort;
        dispatch(getCohortStarted());
        CohortsService.getCohort(activeCohort!).then((cohort) => {
            dispatch(getCohortCompleted(cohort))
        }).catch((error: AxiosError) => {
            dispatch(getCohortFailed(error))
        })
    };
}

export const getCohortStarted = (): CohortAction => {
    return {
        type: CohortActionTypes.GET_COHORT_STARTED,
        payload: {
            loading: true,
            error: undefined
        }
    }
};

export const resetCohortState = (): CohortAction => {
    return {
        type: CohortActionTypes.RESET_COHORT_STATE,
        payload: {

        }
    }
};

export const getCohortCompleted = (cohort: SBCohort): CohortAction => {
    return {
        type: CohortActionTypes.GET_COHORT_COMPLETED,
        payload: {
            cohort: cohort,
            loading: false,
            error: undefined
        }
    }
};
export const getCohortFailed = (error: AxiosError): CohortAction => {
    return {
        type: CohortActionTypes.GET_COHORT_FAILED,
        payload: {
            loading: false,
            error: error
        }
    }
};

export const setActiveCohort = (id: number): CohortAction => {
    return {
        type: CohortActionTypes.SET_ACTIVE_COHORT,
        payload: {
            activeCohort: id,
        }
    }
};
export const setActiveTab = (activeTab: string): CohortAction => {
    return {
        type: CohortActionTypes.SET_ACTIVE_TAB,
        payload: {
            activeTab: activeTab,
        }
    }
};

export const updateSelectedFilters = (key: string, value: string): CohortAction => {
    return {
        type: CohortActionTypes.UPDATE_SELECTED_FITLERS,
        payload: {
            key: key,
            value: value
        }
    }
};


export const getCohortOverview = () => {
    return (dispatch: Dispatch, getState: () => SmartbillsRootState) => {
        var { activeCohort } = getState().customers.cohort;
        dispatch(getCohortOverviewStarted());
        CohortsService.getCohortOverview(activeCohort!).then((overview) => {
            dispatch(getCohortOverviewCompleted(overview))
        }).catch((error: AxiosError) => {
            dispatch(getCohortOverviewFailed(error))
        })
    };
}

export const getCohortOverviewStarted = (): CohortAction => {
    return {
        type: CohortActionTypes.GET_COHORT_OVERVIEW_STARTED,
        payload: {
            overviewLoading: true,
            overviewError: undefined
        }
    }
};

export const getCohortOverviewCompleted = (overview: SBCohortOverviewViewModel): CohortAction => {
    return {
        type: CohortActionTypes.GET_COHORT_OVERVIEW_COMPLETED,
        payload: {
            overviewLoading: false,
            overview: overview,
            error: undefined,
        }
    }
};
export const getCohortOverviewFailed = (error: AxiosError): CohortAction => {
    return {
        type: CohortActionTypes.GET_COHORT_OVERVIEW_FAILED,
        payload: {
            overviewLoading: false,
            overviewError: error
        }
    }
};



export const deleteCohort = () => {
    return (dispatch: Dispatch, getState: () => SmartbillsRootState) => {
        var { activeCohort, cohort } = getState().customers.cohort;
        dispatch(deleteCohortStarted());
        CohortsService.deleteCohort(activeCohort!).then(() => {
            dispatch<any>(toggleDeleteMenu(false))
            dispatch<any>(toggleConfigurationMenu(false))
            dispatch<any>(deleteCohortCompleted(cohort!))
            dispatch<any>(showNotification(new DeleteCohortSuccessNotification(cohort!)))
            dispatch<any>(deleteCohortFromCohorts(cohort!))
            dispatch(push('/customers/cohorts'))
        }).catch((error: AxiosError) => {
            dispatch(deleteCohortFailed(error))
        })
    };
}

export const deleteCohortStarted = (): CohortAction => {
    return {
        type: CohortActionTypes.CONFIGURATION_DELETE_COHORT_STARTED,
        payload: {
            deleteLoading: true,
            deleteError: undefined
        }
    }
};


export const changeClientsCurrentPage = (currentPage: number): CohortAction => {
    return {
        type: CohortActionTypes.CHANGE_CURRENT_PAGE,
        payload: {
            clientsCurrentPage: currentPage
        }
    }
};

export const changeClientsRowsPerPage = (rowsPerPage: number): CohortAction => {
    return {
        type: CohortActionTypes.CHANGE_ROWS_PER_PAGE,
        payload: {
            clientsRowsPerPage: rowsPerPage
        }
    }
};


export const deleteCohortCompleted = (cohort: SBCohort) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: CohortActionTypes.CONFIGURATION_DELETE_COHORT_COMPLETED,
            payload: {
                deleteLoading: false,
                deleteError: undefined
            }
        })
    }
};
export const deleteCohortFailed = (error: AxiosError): CohortAction => {
    return {
        type: CohortActionTypes.CONFIGURATION_DELETE_COHORT_FAILED,
        payload: {
            deleteLoading: false,
            deleteError: error
        }
    }
};

export const updateCohort = () => {
    return (dispatch: Dispatch, getState: () => SmartbillsRootState) => {
        var { activeCohort } = getState().customers.cohort;
        var { cohort } = getState().customers.cohort;
        dispatch(updateCohortStarted());
        CohortsService.updateCohort(activeCohort!, cohort!).then((cohort) => {
            dispatch(updateCohortCompleted(cohort))
            dispatch(toggleConfigurationMenu(false))
            dispatch<any>(getCohortOverview())
            dispatch<any>(getRevenuesOverTime())
            dispatch<any>(getOrdersFilters())
        }).catch((error: AxiosError) => {
            dispatch(updateCohortFailed(error))
        })
    };
}

export const updateCohortStarted = (): CohortAction => {
    return {
        type: CohortActionTypes.UPDATE_COHORT_STARTED,
        payload: {
            updateLoading: true,
            updateError: undefined
        }
    }
};

export const updateCohortCompleted = (cohort: SBCohort): CohortAction => {
    return {
        type: CohortActionTypes.UPDATE_COHORT_COMPLETED,
        payload: {
            cohort: cohort,
            updateLoading: false,
            updateError: undefined
        }
    }
};
export const updateCohortFailed = (error: AxiosError): CohortAction => {
    return {
        type: CohortActionTypes.UPDATE_COHORT_FAILED,
        payload: {
            updateLoading: false,
            updateError: error
        }
    }
};


export const getRevenuesOverTime = () => {
    return (dispatch: Dispatch, getState: () => SmartbillsRootState) => {
        var { activeCohort } = getState().customers.cohort;
        var { cohort } = getState().customers.cohort;
        dispatch(getRevenuesOverTimeStarted());
        CohortsService.getRevenuesOverTime(activeCohort!).then((cohort) => {
            dispatch(getRevenuesOverTimeCompleted(cohort))
        }).catch((error: AxiosError) => {
            dispatch(getRevenuesOverTimeFailed(error))
        })
    };
}

export const getRevenuesOverTimeStarted = (): CohortAction => {
    return {
        type: CohortActionTypes.GET_REVENUES_OVER_TIME_STARTED,
        payload: {
            statsLoading: true,
        }
    }
};

export const getRevenuesOverTimeCompleted = (stats: SBCohortStats): CohortAction => {
    return {
        type: CohortActionTypes.GET_REVENUES_OVER_TIME_COMPLETED,
        payload: {
            stats: stats,
            statsLoading: false,
            statsError: undefined
        }
    }
};
export const getRevenuesOverTimeFailed = (error: AxiosError): CohortAction => {
    return {
        type: CohortActionTypes.GET_REVENUES_OVER_TIME_FAILED,
        payload: {
            statsLoading: false,
            statsError: error

        }
    }
};