export enum CohortsActionTypes {
    GET_COHORTS_STARTED = "GET_COHORTS_STARTED",
    GET_COHORTS_COMPLETED = "GET_COHORTS_COMPLETED",
    GET_COHORTS_FAILED = "GET_COHORTS_FAILED",
    DELETE_COHORT_STARTED = "DELETE_COHORT_STARTED",
    DELETE_COHORT_COMPLETED = "DELETE_COHORT_COMPLETED",
    DELETE_COHORT_FAILED = "DELETE_COHORT_FAILED",
    CREATE_COHORT_STARTED = "CREATE_COHORT_STARTED",
    CREATE_COHORT_COMPLETED = "CREATE_COHORT_COMPLETED",
    CREATE_COHORT_FAILED = "CREATE_COHORTS_FAILED",
    UPDATE_COHORTS_LIST_ITEM_STARTED = "UPDATE_COHORTS_LIST_ITEM_STARTED",
    UPDATE_COHORTS_LIST_ITEM_COMPLETED = "UPDATE_COHORTS_LIST_ITEM_COMPLETED",
    UPDATE_COHORTS_LIST_ITEM_FAILED = "UPDATE_COHORTS_LIST_ITEM_FAILED",
    UPDATE_COHORT_NAME = "UPDATE_COHORT_NAME",
    UPDATE_COHORT_DESCRIPTION = "UPDATE_COHORT_DESCRIPTION",
    TOGGLE_COHORT_MENU = "TOGGLE_COHORT_MENU",
    TOGGLE_CREATE_MENU = "TOGGLE_CREATE_MENU",
    TOGGLE_DELETE_MENU = "TOGGLE_DELETE_MENU",
    TOGGLE_UPDATE_MENU = "TOGGLE_UPDATE_MENU",
    SET_ACTIVE_COHORT = "SET_ACTIVE_COHORT",
    CREATION_SUCCESS_NOTIFICATION = "CREATION_SUCCESS_NOTIFICATION",
    RESET_FORM = "RESET_FORM",
    VALIDATE_FORM = "VALIDATE_FORM",
    VALIDATE_NAME = "VALIDATE_NAME",
    VALIDATE_DESCRIPTION = "VALIDATE_DESCRIPTION",
}