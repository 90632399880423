import { AxiosError, AxiosResponse } from "axios";
import { RetailAPI } from "../../retail-api";
import { CustomerOrderCount } from "../../../models/customers/customer-order-count";

export class CustomerService {
    public static getKnownCustomer(): Promise<CustomerOrderCount[]> {
        return new Promise<CustomerOrderCount[]>((resolve, reject) => {
            RetailAPI.getInstance().get(`insights/customers`).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }
}