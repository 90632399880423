import { dateFormatAliases, EuiAccordion, EuiFlexGroup, EuiFlexItem, EuiLoadingChart, EuiPanel, EuiSpacer, EuiTitle, formatDate } from "@elastic/eui";
import { Axis, LineSeries, Chart, Position, Settings, timeFormatter, niceTimeFormatByDay, Datum, AreaSeries, BarSeries } from '@elastic/charts';

import React, { Fragment, ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CohortStateProps } from "../../../../../redux/customers/cohort/cohort.state";
import { OrdersStateProps } from "../../../../../redux/orders/orders.state";
import { ProductsStateProps } from "../../../../../redux/products/products.state";
import { SmartbillsRootState } from "../../../../../redux/smartbills.reducer";
import '@elastic/charts/dist/theme_only_light.css';
import moment from "moment";
import { SBCohortOrderCountGraphPoint } from "../../../../../view-models/customers/cohorts/stats/order-count-point";
import { LoadableComponent } from "../../../../../components/loadable-component/loadable-component";
import _ from "lodash";

class CohortOrdersPanel extends React.Component<WithTranslation & { cohort: CohortStateProps, products: ProductsStateProps, orders: OrdersStateProps }> {
    public render(): ReactNode {
        const cumulativeSumOrders = (sum => (value: SBCohortOrderCountGraphPoint) => {
            return {
                receiptsCount: (sum += value.receiptsCount).toFixed(2),
                date: value.date
            }
        })(0);
        return (
            <Fragment>
                <EuiSpacer></EuiSpacer>
                <EuiAccordion initialIsOpen id="revenues-over-time" buttonContent={this.props.t("orders-panel:orders-over-time")}>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <LoadableComponent isLoading={this.props.cohort.statsLoading} loadingIcon={<EuiLoadingChart></EuiLoadingChart>} >
                                <Chart size={{ height: 250 }}>
                                    <BarSeries
                                        id={this.props.t("orders-panel:orders-count")}
                                        name={this.props.t("orders-panel:orders-count")}
                                        data={this.props.cohort.stats!.revenuesOverTime!}
                                        xScaleType="time"
                                        xAccessor="date"
                                        yAccessors={["receiptsCount"]}
                                        color="#006BB4"
                                    />
                                    <Axis
                                        id="bottom-axis"
                                        position="bottom"
                                        title="Date"
                                        tickFormat={(date) => moment(date).format("LL")}
                                        showGridLines
                                    />
                                    <Axis
                                        id="left-axis"
                                        position="left"
                                        title={this.props.t("orders-panel:orders-count")}
                                        showGridLines
                                    />
                                </Chart>
                            </LoadableComponent>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <LoadableComponent isLoading={this.props.cohort.statsLoading} loadingIcon={<EuiLoadingChart></EuiLoadingChart>} >
                                <Chart size={{ height: 250 }}>
                                    <AreaSeries
                                        id="financial"
                                        name="Financial"
                                        data={this.props.cohort.stats!.revenuesOverTime!.map(cumulativeSumOrders)}
                                        xScaleType="time"
                                        xAccessor="date"
                                        yAccessors={["receiptsCount"]}
                                        color="#006BB4"
                                    />
                                    <Axis
                                        id="bottom-axis"
                                        position="bottom"
                                        title="Date"
                                        tickFormat={(date) => moment(date).format("LL")}
                                        showGridLines
                                    />
                                    <Axis
                                        id="left-axis"
                                        position="left"
                                        title={this.props.t("orders-panel:total-orders-count")}
                                        showGridLines
                                    />
                                </Chart>
                            </LoadableComponent>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiAccordion>
            </Fragment>
        )
    }
}

const mapStateToProps = (state: SmartbillsRootState): { cohort: CohortStateProps, products: ProductsStateProps, orders: OrdersStateProps } => {
    const { products, orders } = state;
    const { cohort } = state.customers;
    return {
        cohort: cohort,
        products: products,
        orders: orders
    };
};

const mapDispatchToProps = (dispatch: any): any => {
    return {

    }
}

const CohortOrdersPanelTranslated = withTranslation("cohort")(connect(mapStateToProps)(CohortOrdersPanel));
export { CohortOrdersPanelTranslated as CohortOrdersPanel }