import { Button, Col, Form, Input, Result, Row } from "antd";
import React, { ReactNode, Fragment } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Text } from "@zeit-ui/react";
import { ValidateStatus } from "antd/lib/form/FormItem";

class CompanyNotLinked extends React.Component<WithTranslation, { hostnameStatus: ValidateStatus, url: string }> {
    public constructor(props: WithTranslation) {
        super(props);
        this.state = { hostnameStatus: "", url: "" }
    }
    public render(): ReactNode {
        return (
            <Row justify="center" align="middle" style={{ height: "100%", width: "100%" }}>
                <Result
                    status="info"
                    title={this.props.t("manageCompany:companyNotLinked")}
                    subTitle={<Fragment>
                        <Text>{this.props.t("manageCompany:companyNotLinkedDescription")}</Text>
                        <Button danger href="https://apps.shopify.com/smartbills">{this.props.t("manageCompany:installButton")}</Button>
                    </Fragment>}
                />
            </Row>
        )
    }
    private validateCompanyName(shop: string) {
        var url = shop + ".myshopify.com";
        this.setState({ hostnameStatus: "validating", url })
        var isValid = /[a-zA-Z0-9][a-zA-Z0-9\-]*\.myshopify\.com[\/]?/.test(url);
        if (isValid) {
            this.setState({ hostnameStatus: "success" })
        } else {
            this.setState({ hostnameStatus: "error" })
        }
    }
}
const CompanyNotLinkedTranslated = withTranslation()(CompanyNotLinked)
export { CompanyNotLinkedTranslated as CompanyNotLinked }