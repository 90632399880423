export enum ManageCompaniesActionTypes {
    GET_COMPANIES_STARTED = 'GET_COMPANIES_STARTED',
    GET_COMPANIES_COMPLETED = 'GET_COMPANIES_COMPLETED',
    GET_COMPANIES_FAILED = 'GET_COMPANIES_FAILED',
    GET_DEFAULT_COMPANY_STARTED = 'GET_DEFAULT_COMPANY_STARTED',
    GET_DEFAULT_COMPANY_COMPLETED = 'GET_DEFAULT_COMPANY_COMPLETED',
    GET_DEFAULT_COMPANY_FAILED = 'GET_DEFAULT_COMPANY_FAILED',
    UPDATE_DEFAULT_COMPANY = 'UPDATE_DEFAULT_COMPANY',
    UPDATE_DEFAULT_COMPANY_STARTED = 'UPDATE_DEFAULT_COMPANY_STARTED',
    UPDATE_DEFAULT_COMPANY_COMPLETED = 'UPDATE_DEFAULT_COMPANY_COMPLETED',
    UPDATE_DEFAULT_COMPANY_FAILED = 'UPDATE_DEFAULT_COMPANY_FAILED',

}