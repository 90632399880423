import * as signalR from "@microsoft/signalr";
import { store } from "../../redux/store";
import { updateDefaultCompany } from "../../redux/manage-company/manage-company.actions";

export class SocketService {
    private static instance: signalR.HubConnection;
    public static getInstance(): signalR.HubConnection {
        if (!SocketService.instance) {
            try {
                SocketService.instance = new signalR.HubConnectionBuilder()
                    .withUrl(`${process.env.REACT_APP_SOCKET_URL}`, { accessTokenFactory: () => store.getState().oidc.user?.access_token })
                    .configureLogging(signalR.LogLevel.Information)
                    .withAutomaticReconnect()
                    .build()
                
                SocketService.instance.on("sync-update", (company) => {
                    store.dispatch(updateDefaultCompany(company));
                });

                SocketService.instance.start().catch(x => x);
            } catch (exception) {
                console.log(exception)
            }
        }
        return SocketService.instance;
    }
}