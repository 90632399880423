import { EuiBreadcrumbs, EuiButtonEmpty, EuiButtonIcon, EuiComboBox, EuiComboBoxOptionOption, EuiDatePicker, EuiDatePickerRange, EuiDescribedFormGroup, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiForm, EuiFormRow, EuiHorizontalRule, EuiIcon, EuiPanel, EuiSpacer, EuiStat, EuiSuperDatePicker, EuiSuperSelect, EuiTabbedContent, EuiTabbedContentTab, EuiText, EuiTitle } from "@elastic/eui";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { DashboardPage } from "../../../components/dashboard-page/dashboard-page";
import { getCohort, getCohortOverview, getRevenuesOverTime, resetCohortState, setActiveCohort, setActiveTab, toggleConfigurationMenu, updateSelectedFilters } from "../../../redux/customers/cohort/cohort.actions";
import { CohortStateProps } from "../../../redux/customers/cohort/cohort.state";
import { getOrdersFilters } from "../../../redux/orders/orders.actions";
import { OrdersStateProps } from "../../../redux/orders/orders.state";
import { getProductsFilter } from "../../../redux/products/products.actions";
import { ProductsDispatchProps } from "../../../redux/products/products.props";
import { ProductsStateProps } from "../../../redux/products/products.state";
import { SmartbillsRootState } from "../../../redux/smartbills.reducer";

import "./cohort.page.scss"
import { CohortPageDispatchProps, CohortProps } from "./cohort.props";
import { CohortConfigurationFlyout } from "./panels/cohort-configuration/cohort-configuration-flyout";
import { CohortLocationPanel } from "./panels/cohort-location/cohort-location-panel";
import { CohortOrdersPanel } from "./panels/cohort-orders/cohort-orders-panel";
import { CohortOverviewPanel } from "./panels/cohort-overview/cohort-overview-panel";
import { CohortProductsPanel } from "./panels/cohort-products/cohort-products-panel";
import { CohortRevenuesPanel } from "./panels/cohort-revenues/cohort-revenues-panel";
import { CohortUsersPanel } from "./panels/cohort-users/cohort-users-panel";
class CohortPage extends React.Component<CohortProps> {

    public componentDidMount() {
        try {
            var id = Number(this.props.match.params.cohortId)
            if (Number.isNaN(id)) {
                throw "Not found";
            }
            this.props.setActiveCohort(id);
            this.props.getCohort();
            this.props.getCohortOverview();
            this.props.getProductsFilter();
            this.props.getOrdersFilters();
            this.props.getStats();
        } catch {
            this.props.history.replace("/404")
        }
    }

    public componentWillUnmount() {
        this.props.resetCohortState();
    }

    public render() {
        if (this.props.cohort.error?.response?.status == 404) {
            this.props.history.replace("/404")
        }
        return (
            <DashboardPage
                title={this.props.cohort.cohort?.name}
                ability={
                    <EuiButtonEmpty onClick={() => this.props.toggleConfigurationMenu(true)} iconType="gear">
                        {this.props.t("configuration")}
                    </EuiButtonEmpty >}
                breadcrumb={{
                    responsive: false,
                    breadcrumbs: [{
                        text: this.props.t("cohorts"),
                        onClick: () => { this.props.history.push("/customers/cohorts") }
                    }, {
                        text: this.props.cohort.cohort?.name
                    }
                    ]
                }}
            >
                <EuiSpacer size="s"></EuiSpacer>
                <EuiTabbedContent
                    className="tabs"
                    autoFocus='initial'
                    tabs={this.tabs}>
                </EuiTabbedContent>
                {this.props.cohort.configurationMenuOpen && <CohortConfigurationFlyout></CohortConfigurationFlyout>}
                <EuiSpacer></EuiSpacer>
            </DashboardPage >
        );
    }

    private tabs: EuiTabbedContentTab[] = [
        {
            id: "overview",
            name: <span>
                <EuiIcon type="visTable" />&nbsp;{this.props.t("tabs:overview")}</span>,
            content: (
                <CohortOverviewPanel></CohortOverviewPanel>
            )
        },
        {
            id: "revenues",
            name: <span>
                <EuiIcon type="stats" />&nbsp;{this.props.t("tabs:revenues")}</span>,
            content: (
                <CohortRevenuesPanel />
            )
        },
        {
            id: "Commandes",
            name: <span>
                <EuiIcon type="package" />&nbsp;{this.props.t("tabs:commandes")}</span>,
            content: (
                <CohortOrdersPanel />
            )
        },
        {
            id: "products",
            name: <span>
                <EuiIcon type="tag" />&nbsp;{this.props.t("tabs:products")}</span>,
            content: (
                <CohortProductsPanel />
            )
        },
        {
            id: "location",
            name: <span>
                <EuiIcon type="mapMarker" />&nbsp;{this.props.t("tabs:shipping")}</span>,
            content: (
                <CohortLocationPanel />
            )
        },
        {
            id: "users",
            name: <span>
                <EuiIcon type="usersRolesApp" color="text" /> &nbsp;{this.props.t("tabs:customers")}</span>,
            content: <CohortUsersPanel />
        },
    ]
}


const mapStateToProps = (state: SmartbillsRootState): { cohort: CohortStateProps, products: ProductsStateProps, orders: OrdersStateProps } => {
    const { products, orders } = state;
    const { cohort } = state.customers;
    return {
        cohort: cohort,
        products: products,
        orders: orders
    };
};

const mapDispatchToProps = (dispatch: any): CohortPageDispatchProps => {
    return {
        resetCohortState: () => {
            dispatch(resetCohortState())
        },
        getCohort: () => {
            dispatch(getCohort())
        },
        setActiveCohort: (id: number) => {
            dispatch(setActiveCohort(id))
        },
        setActiveTab: (activeTab) => {
            dispatch(setActiveTab(activeTab))
        },
        getCohortOverview: () => {
            dispatch(getCohortOverview())
        },
        getProductsFilter: () => {
            dispatch(getProductsFilter())
        },
        getOrdersFilters: () => {
            dispatch(getOrdersFilters())
        },
        getStats: () => {
            dispatch(getRevenuesOverTime())
        },
        toggleConfigurationMenu: (isOpen) => {
            dispatch(toggleConfigurationMenu(isOpen))
        }
    }
}

const CohortPageTranslated = withTranslation("cohort")(connect(mapStateToProps, mapDispatchToProps)(withRouter(CohortPage)));
export { CohortPageTranslated as CohortPage }