import { EuiButton, EuiCallOut, EuiComboBox, EuiComboBoxOptionOption, EuiDatePicker, EuiDatePickerRange, EuiDescribedFormGroup, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiForm, EuiFormRow, EuiHorizontalRule, EuiOverlayMask, EuiPanel, EuiSpacer, EuiSuperSelect, EuiText, EuiTitle } from "@elastic/eui";
import moment from "moment";
import React, { Fragment, ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DeleteModal } from "../../../../../components/delete-modal/delete-modal";
import { deleteCohort, toggleConfigurationMenu, toggleDeleteMenu, updateCohort, updateSelectedFilters } from "../../../../../redux/customers/cohort/cohort.actions";
import { SmartbillsRootState } from "../../../../../redux/smartbills.reducer";
import { CohortConfigurationDispatchProps, CohortConfigurationProps, CohortConfigurationStateProps } from "./cohort-overview.props";
import "./cohort-configuration.scss"
class CohortConfigurationFlyout extends React.Component<CohortConfigurationProps> {
    public render(): ReactNode {
        const discountFilters = this.createComboBoxOptions(this.props.orders.filters?.discountCodes);
        const localeFitlers = this.createComboBoxOptions(this.props.orders.filters?.locales);
        const paymentStatusFilters = this.createComboBoxOptions(this.props.orders.filters?.paymentStatus);
        const gatewaysFilters = this.createComboBoxOptions(this.props.orders.filters?.gateways);
        const creditCardsFilters = this.createComboBoxOptions(this.props.orders.filters?.creditCards);
        const storesFilters = this.createStoresOptions();
        const productsFilters = this.createProductsOptions();
        const { cohort } = this.props.cohort
        console.log(this.props.t("delete:type"))
        return (
            <EuiFlyout size="l" ownFocus onClose={() => { this.props.toggleConfigurationMenu(false) }}>
                <EuiFlyoutHeader hasBorder>
                    <EuiTitle size="l"><h2>{this.props.t("configuration-panel:title")}</h2></EuiTitle>
                    <EuiText >{this.props.t("configuration-panel:description")} </EuiText>
                </EuiFlyoutHeader>
                <EuiFlyoutBody>
                    <EuiForm>
                        <EuiFormRow fullWidth >
                            <EuiTitle size="m">
                                <h2>{this.props.t("configuration-panel:orders-settings")}</h2>
                            </EuiTitle>
                        </EuiFormRow>
                        <EuiDescribedFormGroup fullWidth
                            title={<h3>{this.props.t("configuration-panel:purchase-date")}</h3>}
                            description={
                                <EuiText>{this.props.t("configuration-panel:purchase-date-description")}</EuiText>
                            }>
                            <EuiFormRow fullWidth>
                                <EuiDatePickerRange
                                    startDateControl={<EuiDatePicker onClear={() => this.handleDateFilterChange("startDate", undefined)} selected={cohort?.configuration?.startDate === undefined ? undefined : moment(cohort?.configuration?.startDate)} onChange={(date) => this.handleDateFilterChange("startDate", date?.toDate())}></EuiDatePicker>}
                                    endDateControl={<EuiDatePicker onClear={() => this.handleDateFilterChange("endDate", undefined)} selected={cohort?.configuration?.endDate === undefined ? undefined : moment(cohort?.configuration?.endDate)} onChange={(date) => this.handleDateFilterChange("endDate", date?.toDate())}></EuiDatePicker>}
                                />
                            </EuiFormRow>
                        </EuiDescribedFormGroup>
                        <EuiDescribedFormGroup fullWidth
                            title={<h3>{this.props.t("configuration-panel:stores")}</h3>}
                            description={
                                <EuiText>{this.props.t("configuration-panel:stores-description")}</EuiText>
                            }>
                            <EuiFormRow fullWidth>
                                <EuiComboBox
                                    options={storesFilters}
                                    selectedOptions={storesFilters?.filter(x => cohort?.configuration?.stores?.includes(x.value!))}
                                    onChange={(options) => {
                                        this.handleComboBoxFilterChange("stores", options.map(x => x.value!))
                                    }}
                                    isLoading={this.props.orders.filtersLoading}
                                    placeholder={this.props.t("configuration-panel:stores-placeholder")}
                                    renderOption={(option: EuiComboBoxOptionOption<string>, searchvalue, s) => {
                                        return <EuiText size="xs">{option?.label}</EuiText>
                                    }}
                                />
                            </EuiFormRow>
                        </EuiDescribedFormGroup>
                        <EuiDescribedFormGroup fullWidth
                            title={<h3>{this.props.t("configuration-panel:products")}</h3>}
                            description={
                                <EuiText>{this.props.t("configuration-panel:products-description")}</EuiText>
                            }>
                            <EuiFormRow fullWidth>
                                <EuiComboBox<{ id: string, name: string }>
                                    selectedOptions={cohort?.configuration?.products?.map(filter => productsFilters.find(x => x.value!.id == filter)!)}
                                    onChange={(options) => {
                                        this.handleComboBoxFilterChange("products", options.map(x => x.value?.id!))
                                    }}
                                    isLoading={this.props.products.productsFilterLoading}
                                    placeholder={this.props.t("configuration-panel:products-placeholder")}
                                    renderOption={(option: EuiComboBoxOptionOption<{ id: string, name: string }>, searchvalue, s) => {
                                        return <EuiText size="xs">{option.value?.name}</EuiText>
                                    }}
                                    options={productsFilters}
                                />
                            </EuiFormRow>
                        </EuiDescribedFormGroup>
                        <EuiDescribedFormGroup fullWidth
                            title={<h3>{this.props.t("configuration-panel:discounts")}</h3>}
                            description={
                                <EuiText>{this.props.t("configuration-panel:discounts-description")}</EuiText>
                            }>
                            <EuiFormRow fullWidth>
                                <EuiComboBox
                                    placeholder={this.props.t("configuration-panel:discounts-placeholder")}
                                    selectedOptions={discountFilters?.filter(x => cohort?.configuration?.discounts?.includes(x.value))}
                                    onChange={(options) => this.handleComboBoxFilterChange("discounts", options.map(x => x.value!))}
                                    isLoading={this.props.orders.filtersLoading}
                                    options={discountFilters}
                                />
                            </EuiFormRow>
                        </EuiDescribedFormGroup>
                        <EuiHorizontalRule></EuiHorizontalRule>
                        <EuiFormRow fullWidth >
                            <EuiTitle size="m">
                                <h2>{this.props.t("configuration-panel:payment-settings")}</h2>
                            </EuiTitle>
                        </EuiFormRow>
                        <EuiDescribedFormGroup fullWidth
                            title={<h3>{this.props.t("configuration-panel:payment-status")}</h3>}
                            description={
                                <EuiText>{this.props.t("configuration-panel:payment-status-description")}</EuiText>
                            }>
                            <EuiFormRow >
                                <EuiComboBox
                                    placeholder={this.props.t("configuration-panel:payment-status-placeholder")}
                                    selectedOptions={paymentStatusFilters?.filter(x => cohort?.configuration?.paymentsStatus?.includes(x.value))}
                                    onChange={(options) => this.handleComboBoxFilterChange("paymentsStatus", options.map(x => x.value!))}
                                    isLoading={this.props.orders.filtersLoading}
                                    options={paymentStatusFilters}
                                />
                            </EuiFormRow>
                        </EuiDescribedFormGroup>
                        <EuiDescribedFormGroup fullWidth
                            title={<h3>{this.props.t("configuration-panel:gateway")}</h3>}
                            description={
                                <EuiText>{this.props.t("configuration-panel:gateway-description")}</EuiText>
                            }>
                            <EuiFormRow fullWidth>
                                <EuiComboBox
                                    placeholder={this.props.t("configuration-panel:gateway-placeholder")}
                                    selectedOptions={gatewaysFilters?.filter(x => cohort?.configuration?.gateways?.includes(x.value))}
                                    onChange={(options) => this.handleComboBoxFilterChange("gateways", options.map(x => x.value!))}
                                    isLoading={this.props.orders.filtersLoading}
                                    options={gatewaysFilters}
                                />
                            </EuiFormRow>
                        </EuiDescribedFormGroup>
                        <EuiDescribedFormGroup fullWidth
                            title={<h3>{this.props.t("configuration-panel:credit-card")}</h3>}
                            description={
                                <EuiText>{this.props.t("configuration-panel:credit-card-description")}</EuiText>
                            }>
                            <EuiFormRow fullWidth>
                                <EuiComboBox
                                    placeholder={this.props.t("configuration-panel:credit-card-placeholder")}
                                    selectedOptions={creditCardsFilters?.filter(x => cohort?.configuration?.creditCards?.includes(x.value))}
                                    onChange={(options) => this.handleComboBoxFilterChange("creditCards", options.map(x => x.value!))}
                                    isLoading={this.props.orders.filtersLoading}
                                    options={creditCardsFilters}
                                />
                            </EuiFormRow>
                        </EuiDescribedFormGroup>
                        <EuiHorizontalRule></EuiHorizontalRule>
                        <EuiFormRow fullWidth >
                            <EuiTitle size="m" >
                                <h2>{this.props.t("configuration-panel:other-settings")}</h2>
                            </EuiTitle>
                        </EuiFormRow>
                        <EuiFormRow fullWidth >
                            <EuiCallOut title={this.props.t("configuration-panel:locale-warning")} color="warning"></EuiCallOut>
                        </EuiFormRow>
                        <EuiSpacer></EuiSpacer>
                        <EuiDescribedFormGroup fullWidth
                            title={<h3>{this.props.t("configuration-panel:locale")}</h3>}
                            description={
                                <EuiText>{this.props.t("configuration-panel:locale-description")}</EuiText>
                            }>
                            <EuiFormRow fullWidth>
                                <EuiComboBox
                                    placeholder={this.props.t("configuration-panel:locale-placeholder")}
                                    selectedOptions={localeFitlers?.filter(x => cohort?.configuration?.locales?.includes(x.value))}
                                    onChange={(options) => this.handleComboBoxFilterChange("locales", options.map(x => x.value!))}
                                    isLoading={this.props.orders.filtersLoading}
                                    options={localeFitlers}
                                />
                            </EuiFormRow>
                        </EuiDescribedFormGroup>
                    </EuiForm>

                </EuiFlyoutBody>
                <EuiFlyoutFooter>
                    <EuiFlexGroup className="setup-footer" justifyContent="spaceBetween">
                        <EuiFlexItem grow={false}>
                            <EuiButton onClick={() => this.props.toggleDeleteMenu(true)} fill color="danger">Delete</EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButton color="secondary" onClick={() => this.props.updateCohort()} isLoading={this.props.cohort.updateLoading}>Save</EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlyoutFooter>

                {
                    this.props.cohort.deleteMenuOpen && <DeleteModal
                        onClose={() => this.props.toggleDeleteMenu(false)}
                        ressource={{ name: this.props.cohort.cohort?.name!, type: this.props.t("delete:type") }}
                        deleteButton={{ isLoading: this.props.cohort.deleteLoading, onClick: () => { this.props.deleteCohort() } }}
                    ></DeleteModal>
                }
            </EuiFlyout >
        );

    }

    private createComboBoxOptions(filters: string[] | undefined) {
        return filters?.map(x => { return { label: x, value: x } })
    }
    private createStoresOptions(): EuiComboBoxOptionOption<string>[] {
        let storesFilters: EuiComboBoxOptionOption<string>[] = [];
        this.props.orders.filters?.stores.map(store => {
            if (store.name == "online") {
                storesFilters.push({
                    label: this.props.t("configuration-panel:online-store"),
                    value: store.id
                })
            } else {
                storesFilters.push({
                    label: store.name,
                    value: store.id
                })
            }
        })
        return storesFilters;
    }
    private createProductsOptions(): EuiComboBoxOptionOption<{ id: string, name: string }>[] {
        let productFilters: EuiComboBoxOptionOption<{ id: string, name: string }>[] = [];
        this.props.products.productsFilter!.map(product => {
            productFilters.push({
                label: product.name,
                value: {
                    id: product.id,
                    name: product.name
                }
            })
        })
        return productFilters;
    }

    private handleDateFilterChange(key: string, options: Date | undefined) {
        this.props.updateSelectedFilters(key, options);
    }
    private handleComboBoxFilterChange(key: string, options: string[]) {
        this.props.updateSelectedFilters(key, options);
    }
}

const mapStateToProps = (state: SmartbillsRootState): CohortConfigurationStateProps => {
    const { products, orders } = state;
    const { cohort } = state.customers;
    return {
        cohort: cohort,
        products: products,
        orders: orders
    };
};

const mapDispatchToProps = (dispatch: any): CohortConfigurationDispatchProps => {
    return {
        updateCohort: () => {
            dispatch(updateCohort())
        },
        updateSelectedFilters: (key, value) => {
            dispatch(updateSelectedFilters(key, value))
        },
        toggleConfigurationMenu: (isOpen) => {
            dispatch(toggleConfigurationMenu(isOpen))
        },
        toggleDeleteMenu: (isOpen) => {
            dispatch(toggleDeleteMenu(isOpen))
        },
        deleteCohort: () => {
            dispatch(deleteCohort())
        }
    }
}

const CohortConfigurationFlyoutTranslated = withTranslation("cohort")(connect(mapStateToProps, mapDispatchToProps)(CohortConfigurationFlyout));
export { CohortConfigurationFlyoutTranslated as CohortConfigurationFlyout }