import { Store, applyMiddleware, createStore } from 'redux';

import { OAuth2Service } from "../services/authentication/oauth2-service";
import { loadUser } from "redux-oidc";
import logger from 'redux-logger'
import { smartbillsReducer } from "./smartbills.reducer";
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory()
var store: Store;
if (process.env.NODE_ENV === "development") {
    store = createStore(smartbillsReducer(history), applyMiddleware(thunk, routerMiddleware(history), logger))
} else {
    store = createStore(smartbillsReducer(history), applyMiddleware(thunk, routerMiddleware(history)))
}
loadUser(store, OAuth2Service.instance);
export { store };