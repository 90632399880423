import { EuiButton, EuiEmptyPrompt, EuiTitle } from "@elastic/eui";
import React, { Fragment } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DashboardPage } from "../dashboard-page/dashboard-page";
class Error404 extends React.Component<WithTranslation & RouteComponentProps> {
    public render() {
        return (
            <DashboardPage centered>
                <EuiEmptyPrompt
                    iconType="faceSad"
                    iconColor="#ff5757"
                    title={<EuiTitle><h1>{this.props.t("404:title")}</h1></EuiTitle>}
                    body={
                        <Fragment>
                            <p>
                                {this.props.t("404:description")}
                            </p>
                        </Fragment>
                    }

                    actions={<EuiButton onClick={() => this.props.history.push("/")}>{this.props.t("404:action")}
                    </EuiButton>}></EuiEmptyPrompt>
            </DashboardPage>
        );
    }
}
const Error404Translated = withTranslation("errors")(withRouter(Error404))
export { Error404Translated as Error404 }