import { colorPalette, dateFormatAliases, EuiAccordion, EuiFlexGroup, EuiFlexItem, EuiLoadingChart, euiPaletteCool, euiPaletteWarm, EuiPanel, EuiSpacer, EuiTitle, formatDate } from "@elastic/eui";
import { Axis, LineSeries, Chart, Position, Settings, timeFormatter, niceTimeFormatByDay, Datum, AreaSeries, BarSeries, Partition, PartitionLayout } from '@elastic/charts';

import React, { Fragment, ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CohortStateProps } from "../../../../../redux/customers/cohort/cohort.state";
import { OrdersStateProps } from "../../../../../redux/orders/orders.state";
import { ProductsStateProps } from "../../../../../redux/products/products.state";
import { SmartbillsRootState } from "../../../../../redux/smartbills.reducer";
import '@elastic/charts/dist/theme_only_light.css';
import { LoadableComponent } from "../../../../../components/loadable-component/loadable-component";

class CohortProductsPanel extends React.Component<WithTranslation & { cohort: CohortStateProps, products: ProductsStateProps, orders: OrdersStateProps }> {
    public render(): ReactNode {
        var colors = colorPalette(["#006BB4"], 20)
        return (
            <Fragment>
                <EuiSpacer></EuiSpacer>
                <EuiAccordion initialIsOpen id="graph" buttonContent={this.props.t("products-panel:popular-by-revenues")}>
                    <EuiFlexGroup style={{ minHeight: 400 }}>
                        <EuiFlexItem grow={3}>
                            <Chart className="story-chart" size={undefined}>
                                <Settings
                                    rotation={90}
                                    legendPosition="right"
                                />
                                <BarSeries
                                    id={this.props.t("products-panel:revenues")}
                                    xScaleType="linear"
                                    data={this.props.cohort.stats!.bestPerformingProductsByRevenues!}
                                    xAccessor="name"
                                    yAccessors={['revenues']}
                                    color="#006BB4"
                                />
                                <Axis
                                    id="bottom-axis"
                                    position={"bottom"}
                                    showGridLines={false}
                                    title={this.props.t("products-panel:revenues")}
                                    tickFormat={(value: number) => { return value.toFixed(2) + " $" }}
                                />
                                <Axis
                                    id="left-axis"
                                    position={"left"}
                                    title={this.props.t("products-panel:product")}
                                />

                            </Chart>
                        </EuiFlexItem>
                        <EuiFlexItem grow={1}>
                            <Chart>
                                <Partition
                                    id="product-revenues-breakdown"
                                    data={this.props.cohort.stats!.bestPerformingProductsByRevenues!.filter(x => x.name != null)}
                                    valueAccessor={(d) => d.revenues}
                                    valueFormatter={(value: number) => { return value.toFixed(2) + " $" }}
                                    layers={[
                                        {
                                            groupByRollup: (d: Datum) => d.name,
                                            shape: {
                                                fillColor: (s) => colors[colors.length - 1 - s.sortIndex]
                                            },
                                        },
                                    ]}
                                    config={{
                                        partitionLayout: PartitionLayout.sunburst,
                                        linkLabel: { maximumSection: Infinity, maxCount: 0 },
                                    }}
                                />
                            </Chart>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiAccordion>
                <EuiAccordion initialIsOpen id="graph" buttonContent={this.props.t("products-panel:popular-by-quantity")}>
                    <EuiFlexGroup style={{ minHeight: 400 }}>
                        <EuiFlexItem grow={3}>
                            <Chart className="product-quantity-graph" size={undefined}>
                                <Settings
                                    rotation={90}
                                    legendPosition="right"
                                    onElementClick={() => { }}
                                />
                                <BarSeries
                                    id={this.props.t("products-panel:quantity")}
                                    xScaleType="linear"
                                    data={this.props.cohort.stats!.bestPerformingProductsByQuantity!}
                                    xAccessor="name"
                                    yAccessors={['quantity']}
                                    color="#006BB4"
                                />
                                <Axis
                                    id="bottom-axis"
                                    position={"bottom"}
                                    showGridLines={false}
                                    title={this.props.t("products-panel:quantity")}
                                />
                                <Axis
                                    id="left-axis"
                                    position={"left"}
                                    title={this.props.t("products-panel:product")}
                                />

                            </Chart>
                        </EuiFlexItem>
                        <EuiFlexItem grow={1}>
                            <Chart>
                                <Partition
                                    id="product-quantity-breakdown"
                                    data={this.props.cohort.stats!.bestPerformingProductsByQuantity!.filter(x => x.name != null)}
                                    valueAccessor={(d) => d.quantity}
                                    layers={[
                                        {
                                            groupByRollup: (d: Datum) => d.name,
                                            shape: {
                                                fillColor: (s) => colors[colors.length - 1 - s.sortIndex]
                                            },
                                        },
                                    ]}
                                    config={{
                                        partitionLayout: PartitionLayout.sunburst,
                                        linkLabel: { maximumSection: Infinity, maxCount: 0 },
                                    }}
                                />
                            </Chart>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiAccordion>
            </Fragment>
        )
    }

    private loadableChart() {
        if (this.props.cohort.statsLoading) {
            return <EuiLoadingChart></EuiLoadingChart>
        }
    }
}

const mapStateToProps = (state: SmartbillsRootState): { cohort: CohortStateProps, products: ProductsStateProps, orders: OrdersStateProps } => {
    const { products, orders } = state;
    const { cohort } = state.customers;
    return {
        cohort: cohort,
        products: products,
        orders: orders
    };
};

const mapDispatchToProps = (dispatch: any): any => {
    return {

    }
}

const CohortProductsPanelTranslated = withTranslation("cohort")(connect(mapStateToProps)(CohortProductsPanel));
export { CohortProductsPanelTranslated as CohortProductsPanel }

/*
 <EuiFlexItem>

                        <LoadableComponent isLoading={this.props.cohort.statsLoading} loadingIcon={<EuiLoadingChart></EuiLoadingChart>} >
                            <Chart size={{ height: 500 }}>
                                <Settings
                                    rotation={90}
                                    showLegend={true}
                                    legendPosition="right"
                                />
                                <BarSeries
                                    id="issues"
                                    name="Issues"
                                    xScaleType="linear"
                                    data={this.props.cohort.stats!.bestPerformingProducts!}
                                    xAccessor="name"
                                    yAccessors={['quantity']}
                                />
                                <Axis
                                    id="bottom-axis"
                                    position={"bottom"}
                                    showGridLines={false}
                                />
                                <Axis
                                    id="left-axis"
                                    position={"left"}

                                />
                            </Chart>
                        </LoadableComponent>
                    </EuiFlexItem>
*/