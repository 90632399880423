import { AxiosError, AxiosResponse } from "axios";

import { RetailAPI } from "../retail-api";
import { SBCompany } from "@smartbills/smartbills-js";
import { CompanyOwned } from "../../models/company/company-owned";

export class CompanyService {
    public static getCompany(id: string): Promise<SBCompany> {
        return new Promise<SBCompany>((resolve, reject) => {
            RetailAPI.getInstance().get(`companies/${id}`).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }
    public static getCompanies(): Promise<SBCompany[]> {
        return new Promise<SBCompany[]>((resolve, reject) => {
            RetailAPI.getInstance().get("companies").then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }

    public static setDefaultCompany(companyId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            RetailAPI.getInstance().put("companies/default", { id: companyId }).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error);
            }
            );
        });
    }
    public static getDefaultCompany(): Promise<CompanyOwned> {
        return new Promise<CompanyOwned>((resolve, reject) => {
            RetailAPI.getInstance().get("companies/default").then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error);
            }
            );
        });
    }
}