import { EuiPanel, EuiTitle, EuiStat, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiFlexGrid, EuiHorizontalRule, EuiIcon } from "@elastic/eui";
import React, { Fragment, ReactNode } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getCohortOverview } from "../../../../../redux/customers/cohort/cohort.actions";
import { CohortStateProps } from "../../../../../redux/customers/cohort/cohort.state";
import { OrdersStateProps } from "../../../../../redux/orders/orders.state";
import { ProductsStateProps } from "../../../../../redux/products/products.state";
import { SmartbillsRootState } from "../../../../../redux/smartbills.reducer";
import { CurrencyHelper } from "../../../../../utils/currency/currency-helper";
import { CohortOverviewDispatchProps } from "./cohort-overview.props";

class CohortOverviewPanel extends React.Component<WithTranslation & { cohort: CohortStateProps, products: ProductsStateProps, orders: OrdersStateProps } & CohortOverviewDispatchProps> {

    public render(): ReactNode {
        return (
            <Fragment>
                <EuiSpacer></EuiSpacer>
                <EuiTitle><h2><b>{this.props.t("overview:customers")}</b></h2></EuiTitle>
                <EuiFlexGrid responsive={false} columns={3}>
                    <EuiFlexItem>
                        <EuiStat isLoading={this.props.cohort.overviewLoading} title={this.props.cohort.overview?.users.length} description={this.props.t("overview:number-customers")}></EuiStat>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiStat isLoading={this.props.cohort.overviewLoading} title={this.props.cohort.overview?.uniqueCustomers} description={this.props.t("overview:unique-customers")}></EuiStat>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiStat isLoading={this.props.cohort.overviewLoading} title={this.props.cohort.overview?.recurrentCustomers} description={this.props.t("overview:recurrent-customers")}></EuiStat>
                    </EuiFlexItem>
                </EuiFlexGrid>
                <EuiHorizontalRule></EuiHorizontalRule>
                <EuiTitle><h2><b>{this.props.t("overview:revenues")}</b></h2></EuiTitle>
                <EuiFlexGrid responsive={false} columns={3}>
                    <EuiFlexItem>
                        <EuiStat isLoading={this.props.cohort.overviewLoading} title={CurrencyHelper.formatNumber(this.props.cohort.overview?.revenues)} description={this.props.t("overview:revenues")}></EuiStat>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiStat isLoading={this.props.cohort.overviewLoading} title={CurrencyHelper.formatNumber(this.props.cohort.overview?.averageRevenues)} description={this.props.t("overview:average-revenue-day")}></EuiStat>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiStat isLoading={this.props.cohort.overviewLoading} title={(this.props.cohort.overview?.totalRevenuesShare! * 100).toFixed(2) + "%"} description={this.props.t("overview:total-revenues-share")}></EuiStat>
                    </EuiFlexItem>
                </EuiFlexGrid>
                <EuiHorizontalRule></EuiHorizontalRule>

                <EuiTitle><h2><b>{this.props.t("overview:orders")}</b></h2></EuiTitle>
                <EuiFlexGrid responsive={false} columns={3}>
                    <EuiFlexItem>
                        <EuiStat isLoading={this.props.cohort.overviewLoading} title={this.props.cohort.overview?.receiptCount} description={this.props.t("overview:orders-count")}></EuiStat>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiStat isLoading={this.props.cohort.overviewLoading} title={CurrencyHelper.formatNumber(this.props.cohort.overview?.revenues! / this.props.cohort.overview?.receiptCount!)} description={this.props.t("overview:average-revenue-order")}></EuiStat>
                    </EuiFlexItem>
                </EuiFlexGrid>
            </Fragment >
        );
    }
}
const mapStateToProps = (state: SmartbillsRootState): { cohort: CohortStateProps, products: ProductsStateProps, orders: OrdersStateProps } => {
    const { products, orders } = state;
    const { cohort } = state.customers;
    return {
        cohort: cohort,
        products: products,
        orders: orders
    };
};

const mapDispatchToProps = (dispatch: any): CohortOverviewDispatchProps => {
    return {
        getCohortOverview: () => {
            dispatch(getCohortOverview())
        }
    }
}

const CohortOverviewPanelTranslated = withTranslation("cohort")(connect(mapStateToProps, mapDispatchToProps)(CohortOverviewPanel));
export { CohortOverviewPanelTranslated as CohortOverviewPanel }