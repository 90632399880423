import { CohortValdiationEnum } from "../../../models/customers/cohorts/cohort-validation";
import { CohortsAction } from "./cohorts.actions";
import { CohortsStateProps } from "./cohorts.state";
import { CohortsActionTypes } from "./cohorts.types";

const initialState: CohortsStateProps = {
    cohorts: [],
    cohortForm: { name: "", description: "" },
    deleteMenuOpen: false,
    createMenuOpen: false,
    cohortMenuOpen: undefined,
    nameValidation: CohortValdiationEnum.UNVALIDATED,
    descriptionValidation: CohortValdiationEnum.VALID,
    isFormValid: false,
};
export function CohortsReducer(
    state: CohortsStateProps = initialState,
    action: CohortsAction
): CohortsStateProps {
    switch (action.type) {
        case CohortsActionTypes.GET_COHORTS_STARTED:
            return {
                ...state,
                loading: action.payload.loading,
                error: action.payload.error,
            };
        case CohortsActionTypes.GET_COHORTS_COMPLETED:
            return {
                ...state,
                cohorts: action.payload.cohorts,
                loading: action.payload.loading,
                error: action.payload.error,
            };
        case CohortsActionTypes.GET_COHORTS_FAILED:
            return {
                ...state,
                cohorts: action.payload.cohorts,
                loading: action.payload.loading,
                error: action.payload.error,
            };
        case CohortsActionTypes.CREATE_COHORT_STARTED:
            return {
                ...state,
                createdCohort: action.payload.createdCohort,
                createLoading: action.payload.createLoading,
                createError: action.payload.createError,
            };
        case CohortsActionTypes.CREATE_COHORT_COMPLETED:
            return {
                ...state,
                cohorts: state.cohorts?.concat(action.payload.createdCohort!),
                createdCohort: action.payload.createdCohort,
                createLoading: action.payload.createLoading,
                createError: action.payload.createError,
            };
        case CohortsActionTypes.CREATE_COHORT_FAILED:
            return {
                ...state,
                createdCohort: action.payload.createdCohort,
                createLoading: action.payload.createLoading,
                createError: action.payload.createError,
            };
        case CohortsActionTypes.UPDATE_COHORT_NAME:
            return {
                ...state,
                cohortForm: {
                    ...state.cohortForm,
                    name: action.payload.cohortForm?.name
                }
            };
        case CohortsActionTypes.UPDATE_COHORT_DESCRIPTION:
            return {
                ...state,
                cohortForm: {
                    ...state.cohortForm,
                    description: action.payload.cohortForm?.description
                }
            };
        case CohortsActionTypes.TOGGLE_COHORT_MENU:
            return {
                ...state,
                cohortMenuOpen: action.payload.cohortMenuOpen
            };
        case CohortsActionTypes.TOGGLE_CREATE_MENU:
            return {
                ...state,
                createMenuOpen: action.payload.createMenuOpen
            };
        case CohortsActionTypes.TOGGLE_DELETE_MENU:
            return {
                ...state,
                deleteMenuOpen: action.payload.deleteMenuOpen
            };
        case CohortsActionTypes.TOGGLE_UPDATE_MENU:
            return {
                ...state,
                updateMenuOpen: action.payload.updateMenuOpen
            };
        case CohortsActionTypes.SET_ACTIVE_COHORT:
            return {
                ...state,
                activeCohort: action.payload.activeCohort
            };
        case CohortsActionTypes.DELETE_COHORT_STARTED:
            return {
                ...state,
                deleted: action.payload.deleted,
                deleteLoading: action.payload.deleteLoading,
                deleteError: action.payload.deleteError,
            };
        case CohortsActionTypes.DELETE_COHORT_COMPLETED:
            return {
                ...state,
                cohorts: state.cohorts!.filter(cohort => cohort.id !== action.payload.deleted?.id),
                deleted: action.payload.deleted,
                deleteLoading: action.payload.deleteLoading,
                deleteError: action.payload.deleteError,
            };
        case CohortsActionTypes.DELETE_COHORT_FAILED:
            return {
                ...state,
                deleted: action.payload.deleted,
                deleteLoading: action.payload.deleteLoading,
                deleteError: action.payload.deleteError,
            };
        case CohortsActionTypes.RESET_FORM:
            return {
                ...state,
                cohortForm: action.payload.cohortForm,
                nameValidation: action.payload.nameValidation,
                descriptionValidation: action.payload.descriptionValidation,
                isFormValid: action.payload.isFormValid
            };
        case CohortsActionTypes.VALIDATE_NAME:
            return {
                ...state,
                nameValidation: action.payload.nameValidation
            };
        case CohortsActionTypes.VALIDATE_DESCRIPTION:
            return {
                ...state,
                descriptionValidation: action.payload.descriptionValidation
            };
        case CohortsActionTypes.VALIDATE_FORM:
            return {
                ...state,
                isFormValid: action.payload.isFormValid
            };
        case CohortsActionTypes.UPDATE_COHORTS_LIST_ITEM_STARTED:
            return {
                ...state,
                updateLoading: action.payload.updateLoading,
                updateError: action.payload.updateError,
            };
        case CohortsActionTypes.UPDATE_COHORTS_LIST_ITEM_COMPLETED:
            var cohorts = state.cohorts!.map((item, index) => {
                if (item.id !== action.payload.createdCohort!.id) {
                    return item
                }
                return {
                    ...item,
                    ...action.payload.createdCohort
                }
            })
            return {
                ...state,
                cohorts: cohorts,
                updateLoading: action.payload.updateLoading,
                updateError: action.payload.updateError,
            };
        case CohortsActionTypes.UPDATE_COHORTS_LIST_ITEM_FAILED:
            return {
                ...state,
                updateLoading: action.payload.updateLoading,
                updateError: action.payload.updateError,
            };
        default:
            return { ...state };
    }
}