import { CohortAction } from "./cohort.actions";
import { CohortStateProps } from "./cohort.state";
import { CohortActionTypes } from "./cohort.types";

const initialState: CohortStateProps = {
    loading: false,
    clientsCurrentPage: 0,
    clientsRowsPerPage: 25,
    overview: {
        users: [],
        revenues: 0,
        receiptCount: 0,
        averageRevenues: 0,
        totalRevenuesShare: 0,
        totalDiscount: 0,
        uniqueCustomers: 0,
        recurrentCustomers: 0,
        clientsLocations: [],
    },
    stats: { revenuesOverTime: [], bestPerformingProductsByRevenues: [], bestPerformingProductsByQuantity: [] }
};
export function CohortReducer(
    state: CohortStateProps = initialState,
    action: CohortAction
): CohortStateProps {
    switch (action.type) {
        case CohortActionTypes.GET_COHORT_STARTED:
            return {
                ...state,
                loading: action.payload.loading,
                error: action.payload.error,
            };
        case CohortActionTypes.GET_COHORT_COMPLETED:
            return {
                ...state,
                clientsCurrentPage: 0,
                clientsRowsPerPage: 25,
                cohort: action.payload.cohort,
                loading: action.payload.loading,
                error: action.payload.error,
            };
        case CohortActionTypes.GET_COHORT_FAILED:
            return {
                ...state,
                cohort: action.payload.cohort,
                loading: action.payload.loading,
                error: action.payload.error,
            };
        case CohortActionTypes.SET_ACTIVE_COHORT:
            return {
                ...state,
                activeCohort: action.payload.activeCohort
            };
        case CohortActionTypes.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload.activeTab
            };
        case CohortActionTypes.UPDATE_SELECTED_FITLERS:
            return {
                ...state,
                cohort: {
                    ...state.cohort,
                    configuration: {
                        ...state.cohort?.configuration,
                        [action.payload.key!]: action.payload.value
                    }
                }
            }
        case CohortActionTypes.GET_COHORT_OVERVIEW_STARTED:
            return {
                ...state,
                overview: action.payload.overview,
                overviewLoading: action.payload.overviewLoading,
                overviewError: action.payload.overviewError,
            }
        case CohortActionTypes.GET_COHORT_OVERVIEW_COMPLETED:
            return {
                ...state,
                overview: action.payload.overview,
                overviewLoading: action.payload.overviewLoading,
                overviewError: action.payload.overviewError,
            }
        case CohortActionTypes.GET_COHORT_OVERVIEW_FAILED:
            return {
                ...state,
                overviewLoading: action.payload.overviewLoading,
                overviewError: action.payload.overviewError,
            }
        case CohortActionTypes.UPDATE_COHORT_STARTED:
            return {
                ...state,
                updateLoading: action.payload.updateLoading,
                updateError: action.payload.updateError,
            };
        case CohortActionTypes.UPDATE_COHORT_COMPLETED:
            return {
                ...state,
                cohort: action.payload.cohort,
                updateLoading: action.payload.updateLoading,
                updateError: action.payload.updateError,
            };
        case CohortActionTypes.UPDATE_COHORT_FAILED:
            return {
                ...state,
                cohort: action.payload.cohort,
                updateLoading: action.payload.updateLoading,
                updateError: action.payload.updateError,
            };

        case CohortActionTypes.GET_REVENUES_OVER_TIME_STARTED:
            return {
                ...state,
                statsLoading: action.payload.statsLoading,
                error: action.payload.statsError
            };
        case CohortActionTypes.GET_REVENUES_OVER_TIME_COMPLETED:
            return {
                ...state,
                stats: action.payload.stats,
                statsLoading: action.payload.statsLoading,
                error: action.payload.statsError

            };
        case CohortActionTypes.GET_REVENUES_OVER_TIME_FAILED:
            return {
                ...state,
                statsLoading: action.payload.statsLoading,
                error: action.payload.statsError
            };
        case CohortActionTypes.TOGGLE_CONFIGURATION_MENU:
            return {
                ...state,
                configurationMenuOpen: action.payload.configurationMenuOpen,
            };
        case CohortActionTypes.TOGGLE_COHORT_DELETE_MENU:
            return {
                ...state,
                deleteMenuOpen: action.payload.deleteMenuOpen,
            };

        case CohortActionTypes.CONFIGURATION_DELETE_COHORT_STARTED:
            return {
                ...state,
                deleteLoading: action.payload.deleteLoading,
                deleteError: action.payload.deleteError,
            }
        case CohortActionTypes.CONFIGURATION_DELETE_COHORT_COMPLETED:
            return {
                ...state,
                deleteLoading: action.payload.deleteLoading,
                deleteError: action.payload.deleteError,
            }
        case CohortActionTypes.CONFIGURATION_DELETE_COHORT_FAILED:
            return {
                ...state,
                deleteLoading: action.payload.deleteLoading,
                deleteError: action.payload.deleteError,
            }
        case CohortActionTypes.CHANGE_CURRENT_PAGE:
            return {
                ...state,
                clientsCurrentPage: action.payload.clientsCurrentPage,
            }
        case CohortActionTypes.CHANGE_ROWS_PER_PAGE:
            return {
                ...state,
                clientsRowsPerPage: action.payload.clientsRowsPerPage,
            }
        case CohortActionTypes.RESET_COHORT_STATE:
            return {
                ...initialState,
            }
        default:
            return { ...state };
    }
}