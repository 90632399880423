import { Button, Result, Row } from "antd";
import { WithTranslation, withTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import React from "react";

class UpdateSuccessScreen extends React.Component<WithTranslation> {
    public render() {
        return (
            <Row justify="center" align="middle" style={{ height: "100vh" }}>
                <Result status="success"
                    title={this.props.t("update:title")}
                    extra={
                        <Link to="/">
                            <Button onClick={() => {
                            }} shape="round" danger>
                                {this.props.t("update:returnToApp")}
                            </Button>
                        </Link>
                    }>
                </Result>
            </Row>
        )
    }
}
const UpdateSuccessTranslated = withTranslation()(UpdateSuccessScreen);
export { UpdateSuccessTranslated as UpdateSuccessScreen }