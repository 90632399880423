
import { AxiosError } from "axios";
import { LatLngBounds } from "leaflet";
import { Moment } from "moment";
import { MapService } from "../../../services/insight/map/map.service";
import { AmountFilters } from "../../../view-models/insights/map/amount-filters";
import { BasicMapFilters } from "../../../view-models/insights/map/basic-map-filters";
import { BasicMapResult } from "../../../view-models/insights/map/basic-map-result";
import { LocalizedCustomer } from "../../../view-models/insights/map/localized-customer";
import { ProductFilter } from "../../../view-models/insights/map/product-filter";
import { store } from "../../store";
import { MapInsightsPayload } from "./map-insights.state";
import { MapInsightsActionTypes } from "./map-insights.types";
import { Dispatch } from "redux"
export interface MapInsightsAction {
    type: MapInsightsActionTypes;
    payload: MapInsightsPayload;
}

export const getProductsFilter = () => {
    return (dispatch: Dispatch) => {
        dispatch(getProductsFilterStarted());
        MapService.getProductsFilter().then((result) => {
            dispatch(getProductsFilterCompleted(result))
        }).catch((error: AxiosError) => {
            dispatch(getProductsFilterFailed(error))
        })
    };
}

export const getProductsFilterStarted = (): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.GET_PRODUCT_FILTERS_STARTED,
        payload: {
            productFiltersLoading: true,
        }
    };
};
export function getProductsFilterCompleted(productFilters: ProductFilter[]): MapInsightsAction {
    return {
        type: MapInsightsActionTypes.GET_PRODUCT_FILTERS_COMPLETED,
        payload: {
            productFiltersLoading: false,
            productFilters: productFilters,
        }
    };
}

export const getProductsFilterFailed = (error: AxiosError): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.GET_PRODUCT_FILTERS_FAILED,
        payload: {
            productFiltersLoading: false,
        }
    };
};

export const getAmountFilters = () => {
    return (dispatch: Dispatch) => {
        dispatch(getAmountFiltersStarted());
        MapService.getAmountFilters().then((result) => {
            dispatch(getAmountFiltersCompleted(result))
        }).catch((error: AxiosError) => {
            dispatch(getAmountFiltersFailed(error))
        })
    };
}

export const getAmountFiltersStarted = (): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.GET_AMOUNT_FILTER_STARTED,
        payload: {
            amountFiltersLoading: true,
        }
    };
};
export function getAmountFiltersCompleted(amountFilters: AmountFilters): MapInsightsAction {
    return {
        type: MapInsightsActionTypes.GET_AMOUNT_FILTER_COMPLETED,
        payload: {
            amountFiltersLoading: false,
            amountFilters: amountFilters,
            mapFilters: { amountStart: amountFilters.min, amountEnd: amountFilters.max }
        }
    };
}

export const getAmountFiltersFailed = (error: AxiosError): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.GET_AMOUNT_FILTER_FAILED,
        payload: {
            amountFiltersLoading: false,
            amountFiltersError: error.response
        }
    };
};

export const getMapResult = () => {
    const mapFilter = store.getState().mapInsights.mapFilters!;
    return (dispatch: Dispatch) => {
        dispatch(getMapResultStarted());
        MapService.getMapResults(mapFilter).then((result) => {
            dispatch(getMapResultCompleted(result))
        }).catch((error: AxiosError) => {
            dispatch(getMapResultFailed(error))
        })
    };
}

export const getMapResultStarted = (): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.GET_MAP_RESULT_STARTED,
        payload: {
            mapResultsLoading: true,
        }
    };
};
export function getMapResultCompleted(mapResult: LocalizedCustomer[]): MapInsightsAction {
    return {
        type: MapInsightsActionTypes.GET_MAP_RESULT_COMPLETED,
        payload: {
            mapResultsLoading: false,
            mapResult: mapResult,
        }
    };
}

export const getMapResultFailed = (error: AxiosError): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.GET_MAP_RESULT_FAILED,
        payload: {
            mapResultsLoading: false,
            mapResultError: error.response
        }
    };
};

export const updateDateFilter = (startDate?: Moment | null, endDate?: Moment | null): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.UPDATE_MAP_DATE_FILTERS,
        payload: {
            mapFilters: { startDate: startDate, endDate: endDate },
        }
    };
};
export const updateInStoreCheckbox = (checked: boolean): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.UPDATE_INSTORE_CHECKBOX,
        payload: {
            mapFilters: { inStore: checked },
        }
    };
};
export const updateOnlineCheckbox = (checked: boolean): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.UPDATE_ONLINE_CHECKBOX,
        payload: {
            mapFilters: { online: checked },
        }
    };
};
export const updateFirstTimeCustomerFilter = (firstTime: boolean): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.UPDATE_MAP_FIRST_TIME_CUSTOMER_FILTER,
        payload: {
            mapFilters: { firstTime: firstTime },
        }
    };
};
export const updateRecurrentCustomerFilter = (recurrent: boolean): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.UPDATE_MAP_RECURRENT_CUSTOMER_FILTER,
        payload: {
            mapFilters: { recurrent: recurrent },
        }
    };
};
export const updateProductFilterSelection = (selected: string[]): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.UPDATE_MAP_PRODUCTS_FILTER,
        payload: {
            mapFilters: { products: selected },
        }
    };
};
export const updateMapExcludeFilter = (exclude: boolean): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.UPDATE_MAP_EXCLUDE_FILTER,
        payload: {
            mapFilters: { exclude: exclude },
        }
    };
};
export const updateAmountFilters = (amountFilters: AmountFilters): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.UPDATE_MAP_AMOUNT_FILTERS,
        payload: {
            mapFilters: { amountStart: amountFilters.min, amountEnd: amountFilters.max },
        }
    };
};

export const updateBoundsFilters = (bounds: LatLngBounds): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.UPDATE_MAP_BOUNDS_FILTERS,
        payload: {
            mapFilters: {
                bounds
            },
        }
    };
};

export const getBasicMap = () => {
    const mapFilter = store.getState().mapInsights.mapFilters! as BasicMapFilters;
    var basicMapFilter: BasicMapFilters = {
        startDate: mapFilter.startDate,
        endDate: mapFilter.endDate,
        online: mapFilter.online,
        inStore: mapFilter.inStore,
        recurrent: mapFilter.recurrent,
        firstTime: mapFilter.firstTime
    }
    return (dispatch: Dispatch) => {
        dispatch(getBasicMapStarted());
        MapService.getBasicMap(basicMapFilter).then((result) => {
            dispatch(getBasicMapCompleted(result))
        }).catch((error: AxiosError) => {
            dispatch(getBasicMapFailed(error))
        })
    };
}

export const getBasicMapStarted = (): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.GET_MAP_RESULT_STARTED,
        payload: {
            mapResultsLoading: true,
        }
    };
};
export function getBasicMapCompleted(mapResult: BasicMapResult): MapInsightsAction {
    return {
        type: MapInsightsActionTypes.GET_MAP_RESULT_COMPLETED,
        payload: {
            mapResultsLoading: false,
            basicMapResult: mapResult,
        }
    };
}

export const getBasicMapFailed = (error: AxiosError): MapInsightsAction => {
    return {
        type: MapInsightsActionTypes.GET_MAP_RESULT_FAILED,
        payload: {
            mapResultsLoading: false,
            mapResultError: error.response
        }
    };
};
