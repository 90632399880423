import { AxiosError, AxiosResponse } from "axios";

import { RetailAPI } from "../../retail-api";
import { SBCohort } from "../../../models/customers/SBCohorts";
import { SBCohortViewModel } from "../../../view-models/customers/cohorts/SBCohortViewModel";
import { SBCohortOverviewViewModel } from "../../../view-models/customers/cohorts/cohort-overview";
import { SBCohortOrderCountGraphPoint } from "../../../view-models/customers/cohorts/stats/order-count-point";
import { SBCohortStats } from "../../../view-models/customers/cohorts/stats/cohort-stats";

export class CohortsService {
    public static getCohorts(): Promise<SBCohort[]> {
        return new Promise<SBCohort[]>((resolve, reject) => {
            RetailAPI.getInstance().get(`cohorts`).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }
    public static deleteCohort(cohortId: number): Promise<{}> {
        return new Promise<{}>((resolve, reject) => {
            RetailAPI.getInstance().delete(`cohorts/${cohortId}`).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }
    public static createCohort(cohort: SBCohortViewModel): Promise<SBCohort> {
        return new Promise<SBCohort>((resolve, reject) => {
            RetailAPI.getInstance().post(`cohorts`, cohort).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }

    public static getCohort(id: number): Promise<SBCohort> {
        return new Promise<SBCohort>((resolve, reject) => {
            RetailAPI.getInstance().get(`cohorts/${id}`).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }
    public static getCohortOverview(id: number): Promise<SBCohortOverviewViewModel> {
        return new Promise<SBCohortOverviewViewModel>((resolve, reject) => {
            RetailAPI.getInstance().get(`cohorts/${id}/overview`).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }
    public static updateCohort(id: number, cohort: SBCohort): Promise<SBCohort> {
        return new Promise<SBCohort>((resolve, reject) => {
            RetailAPI.getInstance().put(`cohorts/${id}`, cohort).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }
    public static getRevenuesOverTime(id: number): Promise<SBCohortStats> {
        return new Promise<SBCohortStats>((resolve, reject) => {
            RetailAPI.getInstance().get(`cohorts/${id}/stats`).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }
}
