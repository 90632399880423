import './index.scss';

import * as serviceWorker from './serviceWorker';

import { App } from './App';
import { OAuth2Service } from './services/authentication/oauth2-service';
import { OidcProvider } from 'redux-oidc';
import { Provider } from 'react-redux'
import React from 'react';
import ReactDOM from 'react-dom';
import { store, history } from './redux/store';
import { ConnectedRouter } from 'connected-react-router';


ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <OidcProvider store={store} userManager={OAuth2Service.instance}>
        < App />
      </OidcProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
// React.StrictMode

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
