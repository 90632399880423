import { LatLngBounds, latLngBounds } from "leaflet";


import _ from "lodash";
import { message } from "antd";
import moment from "moment";
import { LocalizedCustomer } from "../../../view-models/insights/map/localized-customer";
import { MapInsightsAction } from "./map-insights.actions";
import { MapInsightsStateProps } from "./map-insights.state";
import { MapInsightsActionTypes } from "./map-insights.types";

const initialState: MapInsightsStateProps = {
    productFiltersLoading: false,

    productFilters: [],
    amountFilters: { min: 0, max: 100 },
    mapFilters: {
        bounds: latLngBounds([[-90, -180], [90, 180]]), firstTime: true, recurrent: true, startDate: moment().subtract(1, "month"), endDate: moment(),
        online: true,
        inStore: true,
    }
};
export function MapInsightsReducer(
    state: MapInsightsStateProps = initialState,
    action: MapInsightsAction
): MapInsightsStateProps {
    switch (action.type) {
        case MapInsightsActionTypes.GET_PRODUCT_FILTERS_STARTED:
            return {
                ...state,
                productFiltersLoading: action.payload.productFiltersLoading
            };
        case MapInsightsActionTypes.GET_PRODUCT_FILTERS_COMPLETED:
            return {
                ...state,
                productFiltersLoading: action.payload.productFiltersLoading,
                productFilters: action.payload.productFilters,
            };
        case MapInsightsActionTypes.GET_PRODUCT_FILTERS_FAILED:
            return {
                ...state,
                productFiltersLoading: action.payload.productFiltersLoading
            };
        case MapInsightsActionTypes.GET_AMOUNT_FILTER_STARTED:
            return {
                ...state,
                amountFiltersLoading: action.payload.amountFiltersLoading
            };
        case MapInsightsActionTypes.GET_AMOUNT_FILTER_COMPLETED:
            return {
                ...state,
                amountFilters: action.payload.amountFilters,
                amountFiltersLoading: action.payload.amountFiltersLoading,
                mapFilters: {
                    ...state.mapFilters,
                    amountStart: action.payload.mapFilters?.amountStart,
                    amountEnd: action.payload.mapFilters?.amountEnd,
                }
            };
        case MapInsightsActionTypes.GET_AMOUNT_FILTER_FAILED:
            return {
                ...state,
                amountFiltersLoading: action.payload.amountFiltersLoading
            };
        case MapInsightsActionTypes.GET_MAP_RESULT_STARTED:
            return {
                ...state,
                mapResultsLoading: action.payload.mapResultsLoading,
            };
        case MapInsightsActionTypes.GET_MAP_RESULT_COMPLETED:
            return {
                ...state,
                mapResultsLoading: action.payload.mapResultsLoading,
                basicMapResult: action.payload.basicMapResult,
            };
        case MapInsightsActionTypes.GET_MAP_RESULT_FAILED:
            if (!action.payload.mapResultError?.data) {
                message.error("Network Error")
            }
            return {
                ...state,
                mapResultsLoading: action.payload.mapResultsLoading,
                mapResultError: action.payload.mapResultError,
            };
        case MapInsightsActionTypes.UPDATE_MAP_DATE_FILTERS:
            return {
                ...state,
                mapFilters: {
                    ...state.mapFilters,
                    startDate: action.payload.mapFilters?.startDate,
                    endDate: action.payload.mapFilters?.endDate
                }
            };
        case MapInsightsActionTypes.UPDATE_MAP_FIRST_TIME_CUSTOMER_FILTER:
            return {
                ...state,
                mapFilters: {
                    ...state.mapFilters,
                    firstTime: action.payload.mapFilters?.firstTime
                }
            };
        case MapInsightsActionTypes.UPDATE_MAP_RECURRENT_CUSTOMER_FILTER:
            return {
                ...state,
                mapFilters: {
                    ...state.mapFilters,
                    recurrent: action.payload.mapFilters?.recurrent
                }
            };
        case MapInsightsActionTypes.UPDATE_MAP_EXCLUDE_FILTER:
            return {
                ...state,
                mapFilters: {
                    ...state.mapFilters,
                    exclude: action.payload.mapFilters?.exclude
                }
            };
        case MapInsightsActionTypes.UPDATE_MAP_PRODUCTS_FILTER:
            return {
                ...state,
                mapFilters: {
                    ...state.mapFilters,
                    products: action.payload.mapFilters?.products
                },
            };
        case MapInsightsActionTypes.UPDATE_INSTORE_CHECKBOX:
            return {
                ...state,
                mapFilters: {
                    ...state.mapFilters,
                    inStore: action.payload.mapFilters?.inStore
                },
            }; case MapInsightsActionTypes.UPDATE_ONLINE_CHECKBOX:
            return {
                ...state,
                mapFilters: {
                    ...state.mapFilters,
                    online: action.payload.mapFilters?.online
                },
            };
        case MapInsightsActionTypes.UPDATE_MAP_AMOUNT_FILTERS:
            return {
                ...state,
                mapFilters: {
                    ...state.mapFilters,
                    amountStart: action.payload.mapFilters?.amountStart,
                    amountEnd: action.payload.mapFilters?.amountEnd
                }
            };
        case MapInsightsActionTypes.UPDATE_MAP_BOUNDS_FILTERS:
            return {
                ...state,
                mapFilters: {
                    ...state.mapFilters,
                    bounds: action.payload.mapFilters!.bounds
                },
                filteredMapResult: filterMapResult(state.mapResult!, action.payload.mapFilters?.bounds!)
            };
        default:
            return state;
    }
}
const filterMapResult = (mapResult: LocalizedCustomer[] | undefined, bounds: LatLngBounds) => {
    if (mapResult) {
        var clone = _.cloneDeep(mapResult);

        var receiptsInBounds = _.map(clone, x => {
            x.localizedItems = x.localizedItems.filter((x => bounds?.contains({ lat: x.location.latitude, lng: x.location.longitude })))
            return x;
        })

        var filtered = _.filter(receiptsInBounds, x => x.localizedItems.length > 0);

        return filtered;
    }
}