import { I18next } from "../i18n";
export class CurrencyHelper {
    public static formatNumber(number: number | undefined): string {
        if (!number) {
            number = 0;
        }
        const formatter = new Intl.NumberFormat("fr", {
            style: 'currency',
            currency: "CAD"
        });
        var numberFormatted = formatter.format(number);
        return numberFormatted.substring(0, numberFormatted.length - 2)
    }
}