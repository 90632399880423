
import { AccountActionTypes } from "./account.types";
import { AccountClaims } from "../../view-models/account/account-claims";
import { AccountService } from "../../services/account/account.service";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { getDefaultCompany, getCompanies } from "../manage-company/manage-company.actions";
import { AccountPayload } from "./account.state";

export interface AccountAction {
    type: AccountActionTypes;
    payload?: AccountPayload;
}

export const updateAccountClaims = () => {
    return (dispatch: Dispatch<any>) => {
        dispatch(updateAccountClaimsStarted());
        return AccountService.updateAccountClaims().then((response) => {
            dispatch(updateAccountClaimsCompleted())
            dispatch(getDefaultCompany())
            dispatch(getCompanies())

        }).catch((error: AxiosError) => {
            dispatch(updateAccountClaimsFailed(error))
        })
    };
}

export const updateAccountClaimsStarted = (): AccountAction => {
    return {
        type: AccountActionTypes.UPDATE_CLAIMS_STARTED,
        payload: {
            updateClaimsLoading: true,
        }
    };
};
export function updateAccountClaimsCompleted(): AccountAction {
    return {
        type: AccountActionTypes.UPDATE_CLAIMS_COMPLETED,
        payload: {
            updateClaimsLoading: false,
            updateClaimsError: undefined
        }
    };
}

export const updateAccountClaimsFailed = (error: AxiosError): AccountAction => {
    return {
        type: AccountActionTypes.UPDATE_CLAIMS_FAILED,
        payload: {
            updateClaimsLoading: false,
            updateClaimsError: error,
        }
    };
};
