import { Button, Col, Row, Typography, Alert } from "antd";
import { EnvironmentOutlined, SolutionOutlined, UserOutlined } from "@ant-design/icons";
import { WithTranslation, withTranslation } from "react-i18next";

import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import Meta from "antd/lib/card/Meta";
import React, { Fragment } from "react";
import { Card, Text } from "@zeit-ui/react";
import { connect } from "react-redux";
import { UserState } from "redux-oidc";

import { SyncCompanyStatus } from "../../models/company/sync-company-status";
import { DashboardPage } from "../../components/dashboard-page/dashboard-page";
import { ManageCompaniesStateProps } from "../../redux/manage-company/manage-company.state";
import { SmartbillsRootState } from "../../redux/smartbills.reducer";
import { EuiButton, EuiButtonIcon, EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";
import { FiMap, FiShoppingBag, FiUsers } from "react-icons/fi";

class HomeScreen extends React.Component<WithTranslation & RouteComponentProps & { oidc: UserState, manage: ManageCompaniesStateProps }> {
    public render() {
        return (
            <DashboardPage
                title={`${this.props.t("welcome")} ${this.props.oidc.user?.profile.fullname.split(" ")[0]}`}
                description={this.props.t("description")}>
                {this.showSyncAlert()}
                <EuiFlexGroup gutterSize="l">
                    <EuiFlexItem>
                        <EuiCard
                            betaBadgeLabel={this.props.t("cohorts:beta-badge")}
                            onClick={() => { this.props.history.push("/customers/cohorts") }}
                            layout="horizontal"
                            icon={<FiUsers size={32} />}
                            title={this.props.t("cohorts:title")!}
                            description={this.props.t("cohorts:description")!}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiCard
                            onClick={() => { this.props.history.push("/insights/map") }}
                            layout="horizontal"
                            icon={<FiMap size={32} />}
                            title={this.props.t("map-insights:title")!}
                            description={this.props.t("map-insights:description")!}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiCard
                            onClick={() => { this.props.history.push("/database/orders") }}
                            layout="horizontal"
                            icon={<FiShoppingBag size={32} />}
                            title={this.props.t("orders:title")!}
                            description={this.props.t("orders:description")!}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </DashboardPage>
        );
    }

    private showSyncAlert() {
        if (this.props.manage.company?.syncProgressStatus != SyncCompanyStatus.COMPLETED) {
            return (
                <Alert style={{ marginBottom: 10 }} closable type="warning" message={<Fragment>{this.props.t("sync-in-progress")} <Link to="/manage/company">{this.props.t("see-progress")}</Link></Fragment>} />
            )
        }
    }
}

const mapStateToProps = (state: SmartbillsRootState): { oidc: UserState, manage: ManageCompaniesStateProps } => {
    const { oidc, manageCompanies } = state;
    return {
        oidc: oidc,
        manage: manageCompanies
    };
};


const { Title } = Typography;
const HomeScreenTranslated = withTranslation("home")(connect(mapStateToProps)(withRouter(HomeScreen)));
export { HomeScreenTranslated as HomeScreen }