import { SearchEngineStateProps } from "../../search-engine/search-engine.state";
import { SearchEngineAction } from "./search-engine.actions";
import { SearchEngineActionTypes } from "./search-engine.types";


const initialState: SearchEngineStateProps = { loading: false, error: undefined };
export function SearchEngineReducer(
    state: SearchEngineStateProps = initialState,
    action: SearchEngineAction
): SearchEngineStateProps {
    switch (action.type) {
        case SearchEngineActionTypes.UPDATE_ENABLED_STARTED:
            return {
                ...state,
                loading: action.payload?.loading,
                error: action.payload?.error
            };
        case SearchEngineActionTypes.UPDATE_ENABLED_COMPLETED:
            return {
                ...state,
                enabled: action.payload?.enabled,
                loading: action.payload?.loading,
                error: action.payload?.error
            };
        case SearchEngineActionTypes.UPDATE_ENABLED_FAILED:
            return {
                ...state,
                enabled: action.payload?.enabled,
                loading: action.payload?.loading,
                error: action.payload?.error
            };
        default:
            return state;
    }
}