import { AccountAction } from "./account.actions";
import { AccountStateProps } from "./account.state";
import { AccountActionTypes } from "./account.types";

const initialState: AccountStateProps = {
    updateClaimsError: undefined,
    updateClaimsLoading: false,
};
export function AccountReducer(
    state: AccountStateProps = initialState,
    action: AccountAction
): AccountStateProps {
    switch (action.type) {
        case AccountActionTypes.UPDATE_CLAIMS_STARTED:
            return {
                ...state,
                updateClaimsLoading: action.payload?.updateClaimsLoading,
            };
        case AccountActionTypes.UPDATE_CLAIMS_COMPLETED:
            return {
                ...state,
                updateClaimsLoading: action.payload?.updateClaimsLoading,
                updateClaimsError: action.payload?.updateClaimsError,
            };
        case AccountActionTypes.UPDATE_CLAIMS_FAILED:
            return {
                ...state,
                updateClaimsLoading: action.payload?.updateClaimsLoading,
                updateClaimsError: action.payload?.updateClaimsError,
            };
        default:
            return { ...state }
    }
}