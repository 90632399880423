import React, { ReactNode, ReactChild, Fragment } from "react";

import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table, Button, Row, Space, Typography, Col, DatePicker } from "antd";
import moment from "moment";
import 'moment/locale/fr';
import { Card, Text } from "@zeit-ui/react";
import { LoadingOutlined } from "@ant-design/icons";
import { SmartbillsRootState } from "../../redux/smartbills.reducer";
import { OrdersStateProps } from "../../redux/orders/orders.state";
import { OrdersDispatchProps } from "../../redux/orders/orders.props";
import { getOrders } from "../../redux/orders/orders.actions";
import { DashboardPage } from "../../components/dashboard-page/dashboard-page";
import { EuiButton } from "@elastic/eui";
import { Link } from "react-router-dom";
const { Title } = Typography;
class OrdersScreen extends React.Component<WithTranslation & { orders: OrdersStateProps } & OrdersDispatchProps, { selected: string[] }> {
    public constructor(props: WithTranslation & { orders: OrdersStateProps } & OrdersDispatchProps) {
        super(props);
        this.state = { selected: [] }
    }
    public componentDidMount() {
        this.props.getOrders!();
    }
    public render(): ReactNode {
        return (
            <DashboardPage title={this.props.t("title")} description={this.props.t("description")}>
                <Card>
                    <Table loading={{ indicator: <LoadingOutlined />, size: "large", spinning: this.props.orders.ordersLoading }} rowKey="id" scroll={{ x: 1300 }} rowSelection={{ selectedRowKeys: this.state.selected, onChange: (selection, selectedRows) => this.setState({ selected: selectedRows.map(x => x.id) }) }}
                        dataSource={this.props.orders.orders}>
                        <Table.Column responsive={['xs', 'sm', 'md', 'lg', 'xl', 'xxl']} render={(date) => moment.utc(date).local().locale(this.props.i18n.language).format("LLL")} title={this.props.t("table-header:date")} dataIndex="createdAt"></Table.Column>
                        <Table.Column responsive={['xs', 'sm', 'md', 'lg', 'xl', 'xxl']} title={this.props.t("table-header:firstName")} dataIndex="firstName"></Table.Column>
                        <Table.Column responsive={['xs', 'sm', 'md', 'lg', 'xl', 'xxl']} title={this.props.t("table-header:lastName")} dataIndex="lastName"></Table.Column>
                        <Table.Column responsive={['xs', 'sm', 'md', 'lg', 'xl', 'xxl']} title={this.props.t("table-header:email")} dataIndex="email"></Table.Column>
                        <Table.Column responsive={['xs', 'sm', 'md', 'lg', 'xl', 'xxl']} render={(data: number) => data.toFixed(2) + " $"} title={this.props.t("table-header:total")} dataIndex="total"></Table.Column>
                        <Table.Column responsive={['xs', 'sm', 'md', 'lg', 'xl', 'xxl']} render={(data) => { if (data !== null) { return data } else { return this.props.t("table-value:online") } }} title={this.props.t("table-header:source")} dataIndex="source" ></Table.Column>
                        {
                            // <Table.Column responsive={['xs', 'sm', 'md', 'lg', 'xl', 'xxl']} render={(data) => { return (<Link to={`/database/orders/${data}`}><EuiButton size="s">Show</EuiButton></Link>) }} title={this.props.t("table-header:source")} dataIndex="id" ></Table.Column>
                        }
                    </Table>
                </Card>
            </DashboardPage>
        )
    }
}
const mapStateToProps = (state: SmartbillsRootState): { orders: OrdersStateProps } => {
    const { orders } = state;
    return {
        orders: orders
    };
};

const mapDispatchToProps = (dispatch: any): OrdersDispatchProps => {
    return {
        getOrders: () => {
            dispatch(getOrders());
        }
    }
}


const OrderScreenTranslated = connect(mapStateToProps, mapDispatchToProps)(withTranslation("orders")(OrdersScreen))
export { OrderScreenTranslated as OrdersScreen }