import { ProductsAction } from "./products.actions";
import { ProductsStateProps } from "./products.state";
import { ProductsActionTypes } from "./products.types";

const initialState: ProductsStateProps = {
    productsFilterLoading: false,
    productsFilter: []
};
export function ProductsReducer(
    state: ProductsStateProps = initialState,
    action: ProductsAction
): ProductsStateProps {
    switch (action.type) {
        case ProductsActionTypes.GET_PRODUCTS_FILTER_STARTED:
            return {
                ...state,
                productsFilterLoading: action.payload.productsFilterLoading,
                productsFilterError: action.payload.productsFilterError,
            };
        case ProductsActionTypes.GET_PRODUCTS_FILTER_COMPLETED:
            return {
                ...state,
                productsFilter: action.payload.productsFilter,
                productsFilterLoading: action.payload.productsFilterLoading,
                productsFilterError: action.payload.productsFilterError,
            };
        case ProductsActionTypes.GET_PRODUCTS_FILTER_FAILED:
            return {
                ...state,
                productsFilter: action.payload.productsFilter,
                productsFilterLoading: action.payload.productsFilterLoading,
                productsFilterError: action.payload.productsFilterError,
            };
        default:
            return { ...state };
    }
}