import { EuiOverlayMask, EuiModal, EuiModalHeader, EuiModalBody, EuiModalFooter, EuiTitle, EuiText, EuiButton, EuiFieldText, EuiForm, EuiFormRow, EuiButtonEmpty } from "@elastic/eui";
import React, { ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

export interface DeleteModalState {
    isValid: boolean;
    confirmationText: string;
}
export interface SBRessource {
    type: string,
    name: string;
}
export interface DeleteModalProps extends WithTranslation {
    ressource: SBRessource
    onClose: () => void;
    deleteButton: {
        onClick: () => void;
        isLoading?: boolean;
    }
}


class DeleteModal extends React.Component<DeleteModalProps, DeleteModalState> {
    public constructor(props: DeleteModalProps) {
        super(props);
        this.state = {
            isValid: false,
            confirmationText: "",
        }
    }
    public render(): ReactNode {
        return (
            <EuiOverlayMask>
                <EuiModal onClose={this.props.onClose}>
                    <EuiModalHeader>
                        <EuiTitle><h1>{this.props.t("title", { type: this.props.ressource.type })}</h1></EuiTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        <EuiForm>
                            <EuiFormRow fullWidth>
                                <EuiText dangerouslySetInnerHTML={{ __html: this.props.t("description", { name: this.props.ressource.name }) }}></EuiText>
                            </EuiFormRow>
                            <EuiFormRow fullWidth>
                                <EuiText dangerouslySetInnerHTML={{ __html: this.props.t("confirm", { name: this.props.ressource.name }) }}></EuiText>
                            </EuiFormRow>
                            <EuiFormRow fullWidth>
                                <EuiFieldText autoFocus value={this.state.confirmationText} onChange={(event) => this.handleConfirmationTextChange(event.target.value)} fullWidth placeholder={this.props.ressource.name}></EuiFieldText>
                            </EuiFormRow>
                        </EuiForm>
                    </EuiModalBody>
                    <EuiModalFooter>
                        <EuiButtonEmpty onClick={() => this.props.onClose()} >{this.props.t("cancel")}</EuiButtonEmpty>
                        <EuiButton isLoading={this.props.deleteButton.isLoading} autoFocus onClick={this.props.deleteButton.onClick} fill style={{ color: "white" }} isDisabled={!this.state.isValid}>{this.props.t("delete")}</EuiButton></EuiModalFooter>
                </EuiModal>
            </EuiOverlayMask >
        );
    }

    private handleConfirmationTextChange(confirmationText: string): void {
        this.setState({
            confirmationText: confirmationText,
            isValid: confirmationText === this.props.ressource.name
        })
    }
}

const DeleteModalTranslated = withTranslation("delete")(DeleteModal);
export { DeleteModalTranslated as DeleteModal }