import axios, { AxiosInstance } from "axios";
export class RetailAPI {
    private static instance: AxiosInstance;


    public static getInstance(): AxiosInstance {
        if (!RetailAPI.instance) {
            RetailAPI.instance = axios.create({
                baseURL: process.env.REACT_APP_API_URL
            });
        }

        return RetailAPI.instance;
    }

    public static setToken(token: string | undefined) {
        var instance = RetailAPI.getInstance()
        instance.interceptors.request.use(
            config => {
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                }
                return config;
            },
            error => {
                Promise.reject(error)
            });
        RetailAPI.instance = instance;
    }
}

