import './App.css';
import "./utils/i18n";

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import React, { ReactNode } from 'react';

import { CallbackScreen } from './screens/authentication/callback/callback.screen';
import { DashboardLayout } from './layout/dashboard/dashboard.layout';
import { ErrorScreen } from './screens/error/error.screen';
import { InstallSuccessScreen } from './screens/installation/install-success/install-success.screen';
import { LoadingPage } from './screens/authentication/loading/loading-page';
import { LoginScreen } from './screens/authentication/login/login.screen';
import { LogoutCallbackScreen } from './screens/authentication/logout/logout-callback.screen';
import { LogoutScreen } from './screens/authentication/logout/logout.screen';
import { PrivateRoute } from './utils/router/protected-route';
import { SetupScreen } from './screens/setup/setup.screen';
import { UpdateSuccessScreen } from './screens/installation/update-success/update-success.screen';
import { UserState } from 'redux-oidc';
import { connect } from 'react-redux';
import { ConfigProvider } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import frFR from 'antd/es/locale/fr_FR';
import enUS from 'antd/es/locale/en_US';
import { Locale } from 'antd/lib/locale-provider';
import mixpanel from "mixpanel-browser";
import { SilentRenewCallbackScreen } from './screens/authentication/silent-renew/silent-renew-callback';
if (process.env.NODE_ENV == "development") {
	mixpanel.init("3564dd250141667d377d5c893665f46a");
} else {
	mixpanel.init("2d3d032459e2b954f881d5e42f154734");
}
class App extends React.Component<{ oidc: UserState } & WithTranslation> {
	public render(): ReactNode {
		return (
			<ConfigProvider locale={this.getLocale()}>
				<Switch>
					<Route exact path="/login" component={LoginScreen}></Route>
					<Route exact path="/login/callback" component={CallbackScreen}></Route>
					<Route exact path="/logout" component={LogoutScreen}></Route>
					<Route exact path="/logout/callback" component={LogoutCallbackScreen}></Route>
					<Route exact path="/silent-renew/callback" component={SilentRenewCallbackScreen}></Route>
					<Route exact path="/error" component={ErrorScreen}></Route>
					<Route exact path="/install/success" component={InstallSuccessScreen}></Route>
					<Route exact path="/update/success" component={UpdateSuccessScreen}></Route>
					<Route exact path="/shopify/setup" component={SetupScreen}></Route>
					<PrivateRoute path="/" component={DashboardLayout}></PrivateRoute>
					<Redirect to="/login"></Redirect>
				</Switch>
			</ConfigProvider>
		)
	}

	private getLocale(): Locale {
		if (this.props.i18n.language == "fr") {
			return frFR;
		} else {
			return enUS;
		}
	}
}
const mapStateToProps = (state: any): { oidc: UserState } => {
	const { oidc } = state;
	return {
		oidc: oidc,
	};
};


const AppConnected = withTranslation()(connect(mapStateToProps)(App));
export { AppConnected as App }