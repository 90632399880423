export enum OrdersActionTypes {
    GET_ORDERS_STARTED = "GET_ORDERS_STARTED",
    GET_ORDERS_COMPLETED = "GET_ORDERS_COMPLETED",
    GET_ORDERS_FAILED = "GET_ORDERS_FAILED",
    GET_ORDER_STARTED = "GET_ORDER_STARTED",
    GET_ORDER_COMPLETED = "GET_ORDER_COMPLETED",
    GET_ORDER_FAILED = "GET_ORDER_FAILED",
    GET_ORDERS_FILTERS_STARTED = "GET_ORDERS_FILTERS_STARTED",
    GET_ORDERS_FILTERS_COMPLETED = "GET_ORDERS_FILTERS_COMPLETED",
    GET_ORDERS_FILTERS_FAILED = "GET_ORDERS_FILTERS_FAILED",
}