import React, { ReactNode, Fragment } from "react";

import { LanguageSettings } from "../../components/settings/language-settings/language-settings";
import { SettingsScreenProps } from "./settings.props";
import { SettingsScreenState } from "./settings.state";
import { withTranslation } from "react-i18next";
import { Typography } from "antd";
import { DashboardPage } from "../../components/dashboard-page/dashboard-page";

class SettingsScreen extends React.Component<SettingsScreenProps, SettingsScreenState> {
    public constructor(props: any) {
        super(props)
    }
    public render(): ReactNode {
        return (
            <DashboardPage>
                <Title level={3}>
                    {this.props.t("common:screen:settings")}
                </Title>
                <LanguageSettings></LanguageSettings>
            </DashboardPage >
        );
    }
}
const { Title } = Typography;
const SettingsScreenTrannslated = withTranslation()(SettingsScreen);
export { SettingsScreenTrannslated as SettingsScreen }