import React, { Fragment } from "react";
import { Typography, Switch, Row, Col } from "antd";
import { withTranslation, WithTranslation } from "react-i18next";
import { SBProduct } from "@smartbills/smartbills-js";
import { RetailAPI } from "../../../services/retail-api";
import { Text, Card, Image, Dot } from "@zeit-ui/react";
import { connect } from "react-redux";
import { SmartbillsRootState } from "../../../redux/smartbills.reducer";
import { ManageCompaniesStateProps } from "../../../redux/manage-company/manage-company.state";
import { SearchEngineService } from "../../../services/search-engine/search-engine";
import { SearchEngineStateProps } from "../../../redux/search-engine/search-engine.state";
import { SearchEngineDispatchProps } from "../../../redux/manage/search-engine/search-engine.props";
import { updateEnabled } from "../../../redux/manage/search-engine/search-engine.actions";
import { DashboardPage } from "../../../components/dashboard-page/dashboard-page";
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSwitch, EuiText } from "@elastic/eui";
const { Title } = Typography;
class SearchEngineScreen extends React.Component<WithTranslation & SearchEngineDispatchProps & { manage: ManageCompaniesStateProps, searchEngine: SearchEngineStateProps }, { products: any[] }> {
    public constructor(props: WithTranslation & SearchEngineDispatchProps & { manage: ManageCompaniesStateProps, searchEngine: SearchEngineStateProps }) {
        super(props);
        this.state = { products: [] }
    }

    public render() {
        return (
            <DashboardPage title={this.props.t("title")} description={this.props.t("description")}>
                <EuiPanel>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiText>
                                {this.props.t("show-me")}
                            </EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <Switch loading={this.props.searchEngine.loading} onChange={(checked) => {
                                this.props.updateEnabled!(checked);
                            }
                            } defaultChecked={this.props.manage.company?.searchEngineEnabled}
                                checked={this.props.searchEngine.enabled}></Switch>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPanel>
            </DashboardPage>
        )
    }
}


const mapStateToProps = (state: SmartbillsRootState): { manage: ManageCompaniesStateProps, searchEngine: SearchEngineStateProps } => {
    const { manageCompanies, searchEngine } = state;
    return {
        manage: manageCompanies,
        searchEngine: searchEngine
    };
};

const mapDispatchToProps = (dispatch: any): SearchEngineDispatchProps => {
    return {
        updateEnabled: (enabled) => {
            dispatch(updateEnabled(enabled));
        }
    }
}

const SearchEngineScreenTranslated = connect(mapStateToProps, mapDispatchToProps)(withTranslation("searchEngine")(SearchEngineScreen));
export { SearchEngineScreenTranslated as SearchEngineScreen }