
import React, { Fragment, RefObject } from "react";
import { ReactNode } from "react";
import { Typography, DatePicker, Row, Col, Checkbox, Spin, Progress } from "antd";
import { Map as LeaftletMap, TileLayer, GeoJSON, Tooltip, Popup, Pane, CircleMarker } from "react-leaflet";
import { Card, Text } from "@zeit-ui/react";
import { WithTranslation, withTranslation } from "react-i18next";
import { MapInsightsStateProps } from "../../../redux/insights/map/map-insights.state";
import { SmartbillsRootState } from "../../../redux/smartbills.reducer";
import { MapInsightsDispatchProps } from "../../../redux/insights/map/map-insights.props";
import { connect } from "react-redux";
import { getBasicMap, updateDateFilter, updateInStoreCheckbox, updateOnlineCheckbox, updateRecurrentCustomerFilter, updateFirstTimeCustomerFilter } from "../../../redux/insights/map/map-insights.actions";
import { LoadingOutlined } from "@ant-design/icons";
import { GeoJSONProps } from "../../../utils/map/GeoJSONProps";
import _ from "lodash";
import { CanadaZipCodeProperty } from "../../../utils/map/CanadaZipCodeProperty";
import ReactDOMServer from 'react-dom/server';
import hash from "object-hash"
import json from "../../../data/geo.json";
import mixpanel from "mixpanel-browser";
import { DashboardPage } from "../../../components/dashboard-page/dashboard-page";
import { EuiButton, EuiCheckbox, EuiDatePicker, EuiDatePickerRange, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiForm, EuiFormRow, EuiHealth, EuiLoadingSpinner, EuiPanel, EuiStat, EuiTitle } from "@elastic/eui";

const formatNumber = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'CAD' });
const colorArray = ["#0a1b32", "#19437e", "#276aca", "#6faafd", "#c1dafe"]

const { Title } = Typography;
class BasicMapScreen extends React.Component<WithTranslation & MapInsightsDispatchProps & { map: MapInsightsStateProps }, { fsaMap: any, fsaLayer: any, filtersOpen: boolean }> {
    private map: RefObject<LeaftletMap>
    public constructor(props: WithTranslation & MapInsightsDispatchProps & { map: MapInsightsStateProps }) {
        super(props)
        this.map = React.createRef();
        if (!this.props.map.basicMapResult) {
            this.props.getBasicMap!();
        }
        this.state = { fsaMap: null, fsaLayer: null, filtersOpen: false }
    }


    public render(): ReactNode {
        this.map.current?.leafletElement.invalidateSize();
        var revenues: string[] = [];
        if (this.props.map.basicMapResult) {
            revenues = this.props.map.basicMapResult?.fsa.map(x => x.fsa)
        }
        var fsaLayer = null as any;
        if (json) {
            fsaLayer = (json as any as GeoJSONProps<CanadaZipCodeProperty>).features.filter(x => (this.props.map.basicMapResult?.fsa.map((x: any) => x.fsa).includes(x.properties.CFSAUID))) as any;
        }

        return (
            <DashboardPage title={this.props.t("title")} description={this.props.t("description")}>
                {this.renderFilters()}
                <EuiFlexGrid style={{ position: "relative" }} >
                    {this.renderLoading()}
                    <EuiFlexItem grow={10}>
                        <LeaftletMap ref={this.map} worldCopyJump center={{ lat: 20, lng: -70 }} zoom={2} style={{ zIndex: 998, minHeight: 500, height: "100%", width: "100%", opacity: this.props.map.mapResultsLoading ? 0.5 : 1 }}>

                            {fsaLayer && this.props.map.basicMapResult &&
                                <Pane>
                                    <GeoJSON
                                        key={hash(this.props.map.basicMapResult)}
                                        onEachFeature={(feature, layer) => {
                                            layer.bindPopup(ReactDOMServer.renderToString(
                                                <Fragment>
                                                    <Title level={4}>{feature.properties.CFSAUID}</Title>
                                                    <Text>{formatNumber.format(this.props.map.basicMapResult?.fsa.find(x => x.fsa == feature.properties.CFSAUID)?.revenues!)}</Text>
                                                </Fragment>
                                            ));
                                        }
                                        }
                                        data={fsaLayer}
                                        style={(feature) => {
                                            var index = revenues.indexOf(feature!.properties.CFSAUID)
                                            return { color: colorArray[index], fillColor: colorArray[index], opacity: 0.8 }
                                        }}
                                    />
                                </Pane>
                            }
                            <TileLayer
                                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                                subdomains='abcd'
                            />
                            <Pane>
                                {this.renderMarkers()}
                            </Pane>

                        </LeaftletMap >
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiPanel hasShadow style={{ minWidth: 300 }}>
                            <EuiForm>
                                <EuiFormRow fullWidth compressed>
                                    <EuiTitle size="s">
                                        <h2>{this.props.t("results")}</h2>
                                    </EuiTitle>
                                </EuiFormRow>
                                <EuiFormRow label={this.props.t("location")} fullWidth compressed>
                                    <EuiFlexGroup responsive={false}>
                                        <EuiFlexItem grow>
                                            <EuiStat textAlign="center" titleSize="m" title={this.props.map.basicMapResult?.locations.filter(x => x.orderCount == 1).length} description={
                                                <EuiHealth color="#ff5757">{this.props.t("unique-location")}</EuiHealth>}>
                                            </EuiStat>
                                        </EuiFlexItem>
                                        <EuiFlexItem grow >
                                            <EuiStat titleSize="m" textAlign="center" title={this.props.map.basicMapResult?.locations.filter(x => x.orderCount > 1).length} description={
                                                <EuiHealth color="#4E9231">{this.props.t("recurrent-location")}</EuiHealth>}>
                                            </EuiStat>
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFormRow>
                                <EuiFormRow fullWidth compressed>
                                    <EuiStat titleSize="m" textAlign="center" title={_.sum(this.props.map.basicMapResult?.fsa.map(x => x.revenues)).toFixed(2) + "$"} description="Top 5 FSA Revenues"></EuiStat>
                                </EuiFormRow>
                                <EuiFormRow fullWidth label={this.props.t("top-fsa")}>
                                    <Fragment>
                                        {this.renderFSA()}
                                    </Fragment>
                                </EuiFormRow>
                                <EuiFormRow fullWidth>
                                    <EuiButton fullWidth onClick={() => this.setState({ filtersOpen: true })}>{this.props.t("edit-search")}</EuiButton>
                                </EuiFormRow>
                            </EuiForm>
                        </EuiPanel>
                    </EuiFlexItem>
                </EuiFlexGrid>
            </DashboardPage >
        );
    }
    private renderFilters() {
        if (this.state.filtersOpen) {
            return (
                <EuiFlyout ownFocus size="s" onClose={() => this.setState({ filtersOpen: false })}>
                    <EuiFlyoutHeader>
                        <EuiTitle size="s">
                            <h2>{this.props.t("filters")}</h2>
                        </EuiTitle>
                    </EuiFlyoutHeader>
                    <EuiFlyoutBody>
                        <EuiForm>
                            <EuiFormRow label={this.props.t("dates")}>
                                <EuiDatePickerRange
                                    startDateControl={
                                        <EuiDatePicker dateFormat="YYYY-MM-DD" selected={this.props.map.mapFilters?.startDate} onChange={(startDate) => { this.props.updateDateFilter!(startDate, this.props.map.mapFilters?.endDate) }} />
                                    }
                                    endDateControl={
                                        <EuiDatePicker dateFormat="YYYY-MM-DD" selected={this.props.map.mapFilters?.endDate} onChange={(endDate) => { this.props.updateDateFilter!(this.props.map.mapFilters?.startDate, endDate) }} />
                                    } >
                                </EuiDatePickerRange>
                            </EuiFormRow>
                            <EuiFormRow label={this.props.t("Source")}>
                                <EuiFlexGroup>
                                    <EuiFlexItem>
                                        <EuiCheckbox id="online" label={this.props.t("online")} checked={this.props.map.mapFilters?.online} onChange={(event) => { this.props.updateOnlineCheckbox!(event.target.checked) }}></EuiCheckbox>
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiCheckbox id="in-store" label={this.props.t("store")} checked={this.props.map.mapFilters?.inStore} onChange={(event) => { this.props.updateInStoreCheckbox!(event.target.checked) }}></EuiCheckbox>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFormRow>
                            <EuiFormRow label={this.props.t("customer-type")}>
                                <EuiFlexGroup>
                                    <EuiFlexItem>
                                        <EuiCheckbox id="first-time" label={this.props.t("first-time")} checked={this.props.map.mapFilters?.firstTime} onChange={(event) => { this.props.updateFirstTimeCustomerFilter!(event.target.checked) }}></EuiCheckbox>
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiCheckbox id="recurrent" label={this.props.t("recurrent")} checked={this.props.map.mapFilters?.recurrent} onChange={(event) => { this.props.updateRecurrentCustomerFilter!(event.target.checked) }}></EuiCheckbox>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFormRow>
                            <EuiFormRow fullWidth>
                                <EuiButton isLoading={this.props.map.mapResultsLoading} fullWidth onClick={() => {
                                    this.props.getBasicMap!()
                                    this.setState({ filtersOpen: false })
                                }}>{this.props.t("save")}</EuiButton>
                            </EuiFormRow>
                        </EuiForm>
                    </EuiFlyoutBody>
                </EuiFlyout>
            )
        }
    }
    private renderLoading(): ReactNode {
        if (this.props.map.mapResultsLoading) {
            return (
                <div style={{ zIndex: 99999, opacity: 1, backgroundColor: `rgba(255, 255, 255, 0.7)`, position: "absolute", display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
                    <EuiLoadingSpinner size="xl" ></EuiLoadingSpinner>
                </div>
            )
        }
    }

    private renderFSA() {
        const totalRevenues = _.sum(this.props.map.basicMapResult?.fsa.map(x => x.revenues))

        return this.props.map.basicMapResult?.fsa.map((x, index) =>
            <Fragment key={index}>
                <Row justify="space-between" align="middle" style={{ width: "100%" }}>
                    <Col>
                        <span><b>{index + 1 + ". "}</b>{x.fsa} ({formatNumber.format(x.revenues)}) <span style={{ height: 15, width: 15, backgroundColor: colorArray[index] }}></span></span>
                    </Col>
                    <Col>
                        <div style={{ height: 15, width: 15, backgroundColor: colorArray[index] }}></div>
                    </Col></Row>
                <Progress status="normal" strokeColor="#ff5757" percent={Number((x.revenues / totalRevenues * 100).toFixed(2))} size="small" />
            </Fragment>
        )
    }


    public renderMarkers() {
        return this.props.map.basicMapResult?.locations.map((x, index) => {

            if (x.orderCount == 1 && this.props.map.mapFilters?.firstTime) {
                var color = "#FF5757";
                return (
                    <CircleMarker key={index} color={color} center={[x.latitude, x.longitude]} radius={1}>
                    </CircleMarker>
                )
            } else {
                if (x.orderCount > 1 && this.props.map.mapFilters?.recurrent) {
                    var color = "#4E9231";
                    return (
                        <CircleMarker key={index} color={color} center={[x.latitude, x.longitude]} radius={1}>
                        </CircleMarker>
                    )
                }
            }
        });
    }
}

const mapStateToProps = (state: SmartbillsRootState): { map: MapInsightsStateProps } => {
    const { mapInsights } = state;
    return {
        map: mapInsights,
    };
};

const mapDispatchToProps = (dispatch: any): MapInsightsDispatchProps => {
    return {
        getBasicMap: () => {
            dispatch(getBasicMap());
        },
        updateDateFilter: (startDate, endDate) => {
            dispatch(updateDateFilter(startDate, endDate));
        },
        updateRecurrentCustomerFilter: (checked) => {
            dispatch(updateRecurrentCustomerFilter(checked));
        },
        updateFirstTimeCustomerFilter: (checked) => {
            dispatch(updateFirstTimeCustomerFilter(checked));
        },
        updateInStoreCheckbox: (checked) => {
            dispatch(updateInStoreCheckbox(checked));
        },
        updateOnlineCheckbox: (checked) => {
            dispatch(updateOnlineCheckbox(checked));
        }
    }
}
const BasicMapScreenTranslated = connect(mapStateToProps, mapDispatchToProps)(withTranslation("mapInsights")(BasicMapScreen));
export { BasicMapScreenTranslated as BasicMapScreen }