import { AccountReducer } from "./account/account.reducer";
import { MapInsightsReducer } from "./insights/map/map-insights.reducer";
import { combineReducers, Reducer } from "redux";
import { reducer as oidcReducer } from 'redux-oidc';
import { OrdersReducer } from "./orders/orders.reducer";
import { SearchEngineReducer } from "./manage/search-engine/search-engine.reducer";
import { ManageCompaniesReducer } from "./manage-company/manage-company.reducer";
import { DashboardLayoutReducer } from "./dashboard-layout/dashboard-layout.reducer";
import { CohortsReducer } from "./customers/cohorts/cohorts.reducer";
import { CohortReducer } from "./customers/cohort/cohort.reducer";
import { ProductsReducer } from "./products/products.reducer";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { StateType } from "typesafe-actions";
export const smartbillsReducer = (history: History) => combineReducers({
    oidc: oidcReducer,
    products: ProductsReducer,
    layout: DashboardLayoutReducer,
    customers: combineReducers({
        cohort: CohortReducer,
        cohorts: CohortsReducer
    }),
    account: AccountReducer,
    manageCompanies: ManageCompaniesReducer,
    mapInsights: MapInsightsReducer,
    router: connectRouter(history),
    orders: OrdersReducer,
    searchEngine: SearchEngineReducer,
})
export type SmartbillsRootState = StateType<ReturnType<typeof smartbillsReducer>>
