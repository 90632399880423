import detector from "i18next-browser-languagedetector";
import frTranslation from './locales/fr/fr.json';
import enTranslation from './locales/en/en.json';

import homeFr from './locales/fr/home.json';
import homeEn from './locales/en/home.json';

import ordersFr from './locales/fr/orders.json';
import ordersEn from './locales/en/orders.json';

import mapInsightsFr from './locales/fr/map-insights.json';
import mapInsightsEn from './locales/en/map-insights.json';

import searchEngineFr from './locales/fr/search-engine.json';
import searchEngineEn from './locales/en/search-engine.json';
import syncProgressFr from './locales/fr/sync-progress.json';
import syncProgressEn from './locales/en/sync-progress.json';

import customersInsightsFr from './locales/fr/customers-insights.json';
import customersInsightsEn from './locales/en/customers-insights.json';

import menuFr from './locales/fr/menu.json';
import menuEn from './locales/en/menu.json';
import errorsFr from './locales/fr/errors.json';
import errorsEn from './locales/en/errors.json';
import i18next from "i18next";

import cohortsFr from './locales/fr/cohorts/cohorts.json';
import cohortsEn from './locales/en/cohorts/cohorts.json';
import cohortFr from './locales/fr/cohorts/cohort.json';
import cohortEn from './locales/en/cohorts/cohort.json';

import deleteFr from './locales/fr/delete.json';
import deleteEn from './locales/en/delete.json';

import createCohortsFr from './locales/fr/cohorts/create-cohort.json';
import createCohortsEn from './locales/en/cohorts/create-cohort.json';
import { initReactI18next } from "react-i18next";

const getCurrentLng = () => I18next.language || window.localStorage.i18nextLng || '';

export const I18next = i18next.createInstance();
I18next.use(detector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['cookie', 'localStorage', 'navigator', 'htmlTag'],
            caches: ['localStorage', 'cookie'],
        },
        lng: getCurrentLng().substring(0, 2),
        load: "currentOnly",
        fallbackLng: "en",
        returnObjects: true,
        resources: {
            en: {
                errors: errorsEn,
                home: homeEn,
                orders: ordersEn,
                menu: menuEn,
                delete: deleteEn,
                cohorts: {
                    ...cohortsEn,
                    create: createCohortsEn
                },
                cohort: {
                    ...cohortEn,
                },
                syncProgress: syncProgressEn,
                mapInsights: mapInsightsEn,
                searchEngine: searchEngineEn,
                translation: enTranslation,
                customersInsights: customersInsightsEn,
            },
            fr: {
                errors: errorsFr,
                home: homeFr,
                orders: ordersFr,
                menu: menuFr,
                delete: deleteFr,
                cohorts: {
                    ...cohortsFr,
                    create: createCohortsFr
                },
                cohort: {
                    ...cohortFr,
                },
                syncProgress: syncProgressFr,
                mapInsights: mapInsightsFr,
                searchEngine: searchEngineFr,
                translation: frTranslation,
                customersInsights: customersInsightsFr,
            }
        },
        keySeparator: ':',
    })
