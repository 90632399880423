import { EuiBasicTable, EuiButton, EuiButtonEmpty, EuiButtonIcon, EuiContextMenu, EuiContextMenuItem, EuiContextMenuPanel, EuiFieldText, EuiFlexGrid, EuiFlexGroup, EuiFlyout, EuiForm, EuiFormRow, EuiLink, EuiListGroup, EuiListGroupItem, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiOverlayMask, EuiPanel, EuiPopover, EuiSpacer, EuiTable, EuiTextArea, EuiTitle } from "@elastic/eui";
import moment from "moment";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { CreateCohortModal } from "../../../components/cohort/create-modal/create-cohort-modal";
import { UpdateCohortModal } from "../../../components/cohort/update-modal/update-cohort-modal";
import { DashboardPage } from "../../../components/dashboard-page/dashboard-page";
import { DeleteModal } from "../../../components/delete-modal/delete-modal";
import { CohortValdiationEnum } from "../../../models/customers/cohorts/cohort-validation";
import { SBCohort } from "../../../models/customers/SBCohorts";
import { createCohort, deleteCohort, getCohorts, setActiveCohort, toggleCohortMenu, toggleCreateMenu, toggleDeleteMenu, toggleUpdateMenu, updateCohortDescription, updateCohortName } from "../../../redux/customers/cohorts/cohorts.actions";
import { CohortsDispatchProps } from "../../../redux/customers/cohorts/cohorts.props";
import { CohortsStateProps } from "../../../redux/customers/cohorts/cohorts.state";
import { SmartbillsRootState } from "../../../redux/smartbills.reducer";
import "./cohort-page.scss";

class CohortsPage extends React.Component<WithTranslation & RouteComponentProps & { cohorts: CohortsStateProps } & CohortsDispatchProps> {

    public componentDidMount() {
        this.props.getCohorts();
    }

    public render() {
        const { cohorts, cohortForm } = this.props.cohorts;
        return (
            <DashboardPage
                title={this.props.t("title")}
                description={this.props.t("description")}
            >
                <EuiPanel paddingSize="l">
                    <EuiFlexGroup justifyContent="flexEnd" >
                        <EuiButton fill color="text" onClick={() => this.props.toggleCreateMenu(true)} iconType="listAdd">{this.props.t("create-cohort")}</EuiButton>
                    </EuiFlexGroup>
                    <EuiSpacer></EuiSpacer>
                    <EuiBasicTable
                        loading={this.props.cohorts.loading}
                        columns={[
                            {
                                field: 'name',
                                name: this.props.t("columns:name"),
                                truncateText: true,
                            },
                            {
                                field: 'description',
                                name: this.props.t("columns:description"),
                                truncateText: true,
                            },
                            {
                                field: 'createdBy.email',
                                name: this.props.t("columns:created-by"),
                            },
                            {
                                name: this.props.t("columns:updated-at"),
                                render: (item: SBCohort) => {
                                    return (
                                        moment(item.updatedAt).format("LLL")
                                    )
                                }
                            },
                            {
                                name: this.props.t("columns:actions"),
                                actions: [{
                                    render: (item: SBCohort, enabled: boolean) => {
                                        return (
                                            <EuiPopover
                                                id={`menu-${item.id}`}
                                                button={
                                                    <EuiButtonIcon color="subdued"
                                                        onClick={() => {
                                                            this.props.setActiveCohort(item)
                                                            this.props.toggleCohortMenu(true)
                                                        }}
                                                        iconType="boxesHorizontal"
                                                    />
                                                }
                                                closePopover={() => { this.props.toggleCohortMenu(false) }}
                                                isOpen={this.props.cohorts.cohortMenuOpen && this.props.cohorts.activeCohort == item}
                                                anchorPosition="downCenter"
                                            >
                                                <EuiContextMenuPanel
                                                    items={[
                                                        <EuiContextMenuItem
                                                            key="view"
                                                            icon="glasses"
                                                            onClick={() => {
                                                                this.props.history.push(`/customers/cohorts/${item.id}`)
                                                                this.props.toggleCohortMenu(false);
                                                            }}>
                                                            {this.props.t("actions:view")}

                                                        </EuiContextMenuItem>
                                                        ,
                                                        <EuiContextMenuItem
                                                            key="edit"
                                                            icon="pencil"
                                                            onClick={() => {
                                                                this.editCohort(item)
                                                            }}>
                                                            {this.props.t("actions:edit")}
                                                        </EuiContextMenuItem>,
                                                        <EuiContextMenuItem
                                                            key="delete"
                                                            icon="trash"
                                                            onClick={() => {
                                                                this.props.toggleDeleteMenu(true);
                                                            }}>
                                                            {this.props.t("actions:delete")}
                                                        </EuiContextMenuItem>
                                                    ]}
                                                />

                                            </EuiPopover>
                                        )
                                    }
                                }],
                            },
                        ]}
                        items={cohorts!}

                    >
                    </EuiBasicTable>
                </EuiPanel>
                { this.props.cohorts.deleteMenuOpen && this.renderDelete()}
                {
                    this.props.cohorts.createMenuOpen && <CreateCohortModal></CreateCohortModal>
                }
                {
                    this.props.cohorts.updateMenuOpen && <UpdateCohortModal></UpdateCohortModal>
                }
            </DashboardPage >
        );
    }

    private renderDelete() {
        if (this.props.cohorts.activeCohort) {
            return (
                <DeleteModal
                    onClose={() => { this.props.toggleDeleteMenu(false) }}
                    deleteButton={{ onClick: () => this.props.deleteCohort(), isLoading: this.props.cohorts.deleteLoading }
                    }
                    ressource={{ type: this.props.t("delete:type"), name: this.props.cohorts.activeCohort.name! }
                    }></DeleteModal >
            )
        }
    }

    private editCohort(sbCohort: SBCohort) {
        this.props.updateCohortName(sbCohort.name!);
        this.props.updateCohortDescription(sbCohort.description!);
        this.props.toggleUpdateMenu(true);
    }
}

const mapStateToProps = (state: SmartbillsRootState): { cohorts: CohortsStateProps } => {
    const { cohorts } = state.customers;
    return {
        cohorts: cohorts
    };
};
const mapDispatchToProps = (dispatch: any): CohortsDispatchProps => {
    return {
        getCohorts: () => {
            dispatch(getCohorts());
        },
        createCohort: () => {
            dispatch(createCohort());
        },
        updateCohortName: (name) => {
            dispatch(updateCohortName(name));
        },
        updateCohortDescription: (description) => {
            dispatch(updateCohortDescription(description));
        },
        toggleCohortMenu: (isOpen) => {
            dispatch(toggleCohortMenu(isOpen))
        },
        toggleCreateMenu: (isOpen) => {
            dispatch(toggleCreateMenu(isOpen))
        },
        toggleDeleteMenu: (isOpen) => {
            dispatch(toggleDeleteMenu(isOpen))
        },
        setActiveCohort: (cohort: SBCohort) => {
            dispatch(setActiveCohort(cohort))
        },
        deleteCohort: () => {
            dispatch(deleteCohort())
        },
        toggleUpdateMenu: (isOpen) => {
            dispatch(toggleUpdateMenu(isOpen))
        },
    }
}

const CohortsPageTranslated = withTranslation("cohorts")(connect(mapStateToProps, mapDispatchToProps)(withRouter(CohortsPage)));
export { CohortsPageTranslated as CohortsPage }