import { EuiPanel, EuiTitle, EuiStat, EuiFlexGroup, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import React, { Fragment, ReactNode } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Map as LeaftletMap, TileLayer, Pane, CircleMarker } from "react-leaflet";
import { connect } from "react-redux";
import { CohortStateProps } from "../../../../../redux/customers/cohort/cohort.state";
import { OrdersStateProps } from "../../../../../redux/orders/orders.state";
import { ProductsStateProps } from "../../../../../redux/products/products.state";
import { SmartbillsRootState } from "../../../../../redux/smartbills.reducer";

class CohortLocationPanel extends React.Component<WithTranslation & { cohort: CohortStateProps, products: ProductsStateProps, orders: OrdersStateProps }> {
    public render(): ReactNode {
        return (
            <Fragment>
                <EuiSpacer></EuiSpacer>
                <LeaftletMap worldCopyJump center={{ lat: 20, lng: -70 }} zoom={2} style={{ zIndex: 998, minHeight: 500, height: "100%", width: "100%"}}>
                    <TileLayer
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                        subdomains='abcd'
                    />
                    
                    <Pane>
                        {this.renderMarkers()}
                    </Pane>
                </LeaftletMap >
            </Fragment>
        );
    }
    private renderMarkers() {
        return this.props.cohort.overview?.clientsLocations.map((x, index) => {
            return (
                <CircleMarker key={index} color="#006BB4" center={[x.latitude, x.longitude]} radius={1}>
                </CircleMarker>
            )
        });
    }
}

const mapStateToProps = (state: SmartbillsRootState): { cohort: CohortStateProps, products: ProductsStateProps, orders: OrdersStateProps } => {
    const { products, orders } = state;
    const { cohort } = state.customers;
    return {
        cohort: cohort,
        products: products,
        orders: orders
    };
};

const mapDispatchToProps = (dispatch: any): any => {
    return {

    }
}

const CohortLocationPanelTranslated = withTranslation("home")(connect(mapStateToProps)(CohortLocationPanel));
export { CohortLocationPanelTranslated as CohortLocationPanel }