import { RetailAPI } from "../retail-api";
import { AxiosResponse, AxiosError } from "axios";
import { SBReceipt } from "@smartbills/smartbills-js";
import { SBOrdersFilter } from "../../view-models/orders/orders-filter";
import { SBReceiptListItem } from "../../view-models/orders/receipt-list-item";
export class OrdersService {
    public static getOrders(): Promise<SBReceiptListItem[]> {
        return new Promise<SBReceiptListItem[]>((resolve, reject) => {
            RetailAPI.getInstance().get(`orders`).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }
    public static getOrder(id: string): Promise<SBReceipt> {
        return new Promise<SBReceipt>((resolve, reject) => {
            RetailAPI.getInstance().get(`orders/${id}`).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }
    public static getOrdersFilters(): Promise<SBOrdersFilter> {
        return new Promise<SBOrdersFilter>((resolve, reject) => {
            RetailAPI.getInstance().get(`orders/filters`).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error)
            }
            );
        });
    }
}