import { Card, Typography } from "antd";
import React, { Fragment, ReactNode, } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { Text } from "@zeit-ui/react";
import { DashboardPage } from "../../components/dashboard-page/dashboard-page";

const { Title } = Typography;
class SupportScreen extends React.Component<WithTranslation, { current: number }> {

    public constructor(props: any) {
        super(props)
        this.state = {
            current: 0,
        }
    }

    onChange = (current: number) => {
        this.setState({ current });
    };

    public render(): ReactNode {

        return (
            <DashboardPage>
                <Title level={3}>{this.props.t("support:title")}</Title>
                <Card>
                    <Text h2>{this.props.t("support:whatisSmartbills")}</Text>
                    <Text>{this.props.t("support:whatisSmartbillsDescription")}</Text>
                    <Text h2>{this.props.t("support:howDoesItWork")} </Text>
                    <Text b>{this.props.t("support:mapInsights")}</Text>
                    <Text>{this.props.t("support:mapInsightsDescription")}</Text>

                    <Text h2>{this.props.t("support:contactUs")} </Text>
                    <Text>{this.props.t("support:contactUsDescription")} <a href="mailto:support@smartbills.ca">support@smartbills.ca</a> </Text>
                </Card>
            </DashboardPage>
        );
    }
}
const SupportScreenTrannslated = withTranslation()(SupportScreen);
export { SupportScreenTrannslated as SupportScreen }