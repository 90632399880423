import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { RiStore2Line } from "react-icons/ri"
import { ManageCompaniesDispatchProps } from "../../redux/manage-company/manage-company.props";
import { NavigationHeaderProps } from "./header.props";
import { SBCompany } from "@smartbills/smartbills-js";
import { SmartbillsRootState } from "../../redux/smartbills.reducer";
import { UserState } from "redux-oidc";
import { connect } from "react-redux";
import { setDefaultCompany } from "../../redux/manage-company/manage-company.actions";
import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem, EuiImage, EuiPopover, EuiText, EuiButton, EuiIcon, EuiFlexGroup, EuiButtonEmpty, EuiContextMenu, EuiContextMenuPanel, EuiContextMenuItem, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiOverlayMask, EuiSuperSelectOption, EuiSuperSelect, EuiHideFor } from "@elastic/eui";
import { ManageCompaniesStateProps } from "../../redux/manage-company/manage-company.state";
import { DashboardLayoutStateProps } from "../../redux/dashboard-layout/dashboard-layout.state";
import { DashboardLayoutDispatchProps } from "../../redux/dashboard-layout/dashboard-layout.props";
import { toggleSideMenu, toggleTopMenu } from "../../redux/dashboard-layout/dashboard-layout.actions";
import "./header.scss";

class NavigationHeader extends React.Component<NavigationHeaderProps, { selectedCompany: string, visible: boolean }> {
    public constructor(props: NavigationHeaderProps) {
        super(props);
        this.state = {
            selectedCompany: this.props.company?.companyId!,
            visible: false,
        }
    }
    public render() {
        return (
            <EuiHeader position="fixed" style={{ paddingLeft: 20, paddingRight: 20 }}>
                <EuiHeaderSection side="right">
                    <EuiHeaderSectionItem border="none">
                        <EuiButtonEmpty style={{ marginRight: 10 }} color="text" onClick={() => this.props.toggleSideMenu(!this.props.layout.sideMenuOpen)}>
                            <EuiIcon type="menu"></EuiIcon>
                        </EuiButtonEmpty>
                    </EuiHeaderSectionItem>
                </EuiHeaderSection>
                <EuiHeaderSection className="logo-section" side="right">
                    <EuiHideFor sizes={["xs", "s"]}>
                        <EuiHeaderSectionItem className="logo" border="none">

                            <Link to="/">
                                <EuiImage style={{ width: 100 }} alt="Smartbills Logo" url="/logo.png"></EuiImage>
                            </Link>
                        </EuiHeaderSectionItem>
                    </EuiHideFor>
                </EuiHeaderSection>
                <EuiHeaderSection side="right">
                    <EuiHeaderSectionItem border="none">
                        <EuiPopover
                            id="menu"
                            button={
                                <EuiButtonEmpty onClick={() => {
                                    this.props.toggleSideMenu(false)
                                    this.props.toggleTopMenu!(true)
                                }}
                                    iconSide="right" color="text" iconType="arrowDown">
                                    <EuiText style={{ marginRight: 10 }} size="s">{this.getDefaultCompanyName()}</EuiText>
                                </EuiButtonEmpty >
                            }
                            closePopover={() => this.props.toggleTopMenu!(false)}
                            isOpen={this.props.layout.topMenuOpen}
                            anchorPosition="downCenter"
                        >
                            <EuiContextMenuPanel items={this.getContextMenuItems()} />
                        </EuiPopover>
                        {this.renderModal()}
                    </EuiHeaderSectionItem>
                </EuiHeaderSection>
            </EuiHeader>
        )
    }


    private getContextMenuItems() {
        var items = [
            <EuiContextMenuItem
                key="settings"
                icon="gear"
                onClick={() => {
                    this.navigate("/settings")
                }}>
                {this.props.t("header:settings")}

            </EuiContextMenuItem>,
            <EuiContextMenuItem
                key="support"
                icon="help"
                onClick={() => {
                    this.navigate("/support")
                }}>
                {this.props.t("header:support")}
            </EuiContextMenuItem>,
            <EuiContextMenuItem
                key="account"
                icon="popout"
                href="https://accounts.smartbills.ca/dashboard"
                target="_blank">
                {this.props.t("header:account")}

            </EuiContextMenuItem>,
            <EuiContextMenuItem
                key="logout"
                icon="unlink"
                onClick={() => {
                    this.props.history.push("/logout")
                }}>
                {this.props.t("header:logout")}
            </EuiContextMenuItem>,
        ];

        if (this.props.companies?.length! > 0) {
            items.unshift(
                <EuiContextMenuItem
                    key="select-company"
                    icon={<RiStore2Line></RiStore2Line>}
                    onClick={() => {
                        this.setState({ visible: true });
                    }}>
                    {this.props.t("header:selectCompany")}
                </EuiContextMenuItem>
            )
        }
        return items;
    }

    private navigate(path: string) {
        this.props.toggleTopMenu!(false);
        this.props.history.push(path);
    }
    private setDefaultCompany() {
        let company: SBCompany = this.getCompanyFromId(this.state.selectedCompany)!
        this.props.setDefaultCompany!(company)
    }

    private getDefaultCompanyName() {
        if (this.props.companies?.length! > 0) {
            return this.props.companies?.find(x => x.id === this.props.company?.companyId)?.name
        } else {
            return this.props.oidc.user?.profile.fullname
        }
    }
    private getCompanyFromId(id: string) {
        return this.props.companies?.find(x => x.id === id);
    }

    private renderModal() {
        if (this.state.visible) {
            return (
                <EuiOverlayMask>
                    <EuiModal onClose={() => this.setState({ visible: false })}>
                        <EuiModalHeader>
                            <EuiModalHeaderTitle>
                                {this.props.t("header:selectCompany")}
                            </EuiModalHeaderTitle>
                        </EuiModalHeader>

                        <EuiModalBody>
                            <EuiSuperSelect
                                valueOfSelected={this.state.selectedCompany}
                                options={this.props.companies!.map((company, index) => ({
                                    value: company.id,
                                    inputDisplay: company.name
                                }))}
                                onChange={(value) => this.setState({ selectedCompany: value })}
                            />
                        </EuiModalBody>

                        <EuiModalFooter>
                            <EuiButtonEmpty onClick={() => this.setState({ visible: false })}>
                                {this.props.t("header:cancel")}
                            </EuiButtonEmpty>
                            <EuiButton style={{ color: "white" }} fill isLoading={this.props.updateDefaultCompanyLoading} onClick={() => this.setDefaultCompany()}>
                                {this.props.t("header:save")}
                            </EuiButton>
                        </EuiModalFooter>
                    </EuiModal>
                </EuiOverlayMask>
            )
        }
    }
}

const mapStateToProps = (state: SmartbillsRootState): { oidc: UserState, layout: DashboardLayoutStateProps } & ManageCompaniesStateProps => {
    const { oidc, layout, manageCompanies } = state;
    return {
        oidc: oidc,
        layout: layout,
        companies: manageCompanies.companies,
        company: manageCompanies.company,
        updateDefaultCompanyLoading: manageCompanies.updateDefaultCompanyLoading
    };
};

const mapDispatchToProps = (dispatch: any): ManageCompaniesDispatchProps & DashboardLayoutDispatchProps => {
    return {
        setDefaultCompany: (company) => {
            dispatch(setDefaultCompany(company.id));
        },
        toggleSideMenu: (sideMenOpen: boolean) => {
            dispatch(toggleSideMenu(sideMenOpen));
        },
        toggleTopMenu: (topMenuOpen: boolean) => {
            dispatch(toggleTopMenu(topMenuOpen));
        }
    }
}

const NavigationHeaderConnected = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NavigationHeader)));
export { NavigationHeaderConnected as NavigationHeader }
