import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { SBReceipt } from "@smartbills/smartbills-js";
import { OrdersActionTypes } from "./orders.types";
import { OrdersService } from "../../services/orders/orders-service";
import { OrdersPayload } from "./orders.state";
import { SBOrdersFilter } from "../../view-models/orders/orders-filter";
import { SBReceiptListItem } from "../../view-models/orders/receipt-list-item";

export interface OrdersAction {
    type: OrdersActionTypes;
    payload?: OrdersPayload;
}

export const getOrders = () => {
    return (dispatch: Dispatch) => {
        dispatch(getOrdersStarted());
        OrdersService.getOrders().then((orders) => {
            dispatch(getOrdersCompleted(orders))
        }).catch((error: AxiosError) => {
            dispatch(getOrdersFailed(error))
        })
    };
}

export const getOrdersStarted = (): OrdersAction => {
    return {
        type: OrdersActionTypes.GET_ORDERS_STARTED,
        payload: {
            ordersLoading: true,
        }
    };
};
export function getOrdersCompleted(orders: SBReceiptListItem[]): OrdersAction {
    return {
        type: OrdersActionTypes.GET_ORDERS_COMPLETED,
        payload: {
            ordersLoading: false,
            orders: orders,
            orderError: undefined
        }
    };
}

export const getOrdersFailed = (error: AxiosError): OrdersAction => {
    return {
        type: OrdersActionTypes.GET_ORDERS_FAILED,
        payload: {
            ordersLoading: false,
            orderError: error,
        }
    };
};


export const getOrder = (id: string) => {
    return (dispatch: Dispatch) => {
        dispatch(getOrderStarted());
        OrdersService.getOrder(id).then((order) => {
            dispatch(getOrderCompleted(order))
        }).catch((error: AxiosError) => {
            dispatch(getOrderFailed(error))
        })
    };
}

export const getOrderStarted = (): OrdersAction => {
    return {
        type: OrdersActionTypes.GET_ORDER_STARTED,
        payload: {
            orderLoading: true,
        }
    };
};
export function getOrderCompleted(order: SBReceipt): OrdersAction {
    return {
        type: OrdersActionTypes.GET_ORDER_COMPLETED,
        payload: {
            orderLoading: false,
            order: order,
            ordersError: undefined
        }
    };
}

export const getOrderFailed = (error: AxiosError): OrdersAction => {
    return {
        type: OrdersActionTypes.GET_ORDER_FAILED,
        payload: {
            orderLoading: false,
            ordersError: error,
        }
    };
};


export const getOrdersFilters = () => {
    return (dispatch: Dispatch) => {
        dispatch(getOrdersFiltersStarted());
        OrdersService.getOrdersFilters().then((order) => {
            dispatch(getOrdersFiltersCompleted(order))
        }).catch((error: AxiosError) => {
            dispatch(getOrdersFiltersFailed(error))
        })
    };
}

export const getOrdersFiltersStarted = (): OrdersAction => {
    return {
        type: OrdersActionTypes.GET_ORDERS_FILTERS_STARTED,
        payload: {
            filtersLoading: true,
            filtersError: undefined
        }
    }
};

export const getOrdersFiltersCompleted = (filters: SBOrdersFilter): OrdersAction => {
    return {
        type: OrdersActionTypes.GET_ORDERS_FILTERS_COMPLETED,
        payload: {
            filters: filters,
            filtersLoading: false,
            filtersError: undefined
        }
    }
};
export const getOrdersFiltersFailed = (error: AxiosError): OrdersAction => {
    return {
        type: OrdersActionTypes.GET_ORDERS_FILTERS_FAILED,
        payload: {
            filtersLoading: false,
            filtersError: error
        }
    }
};