import { EuiAccordion, EuiFlexGroup, EuiFlexItem, EuiLoadingChart, EuiSpacer } from "@elastic/eui";
import { Axis, Chart, AreaSeries, BarSeries } from '@elastic/charts';

import React, { Fragment, ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CohortStateProps } from "../../../../../redux/customers/cohort/cohort.state";
import { OrdersStateProps } from "../../../../../redux/orders/orders.state";
import { ProductsStateProps } from "../../../../../redux/products/products.state";
import { SmartbillsRootState } from "../../../../../redux/smartbills.reducer";
import '@elastic/charts/dist/theme_only_light.css';
import moment from "moment";
import { SBCohortOrderCountGraphPoint } from "../../../../../view-models/customers/cohorts/stats/order-count-point";
import { LoadableComponent } from "../../../../../components/loadable-component/loadable-component";
import _ from "lodash";
import { CurrencyHelper } from "../../../../../utils/currency/currency-helper";

class CohortRevenuesPanel extends React.Component<WithTranslation & { cohort: CohortStateProps, products: ProductsStateProps, orders: OrdersStateProps }> {
    public render(): ReactNode {
        const cumulativeSum = (sum => (value: SBCohortOrderCountGraphPoint) => {
            return {
                revenues: (sum += value.revenues).toFixed(2),
                date: value.date
            }
        })(0);
        return (
            <Fragment>
                <EuiSpacer></EuiSpacer>
                <EuiAccordion initialIsOpen id="revenues-over-time" buttonContent={this.props.t("revenues-panel:revenues-over-time")}>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <LoadableComponent isLoading={this.props.cohort.statsLoading} loadingIcon={<EuiLoadingChart></EuiLoadingChart>} >
                                <Chart size={{ height: 250 }}>
                                    <BarSeries
                                        id="revenues-per-day"
                                        name="Revenues"
                                        data={this.props.cohort.stats!.revenuesOverTime!}
                                        tickFormat={(value) => CurrencyHelper.formatNumber(value)}
                                        xScaleType="time"
                                        xAccessor="date"
                                        yAccessors={["revenues"]}
                                        color="#006BB4"
                                    />
                                    <Axis
                                        id="bottom-axis"
                                        position="bottom"
                                        title="Date"
                                        tickFormat={(date) => moment(date).format("LL")}
                                        showGridLines
                                    />
                                    <Axis
                                        id="left-axis"
                                        position="left"
                                        title="Revenues"
                                        showGridLines
                                    />
                                </Chart>
                            </LoadableComponent>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <LoadableComponent isLoading={this.props.cohort.statsLoading} loadingIcon={<EuiLoadingChart></EuiLoadingChart>} >
                                <Chart size={{ height: 250 }}>
                                    <AreaSeries
                                        id={this.props.t("revenues-panel:total-revenues")}
                                        name={this.props.t("revenues-panel:total-revenues")}
                                        data={this.props.cohort.stats!.revenuesOverTime!.map(cumulativeSum)}
                                        xScaleType="time"
                                        xAccessor="date"
                                        tickFormat={(value) => CurrencyHelper.formatNumber(value)}
                                        yAccessors={["revenues"]}
                                        color="#006BB4"

                                    />
                                    <Axis
                                        id="bottom-axis"
                                        position="bottom"
                                        title="Date"
                                        tickFormat={(date) => moment(date).format("LL")}
                                        showGridLines
                                    />
                                    <Axis
                                        id="left-axis"
                                        position="left"
                                        title={this.props.t("revenues-panel:total-revenues")}
                                        showGridLines
                                    />
                                </Chart>
                            </LoadableComponent>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiAccordion>
                <EuiAccordion initialIsOpen id="revenues-over-time" buttonContent={this.props.t("revenues-panel:average-revenues")}>
                    <EuiFlexGroup>
                        <EuiFlexItem grow={5}>
                            <LoadableComponent isLoading={this.props.cohort.statsLoading} loadingIcon={<EuiLoadingChart></EuiLoadingChart>} >
                                <Chart size={{ height: 250 }}>
                                    <BarSeries
                                        id={this.props.t("revenues-panel:average-revenue-per-order")}
                                        name={this.props.t("revenues-panel:average-revenue-per-order")}
                                        data={this.props.cohort.stats!.revenuesOverTime!}
                                        xScaleType="time"
                                        xAccessor="date"
                                        yAccessors={["averageRevenues"]}
                                        tickFormat={(value) => CurrencyHelper.formatNumber(value)}
                                        color="#006BB4"
                                    />
                                    <Axis
                                        id="bottom-axis"
                                        position="bottom"
                                        title="Date"
                                        tickFormat={(date) => moment(date).format("LL")}
                                        showGridLines
                                    />
                                    <Axis
                                        id="left-axis"
                                        position="left"
                                        title={this.props.t("revenues-panel:average-revenue-per-order")}
                                        showGridLines
                                    />
                                </Chart>
                            </LoadableComponent>
                        </EuiFlexItem>
                        <EuiFlexItem grow={5}>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiAccordion>
            </Fragment>
        )
    }
}

const mapStateToProps = (state: SmartbillsRootState): { cohort: CohortStateProps, products: ProductsStateProps, orders: OrdersStateProps } => {
    const { products, orders } = state;
    const { cohort } = state.customers;
    return {
        cohort: cohort,
        products: products,
        orders: orders
    };
};

const mapDispatchToProps = (dispatch: any): any => {
    return {

    }
}

const CohortRevenuesPanelTranslated = withTranslation("cohort")(connect(mapStateToProps)(CohortRevenuesPanel));
export { CohortRevenuesPanelTranslated as CohortRevenuesPanel }