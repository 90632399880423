import { Button, Result, Row } from "antd";
import { WithTranslation, withTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import React from "react";

class InstallSuccessScreen extends React.Component<WithTranslation> {
    public render() {
        return (
            <Row justify="center" align="middle" style={{ height: "100vh" }}>
                <Result status="success"
                    title={this.props.t("install:title")}
                    extra={
                        <Link to="/">
                            <Button onClick={() => {
                            }} shape="round" danger>
                                {this.props.t("install:returnToApp")}
                            </Button>
                        </Link>
                    }>
                </Result>
            </Row>
        )
    }
}
const InstallSuccessTranslated = withTranslation()(InstallSuccessScreen);
export { InstallSuccessTranslated as InstallSuccessScreen }