import React, { ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { LoadingPage } from "../loading/loading-page";
import { OAuth2Service } from "../../../services/authentication/oauth2-service";
import { SignoutCallbackComponent } from "redux-oidc";

class LogoutCallbackScreen extends React.Component<RouteComponentProps<{}>> {
    public constructor(props: RouteComponentProps<{}>) {
        super(props);

    };

    public render(): ReactNode {
        return (
            <SignoutCallbackComponent userManager={OAuth2Service.instance} successCallback={() =>
                window.history.replaceState({},
                    window.location.href = "https://smartbills.ca")
            } errorCallback={(error) => window.history.replaceState({},
                window.location.href = "https://smartbills.ca")}>
                <LoadingPage description="Logging you out..." />
            </SignoutCallbackComponent>);
    }
}

const CallbackScreenWithRouter = withRouter(LogoutCallbackScreen);
export { CallbackScreenWithRouter as LogoutCallbackScreen }