import { Language } from "./language";

export class LanguageUtil {
    public static getAvailableLanguages(): string[] {
        return Object.keys(Language);
    }
    public static getValue(key: string): string {
        var language : Language = Language[key as keyof typeof Language];
        return language;
    }
}