import { AxiosError, AxiosResponse } from "axios";

import { AccountClaims } from "../../view-models/account/account-claims";
import { RetailAPI } from "../retail-api";

export class AccountService {
    public static updateAccountClaims(): Promise<void> {
        return new Promise((resolve, reject) => {
            RetailAPI.getInstance().put("account").then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error);
            }
            );
        });
    }
}