import { EuiText, EuiTitle } from "@elastic/eui";
import { notification } from "antd";
import { ArgsProps } from "antd/lib/notification";
import { AxiosError } from "axios";
import React from "react";
import { Dispatch } from "redux";
import { CreateCohortSuccessNotification, DeleteCohortSuccessNotification, UpdateCohortSuccessNotification } from "../../../components/notifications/cohort/cohort-notification";
import { CohortValdiationEnum } from "../../../models/customers/cohorts/cohort-validation";
import { SBCohort } from "../../../models/customers/SBCohorts";
import { CohortsService } from "../../../services/customers/cohorts/cohorts.service";
import { SmartbillsRootState } from "../../smartbills.reducer";
import { CohortsPayload } from "./cohorts.state";
import { CohortsActionTypes } from "./cohorts.types";


export interface CohortsAction {
    type: CohortsActionTypes;
    payload: CohortsPayload;
}

export const getCohorts = () => {
    return (dispatch: Dispatch) => {
        dispatch(getCohortsStarted());
        CohortsService.getCohorts().then((order) => {
            dispatch(getCohortsCompleted(order))
        }).catch((error: AxiosError) => {
            dispatch(getCohortsFailed(error))
        })
    };
}

export const getCohortsStarted = (): CohortsAction => {
    return {
        type: CohortsActionTypes.GET_COHORTS_STARTED,
        payload: {
            loading: true,
            error: undefined
        }
    }
};

export const getCohortsCompleted = (cohorts: SBCohort[]): CohortsAction => {
    return {
        type: CohortsActionTypes.GET_COHORTS_COMPLETED,
        payload: {
            cohorts: cohorts,
            loading: false,
            error: undefined
        }
    }
};

export const getCohortsFailed = (error: AxiosError): CohortsAction => {
    return {
        type: CohortsActionTypes.GET_COHORTS_FAILED,
        payload: {
            error: error,
            loading: false,
        }
    }
};
export const showNotification = (notif: ArgsProps): CohortsAction => {
    notification.open(notif);
    return {
        type: CohortsActionTypes.CREATION_SUCCESS_NOTIFICATION,
        payload: {
        }
    }
};

export const createCohort = () => {
    return (dispatch: Dispatch<any>, getState: () => SmartbillsRootState) => {
        var { cohortForm } = getState().customers.cohorts;
        dispatch(createCohortStarted());
        CohortsService.createCohort(cohortForm!).then((cohort) => {
            dispatch(createCohortCompleted(cohort))
        }).catch((error: AxiosError) => {
            dispatch(createCohortFailed(error))
        })
    };
}

export const createCohortStarted = (): CohortsAction => {
    return {
        type: CohortsActionTypes.CREATE_COHORT_STARTED,
        payload: {
            createdCohort: undefined,
            createLoading: true,
            createError: undefined
        }
    }
};

export const createCohortCompleted = (cohort: SBCohort) => {
    return (dispatch: Dispatch) => {
        dispatch(showNotification(new CreateCohortSuccessNotification(cohort)));
        dispatch({
            type: CohortsActionTypes.CREATE_COHORT_COMPLETED,
            payload: {
                createdCohort: cohort,
                createLoading: false,
                createError: undefined
            }
        })
        dispatch<any>(toggleCreateMenu(false));

    }
};

export const createCohortFailed = (error: AxiosError): CohortsAction => {
    return {
        type: CohortsActionTypes.CREATE_COHORT_FAILED,
        payload: {
            createdCohort: undefined,
            createLoading: false,
            createError: error
        }
    }
};

export const updateCohortName = (name: string) => {
    return (dispatch: Dispatch<CohortsAction>) => {
        dispatch({
            type: CohortsActionTypes.UPDATE_COHORT_NAME,
            payload: {
                cohortForm: {
                    name: name
                }
            }
        });
        dispatch<any>(validateName(name!));
        dispatch<any>(validateForm());
    }
};

export const updateCohortDescription = (description: string) => {
    return (dispatch: Dispatch<CohortsAction>) => {
        dispatch({
            type: CohortsActionTypes.UPDATE_COHORT_DESCRIPTION,
            payload: {
                cohortForm: {
                    description: description
                }
            }
        });
        dispatch<any>(validateDescription(description!));
        dispatch<any>(validateForm());
    }
};

export const toggleCohortMenu = (isOpen: boolean): CohortsAction => {
    return {
        type: CohortsActionTypes.TOGGLE_COHORT_MENU,
        payload: {
            cohortMenuOpen: isOpen
        }
    }
};

export const toggleUpdateMenu = (isOpen: boolean) => {
    return (dispatch: Dispatch<CohortsAction>) => {
        if (!isOpen) {
            dispatch(resetForm())
        }
        dispatch({
            type: CohortsActionTypes.TOGGLE_UPDATE_MENU,
            payload: {
                updateMenuOpen: isOpen
            }
        })
    }
};


export const setActiveCohort = (cohort: SBCohort | undefined): CohortsAction => {
    return {
        type: CohortsActionTypes.SET_ACTIVE_COHORT,
        payload: {
            activeCohort: cohort
        }
    }
};

export const toggleCreateMenu = (isOpen: boolean) => {
    return (dispatch: Dispatch<CohortsAction>) => {
        if (!isOpen) {
            dispatch(resetForm())
        }
        dispatch({
            type: CohortsActionTypes.TOGGLE_CREATE_MENU,
            payload: {
                createMenuOpen: isOpen
            }
        })
    }
};

export const toggleDeleteMenu = (isOpen: boolean): CohortsAction => {
    return {
        type: CohortsActionTypes.TOGGLE_DELETE_MENU,
        payload: {
            deleteMenuOpen: isOpen,
            deleted: undefined,
        }
    }
};

export const deleteCohort = () => {
    return (dispatch: Dispatch<any>, getState: () => SmartbillsRootState) => {
        var { activeCohort } = getState().customers.cohorts;
        dispatch(deleteCohortStarted());
        CohortsService.deleteCohort(activeCohort?.id!).then(() => {
            dispatch(showNotification(new DeleteCohortSuccessNotification(activeCohort!)));
            dispatch(deleteCohortCompleted(activeCohort!))
            dispatch(toggleDeleteMenu(false))
            dispatch(setActiveCohort(undefined))

        }).catch((error: AxiosError) => {
            dispatch(deleteCohortFailed(error))
        })
    };
}

export const deleteCohortStarted = (): CohortsAction => {
    return {
        type: CohortsActionTypes.DELETE_COHORT_STARTED,
        payload: {
            deleted: undefined,
            deleteLoading: true,
            deleteError: undefined
        }
    }
};

export const deleteCohortCompleted = (cohort: SBCohort) => {
    return (dispatch: Dispatch<CohortsAction>) => {
        dispatch({
            type: CohortsActionTypes.DELETE_COHORT_COMPLETED,
            payload: {
                deleted: cohort,
                deleteLoading: false,
                deleteError: undefined
            }
        })
    }
};

export const deleteCohortFailed = (error: AxiosError): CohortsAction => {
    return {
        type: CohortsActionTypes.DELETE_COHORT_FAILED,
        payload: {
            deleted: undefined,
            deleteLoading: false,
            deleteError: error
        }
    }
};

export const resetForm = (): CohortsAction => {
    return {
        type: CohortsActionTypes.RESET_FORM,
        payload: {
            cohortForm: {
                name: "",
                description: ""
            },
            isFormValid: false,
            nameValidation: CohortValdiationEnum.UNVALIDATED,
            descriptionValidation: CohortValdiationEnum.VALID
        }
    }
};

export const validateForm = () => {
    return (dispatch: Dispatch<CohortsAction>, getState: () => SmartbillsRootState) => {
        var nameValidation = getState().customers.cohorts.nameValidation;
        var descriptionValidation = getState().customers.cohorts.descriptionValidation;
        var isValid =
            (nameValidation == CohortValdiationEnum.VALID) &&
            (descriptionValidation == CohortValdiationEnum.VALID)
        dispatch({
            type: CohortsActionTypes.VALIDATE_FORM,
            payload: {
                isFormValid: isValid
            }
        })
    }
};

const validateName = (name: string) => {
    return (dispatch: Dispatch<CohortsAction>, getState: () => SmartbillsRootState) => {
        var validation: CohortValdiationEnum = CohortValdiationEnum.VALID;
        if (name.length > 50) {
            validation = CohortValdiationEnum.FIELD_TOO_LONG
        }
        if (isNullOrWhiteSpace(name)) {
            validation = CohortValdiationEnum.FIELD_EMPTY
        }
        dispatch({
            type: CohortsActionTypes.VALIDATE_NAME,
            payload: {
                nameValidation: validation,
            }
        })
    }
};


const validateDescription = (description: string) => {
    return (dispatch: Dispatch<CohortsAction>, getState: () => SmartbillsRootState) => {
        var validation: CohortValdiationEnum;
        if (description.length > 150) {
            validation = CohortValdiationEnum.FIELD_TOO_LONG
        }
        else {
            validation = CohortValdiationEnum.VALID
        }
        dispatch({
            type: CohortsActionTypes.VALIDATE_DESCRIPTION,
            payload: {
                descriptionValidation: validation,
            }
        })
    }
};


export const updateCohort = () => {
    return (dispatch: Dispatch, getState: () => SmartbillsRootState) => {
        var { activeCohort } = getState().customers.cohorts;
        var { cohortForm } = getState().customers.cohorts;
        var cohortUpdated: SBCohort = {
            name: cohortForm?.name,
            description: cohortForm?.description
        }
        dispatch(updateCohortStarted());
        CohortsService.updateCohort(activeCohort?.id!, cohortUpdated).then((cohort) => {
            dispatch(updateCohortCompleted(cohort))
            dispatch(showNotification(new UpdateCohortSuccessNotification(activeCohort!)));
            dispatch<any>(toggleUpdateMenu(false))
        }).catch((error: AxiosError) => {
            dispatch(updateCohortFailed(error))
        })
    };
}

export const updateCohortStarted = (): CohortsAction => {
    return {
        type: CohortsActionTypes.UPDATE_COHORTS_LIST_ITEM_STARTED,
        payload: {
            updateLoading: true,
            updateError: undefined
        }
    }
};

export const updateCohortCompleted = (cohort: SBCohort): CohortsAction => {
    return {
        type: CohortsActionTypes.UPDATE_COHORTS_LIST_ITEM_COMPLETED,
        payload: {
            createdCohort: cohort,
            updateLoading: false,
            updateError: undefined
        }
    }
};
export const updateCohortFailed = (error: AxiosError): CohortsAction => {
    return {
        type: CohortsActionTypes.UPDATE_COHORTS_LIST_ITEM_FAILED,
        payload: {
            updateLoading: false,
            updateError: error
        }
    }
};

function isNullOrWhiteSpace(name: string) {
    return name === null || name.match(/^\s*$/) !== null
}